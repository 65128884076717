<template>
  <div class="cloth-category-tabs">
    <Flickity ref="tabsFlickity" :options="tabsFlickityOpt" class="tabs-flickity">
      <div class="category-tab" :class="{'selected': selectingCategory === item.value}" @click="tabClicked(item)" v-for="(item) in tabsOpts" :key="item.value">
        {{ item.name }}
      </div>
    </Flickity>
  </div>
</template>

<script>
import Flickity from 'vue-flickity';
import { mapState } from 'vuex';

export default {
  name: 'ClothCategoryTabs',
  data() {
    return {
      selectingCategory: -1,
    }
  },
  created() {
  },
  components: {
    Flickity,
  },
  computed: {
    ...mapState(['clothCategories']),
    tabsFlickityOpt() {
      return {
        wrapAround: false,
        cellAlign: 'left',
        freeScroll: true,
        contain: true,
        pageDots: false,
        prevNextButtons: false,
        initialIndex: 0,
      };
    },
    tabsOpts() {
      const res = [
        {
          name: '全部',
          value: -1,
        }
      ];
      for (const c of this.clothCategories) {
        res.push({
          name: c.name,
          value: c.serial,
        });
      }
      return res;
    },
  },
  mounted() {
  },
  props: {
  },
  watch: {
    
  },
  methods: {
    tabClicked(tab) {
      // console.log(tab);
      this.selectingCategory = tab.value;
      this.$emit('set', tab);
    }
  }

}
</script>

<style scoped>
  .cloth-category-tabs {
    position: relative;
    padding: .25rem 0;
  }

  .tabs-flickity {
    /* padding: .25rem; */
  }

  .tabs-flickity:focus-visible {
    outline: none;
  }

  .category-tab {
    position: relative;
    padding: .125rem .75rem;
    margin: .5rem 0;
    font-weight: bold;
    color: var(--text-color-main);
  }

  .category-tab.selected {
    border-bottom: solid .125rem var(--color-brand-primary);
  }

  .category-tab:not(:first-child) {
    /* margin-left: 1.5rem; */
  }
</style>
