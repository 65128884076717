export default {
  namespaced: true,
  state: {
    historyListData: null,
    scheduledSerial: -1,
    hasValidStatus: false,
  },
  getters: {
  },
  mutations: {
    setHistoryListData(state, data) {
      state.historyListData = data;
    },
    setScheduledSerial(state, data) {
      state.scheduledSerial = data;
    },
    removeFromHistoryListData(state, serial) {
      for (let i=0;i<state.historyListData.length;i++) {
        if (state.historyListData[i].serial === serial) {
          state.historyListData.splice(i, 1);
          return;
        }
      }
    },
    setHasValidStatus(state, result) {
      state.hasValidStatus = result;
    },
  },
  actions: {
    async refreshHistoryList({ commit, dispatch }) {
      const busyName = 'eventRefreshHistoryList';
      await dispatch('appendComponentBusy', busyName, { root: true });
      try {
        const res = await dispatch('api/getReviewOrderListPromise', null, { root: true });
        commit('setHistoryListData', res.list);

        // 檢查 historyListData 的 status，並存放結果
        const hasValidStatus = res.list.some(item => [0, 1, 4, 5].includes(item.status));
        commit('setHasValidStatus', hasValidStatus);
      } catch (error) {
        await dispatch('appendErrorMsg', error.toString(), { root: true });
        await dispatch('showMsgModal', error.toString(), { root: true });
      } finally {
        await dispatch('clearComponentBusy', busyName, { root: true });
      }
    },
    clearHistoryListData({ commit }) {
      commit('setHistoryListData', null);
    },
    removeFromHistoryListData({ commit }, orderSerial) {
      commit('removeFromHistoryListData', orderSerial);
    },
    setScheduledSerial({ commit }, serial) {
      commit('setScheduledSerial', serial);
    },
    clearScheduledSerial({ commit }) {
      commit('setScheduledSerial', -1);
    },
  }

}
