<template>
  <div class="home" :class="{'not-ready': !init}">
    <div class="home-user">
      <router-link :to="{ name: 'UserPublic', params: {serial: loginUserSerial}}" class="user-img">
        <img :src="user.picUrl" />
      </router-link>

      <div class="user-msg">
        <p class="welcome">今天又是新的一天！</p>
        <p class="welcome-msg">歡迎回來 {{ user.displayName }}</p>
      </div>
    </div>

    <div class="home-week">
      <router-link :to="{name: 'OutfitCalendar'}" class="home-week-panel" @click.native="recordClick('HomeTopCalendar')">
        <div class="day-card" :class="{'has-outfit': dateHasOutfit(item), 'is-today': isToday(item)}" v-for="(item) in weekDates" :key="item.dayOfYear()">
          <div class="day-circle">
            <span>{{ item.date() }}</span>
          </div>
          <div class="day-weekday">{{ item.format('ddd') }}</div>
        </div>
      </router-link>
    </div>
    
    <div class="home-section">
      <p class="home-section-title">我的衣櫥 <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.999998 12L7 6.5L1 0.999999" stroke="#8D8D8D" stroke-width="1.5" stroke-linecap="round"/></svg></p>
      <div>
        <router-link :to="{name: 'Analyze'}" @click.native="recordClick('HomeClosetAnalytic')">
          <HomeClosetData/>
        </router-link>
      </div>
    </div>

    <div class="home-section">
      <p class="home-section-title">好友狀態 <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.999998 12L7 6.5L1 0.999999" stroke="#8D8D8D" stroke-width="1.5" stroke-linecap="round"/></svg></p>
      
      <div v-if="recentFriendInfo.length > 0" class="friend-row mb-2">
        <div class="friend-col" v-for="f in recentFriendInfo" :key="f.serial">
          <router-link :to="{name: 'UserPublic', params: {serial:f.serial}}" class="friend-card" @click.native="recordClick('HomeFriendLink')">
            <div class="friend-img">
              <img :src="f.picUrl"/>
            </div>

            <div class="friend-info">
              <label>{{ f.name }}</label>
              <p>上傳了 {{ f.publicOutfitCount }} 筆新穿搭</p>
              <p>公開了 {{ f.publicClothCount }}件單品</p>
            </div>
          </router-link>
        </div>
      </div>

      <div>
        <button class="add-friend-btn" @click="openAddFriendModal">
          <img :src="require('@/assets/icons/Add_round_fill_grey.png')"/> <span>新增好友</span>
        </button>
      </div>
    </div>

    <div class="home-section">
      <p class="home-section-title">好文閱讀 <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.999998 12L7 6.5L1 0.999999" stroke="#8D8D8D" stroke-width="1.5" stroke-linecap="round"/></svg></p>
      <div class="mb-4">
        <flickity class="banner-carousel" v-if="init" ref="bannerFlickity" :options="bannerFlickityOpt">
          <div class="banner-cell" v-for="(item, index) in bannerPosts" :key="index">
            <router-link :to="{name: 'TopicContent', params: {id: item.id}}" class="banner-card">
              <div class="post-img">
                <img :src="item.image.url" />
              </div>
              <p>{{ item.title }}</p>
              <span>{{ postDateStr(item.published) }}</span>
            </router-link>
          </div>
        </flickity>
      </div>
      <div class="pt-2">
        <router-link :to="{name: 'Topic'}" class="more-topic-btn">
          <img :src="require('@/assets/nav_icons/topic.png')"/><span>看更多 好文閱讀</span>
        </router-link>
      </div>
    </div>

    <div class="d-none">
      <p class="text-center text-secondary mt-4" v-if="homeOutfitSerials.length === 0">尚未追蹤任何使用者<br/>或未有任何追蹤中的使用者分享穿搭</p>
      <div class="row no-gutters">
        <div class="col-6 px-1 mb-2" v-for="(s) in filteredSerial" :key="s">
          <OutfitCard :serial="s"></OutfitCard>
        </div>
      </div>
      <div class="mb-3 text-center" v-if="showLoadMoreBtn">
        <button class="btn btn-sm btn-green" @click="loadMoreClicked">載入更多</button>
      </div>
    </div>

    <transition name="fade">
      <div class="update-panel" v-if="showUpdatePanel" @click="closeUpdatePanel">
        <img :src="require('@/assets/update/update.png')"/>
      </div>
    </transition>
  </div>
</template>

<script>
// @ is an alias to /src
import Flickity from 'vue-flickity';
import OutfitCard from '@/components/home/OutfitCard.vue';
import HomeClosetData from '@/views/home/HomeClosetData.vue'
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'Home',
  data() {
    return {
      readingBusyName: 'HOMEDATAREADING',

      init: false,
      homeOutfitSerials: [],
      nowLoadCount: 6,
      outfits: [],

			postList: [],
      recentFriendInfo: [],
      
      bannerFlickityOpt: {
        wrapAround: false,
        cellAlign: 'center',
        contain: true,
        pageDots: true,
        prevNextButtons: false,
        initialIndex: 0,
      },
      showUpdatePanel: false,
    };
  },
  components: {
    OutfitCard,
    HomeClosetData,
    Flickity,
  },
  computed: {
    ...mapGetters(['hasToken']),
    filteredSerial() {
      const res = [];
      for (let i=0;i<this.nowLoadCount && i<this.homeOutfitSerials.length;i++) {
        res.push(this.homeOutfitSerials[i]);
      }
      return res;
    },
    showLoadMoreBtn() {
      return this.nowLoadCount < this.homeOutfitSerials.length;
    },
    user() {
      return this.$store.state.user;
    },
    weekDates() {
      const today = moment();
      const weekStart = today.subtract(today.day(), 'days');
      const weekDates = [];
      weekDates.push(moment(weekStart));
      for (let i=0;i<6;i++) {
        const d = moment(weekStart.add(1, 'days'));
        weekDates.push(d);
      }
      return weekDates;
    },
		bannerPosts() {
			if (this.postList.length > 6) {
				return this.postList.slice(0,6);
			}
			return this.postList;
		},
    loginUserSerial() {
      return this.$store.getters.loginUserSerial;
    }
	},
  watch: {
  },
  async mounted() {
    try {
      console.log(this.hasToken);
      if (!this.hasToken) {
        this.$router.push({name: 'Login'});
      }
      await this.__refresh();
    } catch (error) {
      this.appendErrorMsg(error.toString());
    } finally {
      this.init = true;
    }
	},
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'setAddFriendShow']),
    recordClick(event) {
      window.trackBtnClicked(event);
      console.log('123');
    },
    async __refresh() {
      this.appendComponentBusy(this.readingBusyName);
      try {
        const read = await this.$store.dispatch('updatePanel/getUpdatePanelRead');
        console.log(read);
        this.showUpdatePanel = read;
        //
        const results = await Promise.all([
          this.$store.dispatch('api/getHomeRecentFriendInfoPromise'),
          this.$store.dispatch('api/readOutfitListPromise'),
          this.$store.dispatch('api/getHomeOutfitSerialsPromise'),
          this.$store.dispatch('api/readPostListPromise'),
        ]);
        // const recentFriendInfo = await this.$store.dispatch('api/getHomeRecentFriendInfoPromise');
        this.recentFriendInfo.splice(0, this.recentFriendInfo.length);
        for (const f of results[0]) {
          this.recentFriendInfo.push(f);
        }

        // const list = await this.$store.dispatch('api/readOutfitListPromise');
        this.outfits.splice(0, this.outfits.length);
        for (const outfit of results[1]) {
          this.outfits.push(outfit);
        }
        this.homeOutfitSerials.splice(0, this.homeOutfitSerials.length);
        // const serials = await this.$store.dispatch('api/getHomeOutfitSerialsPromise');
        for (const s of results[2]) {
          this.homeOutfitSerials.push(s);
        }
        // const plist = await this.$store.dispatch('api/readPostListPromise');
        this.postList.splice(0, this.postList.length);
        results[3].map((item) => {
          this.postList.push(item);
        });
      } catch(e) {
        console.error(e);
        this.$emit('error', e);
      } finally {
        this.clearComponentBusy(this.readingBusyName);
      }
    },
    dateHasOutfit(m) {
      const mStr = m.format('YYYY-MM-DD');
      const d = this.outfits.filter((o) => {
        return mStr === o.date;
      });
      return d.length > 0;
    },
    isToday(m) {
      return moment().format('YYYY-MM-DD') === m.format('YYYY-MM-DD');
    },
    loadMoreClicked() {
      this.nowLoadCount += 6;
    },
    openAddFriendModal() {
      this.setAddFriendShow(true);
    },
    bannerClicked(index, id) {
      //
      console.log(index);
      console.log(id);
    },
    postDateStr(t) {
      return moment(t).format('YYYY.MM.DD');
    },
    closeUpdatePanel() {
      this.showUpdatePanel = false;
      this.$store.dispatch('updatePanel/setUpdatePanelRead');
    },
  }
}
</script>

<style scoped>
  a:hover, a:focus {
    text-decoration: none;
  }

  .home {
    padding: 10px 0;
    transition: opacity 1s ease;
  }

  .not-ready {
    opacity: 0;
  }

  .btn-green {
    background-color: var(--main-green);
    color: #fff;
    border-radius: 999px;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .home-user {
    display: flex;
    align-items: center;
    padding: 14px 1rem;
  }

  .home-user .user-img {
    flex: 0 0 auto;
    width: 42px;
    position: relative;
  }

  .home-user .user-img>img {
    display: block;
    width: 100%;
    border-radius: 50%;
  }

  .home-user .user-msg {
    flex: 1 1;
    padding-left: 1.5rem;
  }

  .home-user .user-msg .welcome {
    font-size: 1.25rem;
    margin: 0;
    font-weight: 600;
    color: #4D4D4D;
  }

  .home-user .user-msg .welcome-msg {
    font-size: .8rem;
    margin: 0;
    color: #8D8D8D;
    font-weight: 600;
  }

  .home-week {
    padding: 9px 1rem;
  }

  .home-week .home-week-panel {
    padding: .5rem;
    background-color: #EEEEEE;
    box-shadow: 4px 4px 8px rgba(196, 196, 196, 0.41);
    border-radius: 1rem;
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto;
    column-gap: .5rem;
  }

  .home-week .home-week-panel:hover, .home-week .home-week-panel:focus {
    text-decoration: none;
  }

  .home-week .home-week-panel .day-card {
    position: relative;
  }

  .home-week .home-week-panel .day-card .day-circle {
    position: relative;
    width: 100%;
    padding-top: 100%;
    border-radius: 50%;
    background-color: #fff;
    color: var(--main-green);
  }

  .home-week .home-week-panel .day-card.is-today .day-circle {
    border: 1.5px solid rgba(125, 196, 183, 0.65);
    box-shadow: 2px 2px 8px rgba(196, 196, 196, 0.85);
  }

  .home-week .home-week-panel .day-card.has-outfit:not(.is-today) .day-circle {
    background: #7DC4B7;
    color: #fff;
  }

  .home-week .home-week-panel .day-card .day-circle>span {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 1000;
  }

  .home-week .home-week-panel .day-card .day-weekday {
    padding-top: .25rem;
    color: #C4C4C4;
    font-size: .6rem;
    text-align: center;
  }

  .home-section {
    padding: .5rem 1rem;
  }

  .home-section .home-section-title {
    margin-bottom: .5rem;
  }

  .home-section .home-section-title svg {
    display: inline-block;

  }

  .add-friend-btn, .more-topic-btn {
    background: #FFFFFF;
    box-shadow: 4px 4px 8px rgba(196, 196, 196, 0.41);
    border-radius: 16px;
    width: 100%;
    border: none;
    padding: .5rem;
    appearance: none;
    letter-spacing: .1em;
    display: flex;
    align-items: center;
    justify-content: center;
    color:#4D4D4D;
  }

  .add-friend-btn>img, .more-topic-btn>img {
    height: 1.5em;
    display: inline-block;
    margin-right: .5rem;
  }

  .friend-card {
    display: flex;
    background: #FFFFFF;
    box-shadow: 4px 4px 8px rgba(196, 196, 196, 0.41);
    border-radius: 16px;
    width: 100%;
    border: none;
    padding: .8rem .5rem;
    align-items: center;
  }

  .friend-row {
    display: flex;
    margin-left: -4px;
    margin-right: -4px;
  }

  .friend-col {
    flex: 1 1;
    padding: 0 4px;
  }

  .friend-card .friend-img {
    flex: 0 0 2rem;
    height: 2rem;
    position: relative;
  }

  .friend-card .friend-img>img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .friend-card .friend-info {
    padding-left: .5rem;
    color: #4D4D4D;
  }

  .friend-card .friend-info>label {
    margin-bottom: 0;
    font-size: 12px;
  }

  .friend-card .friend-info>p {
    margin-bottom: 0;
    font-size: 11px;
    color: #C4C4C4;
  }

  .banner-carousel {
    position: relative;
    width: 100%;
  }
  
  .banner-cell {
    width: 60%;
    position: relative;
    margin-right: 10px;
  }

  .banner-card {
    display: block;
    position: relative;
  }

  .post-img {
    position: relative;
    padding-top: 53.4%;
  }

  .post-img>img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .banner-card>p {
    text-align: right;
    margin: 0;
    font-size: .8rem;
    color: #4D4D4D;
  }

  .banner-card>span {
    display: block;
    text-align: right;
    margin: 0;
    font-size: .6rem;
    color: #C4C4C4;
  }

  .update-panel {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    height: 100dvh;
    z-index: 9999;
    justify-content: center;
    align-items: center;
    background-color: #0005;
  }

  .update-panel>img {
    width: 80%;
  }
</style>
