<template>
  <div class="outfit-block">
    <div class="outfit-img">
      <img :src="outfitImg" @load="imgLoaded"/>
    </div>
  </div>
</template>


<script>
// @ is an alias to /src

export default {
  name: 'OutfitBlock',
  data() {
    return {
      resolvedOutfitImg: null,
    };
  },
  props: {
    outfit: {
      type: Object,
      required: true,
    },
  },
  components: {
  },
  computed: {
    outfitImg() {
      // return this.outfit.outfitPhotos[0].imgUrl;
      return this.resolvedOutfitImg || '';
    },
	},
  watch: {
    outfit: {
      handler: 'resolveOutfitImg',
      deep: true, // 確保監聽內部變化
      immediate: true, // 組件掛載時也執行一次
    }
  },
  mounted() {
	},
  methods: {
    async resolveOutfitImg() {
      if (this.outfit.outfitPhotos.length === 0) return;

      const firstPhoto = this.outfit.outfitPhotos[0];

      if (firstPhoto.imgUrl) {
        this.resolvedOutfitImg = firstPhoto.imgUrl;
      } else if (firstPhoto.clothSerial) {
        try {
          const res = await this.$store.dispatch('api/readClothInfoPromise', firstPhoto.clothSerial);
          this.resolvedOutfitImg = res.imgs?.[0] || ''; // 確保 res.imgs[0] 存在，否則為空字串
        } catch (e) {
          console.error('獲取 cloth 圖片失敗:', e);
        }
      }
    },
    imgLoaded() {
      this.$emit('loaded');
    }
  }
}
</script>

<style scoped>
  .outfit-block {
    position: relative;
    width: 100%;
  }

  .outfit-img {
    padding-top: 100%;
    background-color: #C4C4C4;
    border-radius: .5rem;
    overflow: hidden;
    position: relative;
  }

  .outfit-img>img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
</style>
