// 使用方法參考
// async mounted() {
//     await this.handleAsyncTask(this.methodExampleA);
// },
// method: {
//     methodExampleA() {
//         this.data = await this.$store.dispatch('api/getDataPromise');
//     },
//     async methodExampleB() {
//         await this.handleAsyncTask(async () => {
//             const data = await this.$store.dispatch('api/postDataPromise');
//             await this.$router.push({
//                 name: 'Example',
//                 params: { id: data.serial }
//             });
//         });
//     },
// },

import {mapActions} from "vuex";

export default {
  methods: {
    ...mapActions(['appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'showMsgModal']),

    async handleAsyncTask(task) {
      const tempTaskBusyID = `${new Date().getTime()}_${parseInt(Math.random()*10000)}`;
      await this.$store.dispatch('appendComponentBusy', tempTaskBusyID);
      try {
        await task();
      } catch (error) {
        this.appendErrorMsg(error.toString());
        this.showMsgModal(error.toString());
      } finally {
        await this.$store.dispatch('clearComponentBusy', tempTaskBusyID);
      }
    },
  }
}