<template>
  <FullScreenModal title="新增衣物" @back="backClicked">
    <div class="view-cloth" v-if="init">
      <ViewClothContent :cloth="cloth"></ViewClothContent>

      <section class="actions">
        <button class="horizon-btn" @click="addToClicked">新增至衣櫥</button>
      </section>

      <transition name="fade">
        <WcCommonModal title="確定新增" v-if="isConfirmModalShow" @cancel="isConfirmModalShow = false" @ok="addToCloset" hide-header>
          <div class="text-center" style="font-size: 14px;">
            確定取得此衣物<br/>
            新增至您的衣櫥？
          </div>
        </WcCommonModal>
      </transition>
    </div>
  </FullScreenModal>
</template>

<script>
import { mapActions } from 'vuex';
import clothDefault from '@/dataModel/cloth.js';
import WcCommonModal from '@/components/commons/WcCommonModal.vue';
import FullScreenModal from '@/components/FullScreenModal.vue';
import ViewClothContent from '@/views/closet/ViewClothContent';
import '@/assets/css/buttons.css';

export default {
  name: 'AddClothFromTemplateModal',
  data() {
    return {
      init: false,
      cloth: clothDefault(),
      isConfirmModalShow: false,
    };
  },
  components: {
    WcCommonModal,
    ViewClothContent,
    FullScreenModal,
  },
  props: {
    clothTemplate: {
      type: Object,
      required: true,
    },
  },
  beforeDestroy() {
  },
  mounted() {
    this.cloth = Object.assign({}, this.cloth, this.clothTemplate);
    this.init = true;
  },
  computed: {
  },
  watch: {
  },
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'updateCloth']),
    backClicked() {
      this.$emit('back');
    },
    addToClicked() {
      this.isConfirmModalShow = true;
    },
    async addToCloset() {
      this.isConfirmModalShow = false;
      this.$emit('buy', this.cloth);
    }
    
  }
}
</script>

<style scoped>
  .create-cloth {
  }

  .imgs {
    position: relative;
  }

  .square-outer {
    position: relative;
    width: 100%;
    background-color: #eeeeee;
    padding-top: 100%;
  }

  .square-outer>img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .use-count {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    border-radius: 999px;
    background-color: #fff;
    color: var(--text-dark);
    font-size: 12px;
    padding: 4px 12px;
    box-shadow: 0px 0px 4px #00000040;
  }

  .square-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .square-inner>img {
    width: 46px;
    height: 46px;
    object-fit: contain;
  }

  .square-inner>p {
    margin: 0;
    font-size: 12px;
    color: #fff;
  }

  /* info */
  .info {
    padding: 1rem;
    position: relative;
  }

  .info-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    flex-wrap: nowrap;
  }

  .info-row.v-center {
    align-items: center;
  }

  .info-col-title {
    flex: 1 1;
    font-size: 14px;
    padding-right: 1rem;
  }

  .info-col-title.sub {
    padding-left: 1rem;
  }

  .info-col-title>label {
    font-weight: 600;
    margin: 0;
  }

  .info-col-title>label.required::after {
    content: '*';
    color: var(--required-orange);
  }

  .info-col-content {
    flex: 0 0 70%;
    max-width: 70%;
    font-size: 12px;
  }

  .pills.brand-pill {
    --pill-color: var(--main-green);
  }

  .pills.tag-pill {
    --pill-color: var(--main-yellow);
  }

  .pills {
    position: relative;
    display: block;
    font-size: 12px;
    color: var(--pill-color);
    border-radius: 999px;
    padding: 3px 14px;
    border: 1px solid var(--pill-color);
    margin-bottom: .25rem;
    margin-top: .25rem;
  }

  .public-pill {
    position: absolute;
    top: .5rem;
    right: 1rem;
    display: block;
    font-size: 12px;
    color: #fff;
    border-radius: 999px;
    padding: 3px 14px;
    border: 1px solid var(--main-green);
    background-color: var(--main-green);
    margin-bottom: .25rem;
    margin-top: .25rem;
  }

  .sub-imgs {
    position: relative;
    display: block;
    /* grid-template-rows: auto;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 4px; */
    overflow-x: auto;
    padding: 4px 0;
  }

  .sub-imgs-inner {
    display: flex;
    flex-wrap: nowrap;
  }

  .sub-imgs-content {
    position: relative;
  }

  .sub-imgs-content:not(:first-child) {
    margin-left: 4px;
  }

  .sub-img-photo {
    display: block;
    position: relative;
    padding-top: 100%;
    width: 100px;
    background-color: #ddd;
    border: solid 2px #ddd;
  }

  .sub-img-photo.active {
    border-color: var(--main-orange);
  }

  .sub-img-photo>img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: contain;
  }

</style>
