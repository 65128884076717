<template>
  <transition name="fade">
    <FullScreenModal v-if="isMainConfigShow" title="設定選單" @back="configCancel">
      <div class="main-config pb-5">
        <ul class="config">
          <li class="sect">
            我的帳號
          </li>

          <li>
            <a href="#" @click.prevent="showMemberConfig">
              <span>會員帳號設定</span>
            </a>
          </li>

          <li>
            <a href="#" class="disabled" @click.prevent="underconstruction">
              <span>個人資料設定</span>
            </a>
          </li>

          <li>
            <a href="#" class="disabled" @click.prevent="underconstruction">
              <span>共享衣櫥設定</span>
            </a>
          </li>

          <li>
            <a href="#" @click.prevent="showBodyConfig">
              <span>我的身體數據設定</span>
            </a>
          </li>

          <li>
            <a href="#" class="disabled" @click.prevent="underconstruction">
              <span>任務點數回饋</span>
            </a>
          </li>
        </ul>

        <ul class="config d-none">
          <li class="sect">
            偏好設定
          </li>

          <li>
            <a href="#" class="disabled" @click.prevent="underconstruction">
              <span>主題背景色設定</span>
            </a>
          </li>

          <li>
            <a href="#" class="disabled" @click.prevent="underconstruction">
              <span>通知設定</span>
            </a>
          </li>
        </ul>

        <ul class="config">
          <li class="sect">
            關於
          </li>

          <li>
            <a href="#" class="disabled" @click.prevent="underconstruction">
              <span>使用說明</span>
            </a>
          </li>

          <li>
            <a href="#" class="disabled" @click.prevent="underconstruction">
              <span>常見問題</span>
            </a>
          </li>

          <li>
            <a href="#" @click.prevent="showPolicy">
              <span>隱私權聲明</span>
            </a>
          </li>

          <li>
            <a href="#" @click.prevent="showBuyFlow">
              <span>購物流程</span>
            </a>
          </li>

          <li>
            <a href="#" @click.prevent="showExchange">
              <span>退換貨政策</span>
            </a>
          </li>

          <li>
            <a href="https://walkingcloset.co/about" target="_blank">
              <span>關於我們</span>
            </a>
          </li>

          <li>
            <a href="#" @click.prevent="shareApp">
              <span>分享 App 連結</span>
            </a>
          </li>

          <li>
            <a href="https://walkingcloset.co/contactus" target="_blank">
              <span>聯絡我們</span>
            </a>
          </li>
        </ul>

        <button class="horizon-btn mt-3" @click="logoutClicked">登出</button>

        <transition name="fade">
          <WcCommonModal v-if="isLogoutModalShow" @cancel="isLogoutModalShow = false" @ok="logout" hide-header>
            <div class="text-center" style="font-size: 14px;">
              確定登出?
            </div>
          </WcCommonModal>
        </transition>

      </div>
    </FullScreenModal>
  </transition>
</template>

<script>
import {
  mapGetters,
  mapActions
} from 'vuex';
import '@/assets/css/config-ul.css';
import '@/assets/css/buttons.css';
import FullScreenModal from '@/components/FullScreenModal.vue';
import WcCommonModal from '@/components/commons/WcCommonModal.vue';

export default {
  name: 'MainConfig',
  data() {
    return {
      isLogoutModalShow: false,
    };
  },
  components: {
    WcCommonModal,
    FullScreenModal,
  },
  props: {},
  beforeDestroy() {},
  async mounted() {},
  computed: {
    ...mapGetters(['isMainConfigShow']),
  },
  watch: {

  },
  methods: {
    ...mapActions(['showMsgModal', 'setMainConfigShow', 'setMemberConfigShow', 'setBodyConfigShow', 'setPolicyShow', 'setBuyFlowShow', 'setExchangeShow']),
    showMemberConfig() {
      this.setMemberConfigShow(true);
    },
    showBodyConfig() {
      this.setBodyConfigShow(true);
    },
    showPolicy() {
      this.setPolicyShow(true);
    },
    showBuyFlow() {
      this.setBuyFlowShow(true);
    },
    showExchange() {
      this.setExchangeShow(true);
    },
    logoutClicked() {
      this.isLogoutModalShow = true;
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.push({name: 'Login'});
      this.setMainConfigShow(false);
      
      // window.location.href = '/closet/logout';
    },
    underconstruction() {
      this.showMsgModal('功能還在建立中~QQ');
    },
    configCancel() {
      this.setMainConfigShow(false);
    },
    shareApp() {
      if (window.navigator.share) {
        window.navigator.share({
          title: '長腳衣櫥',
          text: '和我一起成為長腳衣櫥的測試員，讓衣物的管理更智慧化，減少浪費也穿出自己的個性，一起創造更美好的衣物網路',
          url: 'https://walkingcloset.network/closet/',
        }).then(() => {
          console.log('成功！');
        }).catch((error) => {
          console.log('發生錯誤', error)
        });
      } else {
        this.copyMsg('https://walkingcloset.network/closet/');
        this.showMsgModal('已複製連結!');
      }
    },
    copyMsg(msg) {
      const node = document.createElement("span");
      node.innerText = msg;
      node.setAttribute('style', 'position:absolute;left:0;top:0;opacity:0;');
      document.body.appendChild(node);
      const selection = window.getSelection();
      selection.removeAllRanges();
      const range = document.createRange();
      range.selectNode(node);
      selection.addRange(range);

      try {
        const successful = document.execCommand('copy');
        const sysmsg = successful ? 'successful' : 'unsuccessful';
        console.log(sysmsg);
      } catch (err) {
        console.error(err);
      }

      selection.removeAllRanges();
      node.remove();
    }
  }
}
</script>

<style scoped>
  .main-config {

  }

  .horizon-btn {
    position: absolute;
    bottom: 0;
    left: 0;
  }
</style>
