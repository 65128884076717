<template>
  <div class="custom-place-finder">
    <button class="search-btn" v-if="!hasPlaceData" @click="toggleShowSearchModal">
      <span>輸入地點</span>
    </button>
    <div class="place-info" v-if="hasPlaceData">
      <div class="title fs-h4">地點</div>
      <div class="name">
        <Icon name="location" size="24" />
        <span class="fs-14px" v-text="placeData.place"></span>
        <button class="edit-btn fs-14px fw-bold" @click="toggleShowSearchModal('open')">
          <span>編輯</span>
        </button>
      </div>
    </div>
    <semiOverlayModal class="search-place" v-model="showSearchModal">
      <div class="search-place-nav">
        <button class="close-btn" @click="toggleShowSearchModal('close')">
          <Icon name="close" size="24" />
        </button>
        <span class="fs-16px fw-bold">地點</span>
<!--        <button class="save-btn fs-14px">-->
<!--          <span>儲存</span>-->
<!--        </button>-->
      </div>
      <div class="search-place-option-panel">
        <div class="option-group">
          <input type="text" class="form-control fs-16px" placeholder="搜尋" v-model="situation">
          <button class="option-btn btn-primary" @click="sendSearchPlace">
            <Icon name="search" size="24" />
          </button>
        </div>
        <div class="result-list" :class="{ 'items-exceed': searchResult.length > 3 }">
          <div class="result-item" v-for="(item, index) in searchResult" :key="item.placeId" @click="updateSearchPlace(index)">
            <div class="name fs-16px" v-text="item.place"></div>
            <div class="address fs-14px" v-text="item.address"></div>
          </div>
          <div class="error-text" v-if="errorText" v-text="errorText"></div>
        </div>
      </div>
    </semiOverlayModal>
  </div>
</template>

<script>
import semiOverlayModal from "@/components/SemiOverlayModal.vue";

export default {
  name: 'CustomPlaceFinder',
  data() {
    return {
      showSearchModal: false,

      situation: '',
      searchResult: [],
      errorText: '',
    }
  },
  components: {
    semiOverlayModal,
  },
  props: {
    placeData: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    showSearchModal(newVal) {
      if (!newVal) {
        this.situation = '';
        this.searchResult = [];
        this.errorText = '';
      }
    }
  },
  computed: {
    hasPlaceData() {
      return this.placeData && Object.keys(this.placeData).length > 0 && this.placeData.place;
    },
  },
  methods: {
    toggleShowSearchModal(action) {
      switch(action) {
        case 'close':
          this.showSearchModal = false;
          break;
        case 'open':
          this.showSearchModal = true;
          break;
        default:
          this.showSearchModal = !this.showSearchModal;
      }
    },
    async sendSearchPlace() {
      // const res = await this.$store.dispatch('api/postSearchPlacePromise', this.situation);
      // console.log(res)
      // if(res) {
      //   this.searchResult = res;
      // }
      this.errorText = '';
      try {
        const res = await this.$store.dispatch('api/postSearchPlacePromise', this.situation);
        if (!res || !Array.isArray(res)) {
          // console.warn('搜尋結果為空或格式錯誤', res);
          this.searchResult = [];
          this.errorText = '搜尋結果為空或格式錯誤，請重新搜尋。';
          return;
        }
        this.searchResult = res;
      } catch (error) {
        // console.error('搜尋地點時發生錯誤', error);
        this.searchResult = [];
        this.errorText = '搜尋地點時發生錯誤，請重新搜尋。';
      }
    },
    updateSearchPlace(index) {
      const place = this.searchResult[index];
      this.$emit('updateSearchPlace', {
        place: place.place || '',
        placeId: place.placeId || '',
        placeLat: place.placeLat || 0,
        placeLong: place.placeLong || 0,
        placeCountry: place.placeCountry || '',
        areaLevel1: place.areaLevel1 || '',
        areaLevel2: place.areaLevel2 || '',
        areaLevel3: place.areaLevel3 || '',
      });
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.custom-place-finder {
  .search-btn {
    color: $typography-secondary-default;
    background: $color-white;
    width: 100%;
    text-align: left;
    border-radius: .5rem;
    border: solid 1px $color-border;
    padding: 1.5rem 1rem;
  }
  .semi-overlay-modal.search-place {
    .search-place-nav {
      position: relative;
      text-align: center;
      padding: 1rem 4rem;
      .close-btn {
        position: absolute;
        top: .5rem;
        left: .5rem;
        display: flex;
        background: transparent;
        border: none;
        padding: .5rem;
      }
      .save-btn {
        position: absolute;
        top: .5rem;
        right: .5rem;
        color: $typography-text-link;
        background: transparent;
        line-height: 24px;
        border: none;
        padding: .5rem;
      }
    }
    .search-place-option-panel {
      padding: 1.25rem 1.75rem;
      .option-group {
        display: flex;
        flex-wrap: nowrap;
        gap: .5rem;
        .form-control {
          flex-grow: 1;
          width: auto;
          font-size: .875rem;
          line-height: 1.25rem;
          padding: .75rem 1rem;
        }
        .option-btn {
          flex-shrink: 0;
          border-radius: .75rem;
          padding: .5rem 1rem;
        }
      }
      .result-list {
        display: flex;
        flex-direction: column;
        gap: .75rem;
        padding: 1rem 0;
        &.items-exceed {
          position: relative;
          height: 178px;
          overflow: auto;
          &::after {
            content: '';
            position: fixed;
            bottom: 20px;
            left: 0;
            width: 100%;
            height: 2rem;
            background: linear-gradient(to top, white 0%, rgba(255, 255, 255, 0) 100%);
          }
        }
        .result-item {
          .address {
            color: $typography-secondary-default;
          }
        }
        .error-text {
          color: $color-warning;
        }
      }
    }
  }
  .place-info {
    padding-top: .5rem;
    padding-bottom: .5rem;
    .title {
      margin-bottom: .5rem;
    }
    .name {
      position: relative;
      display: flex;
      gap: .25rem;
      padding-left: .25rem;
      padding-right: 4rem;
      .svg-icon {
        flex-shrink: 0;
      }
    }
    .edit-btn {
      position: absolute;
      top: 50%;
      right: .5rem;
      color: $typography-text-link;
      background: transparent;
      line-height: 24px;
      border: none;
      padding: .5rem;
      transform: translateY(-50%);
    }
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.custom-place-finder {
  .custom-modal-footer {
    transition: .5s;
    bottom: -500px;
    &.search-place {
      padding: 0;
    }
    &.active {
      bottom: 0;
    }
  }
  .semi-overlay-modal.search-place {
    .modal-content {
      padding: 0;
    }
  }
}
</style>