import LoadingCover from './LoadingCover.vue'
import ImageUploadButton from './ImageUploadButton.vue'
import CustomSelect from './CustomSelect.vue'
import CustomTextInput from './CustomTextInput.vue'

const Components = [
  LoadingCover, ImageUploadButton, CustomSelect, CustomTextInput
]

const install = function (Vue) {
  Components.forEach(component => {
    Vue.component(component.name, component)
  });
}

export default {
 install
}
