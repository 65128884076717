<template>
  <div>
    <section class="imgs">
      <div class="square-outer">
        <img :src="totalImgArray[photoIndex]" v-if="hasImg" />
        <span class="use-count" v-if="cloth.usedCount !== undefined">已穿著 {{cloth.usedCount}} 次</span>
      </div>
    </section>

    <section v-if="totalImgArray.length > 1">
      <div class="sub-imgs">
        <div class="sub-imgs-inner">
          <div class="sub-imgs-content" v-for="(item, index) in totalImgArray" :key="index">
            <div class="sub-img-photo" :class="{'active': photoIndex === index}" @click="setShowPhotoIndex(index)">
              <img :src="item" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="info">
      <div class="public-pill" v-if="cloth.isPublic">
        公開的衣物
      </div>

      <div class="info-row">
        <div class="info-col-title">
          <label>單品資訊</label>
        </div>
      </div>

      <div class="info-row v-center">
        <div class="info-col-title sub">
          <label>類別:</label>
        </div>

        <div class="info-col-content">
          {{category1Name}} / {{category2Name}}
        </div>
      </div>

      <div class="info-row v-center">
        <div class="info-col-title sub">
          <label>色系:</label>
        </div>

        <div class="info-col-content">
          <ColorSelect v-model="cloth.color" :selection="false"></ColorSelect>
        </div>
      </div>

      <hr/>

      <div class="info-row v-center">
        <div class="info-col-title sub">
          <label>品牌:</label>
        </div>

        <div class="info-col-content">
          <div class="row no-gutters">
            <div class="col-auto pr-2" v-for="(item) in cloth.brands" :key="item">
              <span class="pills brand-pill">
                <span>#{{item}}</span>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="info-row v-center">
        <div class="info-col-title sub">
          <label>標籤:</label>
        </div>

        <div class="info-col-content">
          <div class="row no-gutters">
            <div class="col-auto pr-2" v-for="(item) in cloth.tags" :key="item">
              <span class="pills tag-pill">
                <span>#{{item}}</span>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="info-row v-center">
        <div class="info-col-title sub">
          <label>尺寸:</label>
        </div>

        <div class="info-col-content">
          {{cloth.size}}
        </div>
      </div>

      <div class="info-row v-center">
        <div class="info-col-title sub">
          <label>入手時間:</label>
        </div>

        <div class="info-col-content">
          {{getTimeString}}
        </div>
      </div>

      <div class="info-row v-center">
        <div class="info-col-title sub">
          <label>入手狀態:</label>
        </div>

        <div class="info-col-content">
          {{getStatusString}}
        </div>
      </div>

      <div class="info-row v-center">
        <div class="info-col-title sub">
          <label>入手價格:</label>
        </div>

        <div class="info-col-content">
          {{priceString}}
        </div>
      </div>

      <div class="info-row v-center">
        <div class="info-col-title sub">
          <label>主要材質:</label>
        </div>

        <div class="info-col-content">
          {{materialString}}
        </div>
      </div>

      <div class="info-row v-center">
        <div class="info-col-title sub">
          <label>清洗規範:</label>
        </div>

        <div class="info-col-content">
          {{washRuleString}}
        </div>
      </div>

      <div class="info-row v-center">
        <div class="info-col-title sub">
          <label>清洗提醒:</label>
        </div>

        <div class="info-col-content">
          {{washAlertString}}
        </div>
      </div>

      <div class="info-row">
        <div class="info-col-title sub">
          <label>備註:</label>
        </div>

        <div class="info-col-content" v-html="descWithBr">
        </div>
      </div>

    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ColorSelect from '@/components/closet/ColorSelect.vue';
import '@/assets/css/buttons.css';

export default {
  name: 'ViewClothContent',
  data() {
    return {
      photoIndex: 0,
    };
  },
  components: {
    ColorSelect,
  },
  props: {
    cloth: {
      type: Object,
      required: true,
    },
  },
  beforeDestroy() {
  },
  mounted() {
  },
  computed: {
    ...mapState(['clothColors', 'clothCategories']),
    hasImg() {
      return this.cloth.imgs.length > 0;
    },
    category1() {
      const res = this.clothCategories.filter((item) => {
        return this.cloth.category1 === item.serial;
      });
      return res[0];
    },
    category1Name() {
      return this.category1.name;
    },
    category2Name() {
      const res = this.category1.sub.filter((item) => {
        return this.cloth.category2 === item.serial;
      });
      if (res.length > 0) {
        return res[0].name;
      }
      return '';
    },
    getTimeString() {
      let res = '';
      if (this.cloth.getYear !== -1) {
        res += `${this.cloth.getYear} 年`;
      }
      if (this.cloth.getMonth !== -1) {
        const monthAry = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'];
        res += ` ${monthAry[this.cloth.getMonth-1]}`
      }
      return res.length>0?res:'-';
    },
    getStatusString() {
      const opts = ['-', '新衣', '二手衣'];
      return opts[this.cloth.getStatus + 1];
    },
    priceString() {
      if (this.cloth.price === -1) {
        return '-';
      }
      return `$ ${this.cloth.price}`;
    },
    materialString() {
      const opts = ['-', '棉Cotton', '麻Linen', '絲Silk', '羊毛 Wool', '聚酯纖維Polyester', '尼龍Nylon', '嫘縈(人造絲)Rayon/Viscose', '彈性纖維Spandex', '環境友善(再生纖維等)', '其他', '100 % 回收聚酯材料 : (RT回收二手衣)+(RB寶特瓶)'];
      return opts[this.cloth.material + 1];
    },
    washRuleString() {
      const opts = ['-', '手洗', '洗衣機-柔洗', '洗衣機-標準', '送洗', '送洗-乾洗', '40度以下清洗'];
      return opts[this.cloth.washRule + 1];
    },
    washAlertMonthOpt() {
      const opts = [{
        value: -1,
        name: '未設定'
      }];
      opts.push({
        value: 1,
        name: '每個月'
      });
      opts.push({
        value: 2,
        name: '每兩個月'
      });
      opts.push({
        value: 3,
        name: '每一季'
      });
      opts.push({
        value: 6,
        name: '每半年'
      });
      return opts;
    },
    washAlertDayOpt() {
      const opts = [{
        value: -1,
        name: '未設定'
      }];
      opts.push({
        value: 1,
        name: '1日'
      });
      opts.push({
        value: 15,
        name: '15日'
      });
      return opts;
    },
    washAlertString() {
      if (!this.cloth.washAlert) {
        return '無';
      }
      const washAlertMonthString = this.washAlertMonthOpt.filter((item) => {return item.value === this.cloth.washAlertMonth})[0].name;
      const washAlertDayString = this.washAlertDayOpt.filter((item) => {return item.value === this.cloth.washAlertDay})[0].name;
      return `${washAlertMonthString} ${washAlertDayString}`;
    },
    descWithBr() {
      return this.cloth.desc.replace(/(?:\r\n|\r|\n)/g, '<br>');
    },
    hasPhotoBack() {
      return this.cloth.photoBack.length > 0;
    },
    hasPhotoBrand() {
      return this.cloth.photoBrand.length > 0;
    },
    hasPhotoWash() {
      return this.cloth.photoWash.length > 0;
    },
    hasMaterialPhoto() {
      return this.cloth.materialPhoto.length > 0;
    },
    totalImgArray() {
      const imgs = [ ... this.cloth.imgs ];
      if (this.hasPhotoBack) {
        imgs.push(this.cloth.photoBack);
      }
      if (this.hasPhotoBrand) {
        imgs.push(this.cloth.photoBrand);
      }
      if (this.hasPhotoWash) {
        imgs.push(this.cloth.photoWash);
      }
      if (this.hasMaterialPhoto) {
        imgs.push(this.cloth.materialPhoto);
      }
      return imgs;
    },
  },
  watch: {
  },
  methods: {
    setShowPhotoIndex(index) {
      this.photoIndex = index;
    },
  }
}
</script>

<style scoped>
  .imgs {
    position: relative;
  }

  .square-outer {
    position: relative;
    width: 100%;
    background-color: #eeeeee;
    padding-top: 100%;
  }

  .square-outer>img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .use-count {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    border-radius: 999px;
    background-color: #fff;
    color: var(--text-dark);
    font-size: 12px;
    padding: 4px 12px;
    box-shadow: 0px 0px 4px #00000040;
  }


  /* info */
  .info {
    padding: 1rem;
    position: relative;
  }

  .info-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    flex-wrap: nowrap;
  }

  .info-row.v-center {
    align-items: center;
  }

  .info-col-title {
    flex: 1 1;
    font-size: 14px;
    padding-right: 1rem;
  }

  .info-col-title.sub {
    padding-left: 1rem;
  }

  .info-col-title>label {
    font-weight: 600;
    margin: 0;
  }

  .info-col-title>label.required::after {
    content: '*';
    color: var(--required-orange);
  }

  .info-col-content {
    flex: 0 0 70%;
    max-width: 70%;
    font-size: 12px;
  }

  .pills.brand-pill {
    --pill-color: var(--main-green);
  }

  .pills.tag-pill {
    --pill-color: var(--main-yellow);
  }

  .pills {
    position: relative;
    display: block;
    font-size: 12px;
    color: var(--pill-color);
    border-radius: 999px;
    padding: 3px 14px;
    border: 1px solid var(--pill-color);
    margin-bottom: .25rem;
    margin-top: .25rem;
  }

  .public-pill {
    position: absolute;
    top: .5rem;
    right: 1rem;
    display: block;
    font-size: 12px;
    color: #fff;
    border-radius: 999px;
    padding: 3px 14px;
    border: 1px solid var(--main-green);
    background-color: var(--main-green);
    margin-bottom: .25rem;
    margin-top: .25rem;
  }

  .sub-imgs {
    position: relative;
    display: block;
    /* grid-template-rows: auto;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 4px; */
    overflow-x: auto;
    padding: 4px 0;
  }

  .sub-imgs-inner {
    display: flex;
    flex-wrap: nowrap;
  }

  .sub-imgs-content {
    position: relative;
  }

  .sub-imgs-content:not(:first-child) {
    margin-left: 4px;
  }

  .sub-img-photo {
    display: block;
    position: relative;
    padding-top: 100%;
    width: 100px;
    background-color: #ddd;
    border: solid 2px #ddd;
  }

  .sub-img-photo.active {
    border-color: var(--main-orange);
  }

  .sub-img-photo>img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: contain;
  }

</style>
