<template>
  <div class="cloth-size-select new-style">
    <b-form-select
        v-model="rootVal"
        :options="rootOpt"
        required
    >
    </b-form-select>
    <div class="select-size" v-if="rootVal !== ''">
      <b-form-select
          v-model="secVal"
          :options="secOpts"
          required
      ></b-form-select>
      <b-form-select
          v-model="thirVal"
          :options="thirdSelectOpts"
          required
      ></b-form-select>
    </div>
  </div>
</template>


<script>
export default {
  name: 'ClothSizeSelectNewStyle',
  data() {
    return {
      inited: false,
      rootVal: '',
      secVal: '',
      thirVal: '',

      rootOpt: [
        {
          text: this.nullText,
          value: '',
        },
        {
          text: '通用尺寸',
          value: '通用',
        },
        {
          text: '褲子尺寸',
          value: '褲子',
        },
        {
          text: '鞋子尺寸',
          value: '鞋子',
        },
        {
          text: '帽子尺寸',
          value: '帽子',
        }
      ],

      intenalSizeOpt: [
        {
          text: 'F(Free Size)',
          value: 'F',
        },
        {
          text: 'XXS',
          value: 'XXS',
        },
        {
          text: 'XS',
          value: 'XS',
        },
        {
          text: 'S',
          value: 'S',
        },
        {
          text: 'M',
          value: 'M',
        },
        {
          text: 'L',
          value: 'L',
        },
        {
          text: 'XL',
          value: 'XL',
        },
        {
          text: 'XXL',
          value: 'XXL',
        },
        {
          text: '3XL',
          value: '3XL',
        },
        {
          text: '4XL',
          value: '4XL',
        },
      ],
      feetTable: [
        [22.8,35,3.5,5,21.5,21],
        [23.1,35.5,4,5.5,22,21.5],
        [23.5,36,4.5,6,22.5,22],
        [23.8,37,5,6.5,23,22.5],
        [24.1,37.5,5.5,7,23.5,23],
        [24.5,38,6,7.5,24,23.5],
        [24.8,38.5,6.5,8,24.5,24],
        [25.1,39,7,8.5,25,24.5],
        [25.4,40,7.5,9,25.5,25],
        [25.7,41,8,9.5,26,25.5],
        [26,42,8.5,10,26.5,26],
        [26.7,43,9,10.5,27.5,27],
        [27.3,44,10.5,12,28.5,28],
        [27.9,45,11.5,13,29.5,29],
        [28.6,46.5,12.5,14,30.5,30],
        [29.2,48.5,14,15.5,31.5,31],
      ],
    };
  },
  props: {
    value: {
      type: String,
      required: false,
      default: '',
    },
    nullText: {
      type: String,
      required: false,
      default: '請選擇',
    },
  },
  components: {
  },
  computed: {
    thirdSelectOpts() {
      const v2 = this.secVal;
      if (v2 === '國際尺碼') {
        return this.intenalSizeOpt;
      } else if (v2 === '腰圍') {
        return this.waistOpts;
      } else if (v2 === '歐洲鞋') {
        return this.feetSizeEuOpt;
      } else if (v2 === '美國男鞋') {
        return this.feetSizeUsMaOpt;
      } else if (v2 === '美國女鞋') {
        return this.feetSizeUsFeOpt;
      } else if (v2 === '亞洲男鞋') {
        return this.feetSizeJaMaOpt;
      } else if (v2 === '亞洲女鞋') {
        return this.feetSizeJaFeOpt;
      } else if (v2 === '美國尺寸') {
        return this.headSizeUsOpt
      }
      return this.intenalSizeOpt;
    },
    feetSizeEuOpt() {
      const opt = [];
      for (let i = 0 ; i < this.feetTable.length ; i ++ ) {
        const row = this.feetTable[i]
        opt.push({
          value: `${row[1]}`,
          text: `${row[1]}`,
        });
      }
      return opt;
    },
    feetSizeUsMaOpt() {
      const opt = [];
      for (let i = 0 ; i < this.feetTable.length ; i ++ ) {
        const row = this.feetTable[i]
        opt.push({
          value: `${row[2]}`,
          text: `${row[2]}`,
        });
      }
      return opt;
    },
    feetSizeUsFeOpt() {
      const opt = [];
      for (let i = 0 ; i < this.feetTable.length ; i ++ ) {
        const row = this.feetTable[i]
        opt.push({
          value: `${row[3]}`,
          text: `${row[3]}`,
        });
      }
      return opt;
    },
    feetSizeJaMaOpt() {
      const opt = [];
      for (let i = 0 ; i < this.feetTable.length ; i ++ ) {
        const row = this.feetTable[i]
        opt.push({
          value: `${row[4]}`,
          text: `${row[4]}`,
        });
      }
      return opt;
    },
    feetSizeJaFeOpt() {
      const opt = [];
      for (let i = 0 ; i < this.feetTable.length ; i ++ ) {
        const row = this.feetTable[i]
        opt.push({
          value: `${row[5]}`,
          text: `${row[5]}`,
        });
      }
      return opt;
    },
    headSizeUsOpt() {
      const opt = [];
      const sizes = ['6 5/8', '6 3/4', '6 7/8', '7', '7 1/8', '7 1/4', '7 3/8', '7 1/2', '7 5/8'];
      for (let i = 53 ; i <= 61 ; i ++ ) {
        opt.push({
          value: i,
          text: `${sizes[i-53]}`,
        });
      }
      return opt;
    },
    waistOpts() {
      const res = [];
      for (let i=18;i<=48;i++) {
        res.push({
          text: `${i}`,
          value: `${i}`,
        });
      }
      return res;
    },
    secOpts() {
      const res = [];
      const v1 = this.rootVal;
      if (v1 !== '鞋子') {
        res.push({
          text: '國際尺碼',
          value: '國際尺碼',
        });
      }
      if (v1 === '褲子') {
        res.push({
          text: '腰圍(inch)',
          value: '腰圍',
        });
      }
      if (v1 === '鞋子') {
        res.push({
          text: '歐洲鞋',
          value: '歐洲鞋',
        });
        res.push({
          text: '美國男鞋',
          value: '美國男鞋',
        });
        res.push({
          text: '美國女鞋',
          value: '美國女鞋',
        });
        res.push({
          text: '亞洲男鞋',
          value: '亞洲男鞋',
        });
        res.push({
          text: '亞洲女鞋',
          value: '亞洲女鞋',
        });
      }
      if (v1 === '帽子') {
        res.push({
          text: '美國尺寸',
          value: '美國尺寸',
        });
      }
      return res;
    }
	},
  watch: {
    rootVal(val) {
      if (!this.inited) {
        return;
      }
      if (val === '') {
        this.secVal = '';
        this.thirVal = '';
      } else {
        this.secVal = this.secOpts[0].value;
      }
    },
    secVal(val) {
      if (!this.inited) {
        return;
      }
      if (val !== '') {
        this.thirVal = this.thirdSelectOpts[0].value;
      }
    },
    thirVal() {
      if (!this.inited) {
        return;
      }
      this.__emitValue();
    },
    value(val) {
      this.inited = false;
      this.parsePropValue(val);
      setTimeout(() => {
        this.inited = true;
      }, 10);
    }
  },
  mounted() {
    this.parsePropValue(this.value);
    this.inited = true;
	},
  methods: {
    __emitValue() {
      let resString = '';
      if (this.rootVal !== '') {
        resString = `${this.rootVal}|${this.secVal}|${this.thirVal}`;
      }
      this.$emit('input', resString);
    },
    parsePropValue(val) {
      if (val.length > 0) {
        const vals = val.split('|');
        this.rootVal = vals[0];
        this.secVal = vals[1];
        this.thirVal = vals[2];
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.cloth-size-select.new-style {
  width: 100%;
  .select-size {
    display: flex;
    gap: .5rem;
    margin-top: .5rem;
    .size {
      flex: 0 0 27%;
    }
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.cloth-size-select.new-style {
  .select-size {

  }
}
</style>
