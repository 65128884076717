<template>
  <transition name="fade">
    <FullScreenModal v-if="isExchangeShow" title="購物退換貨政策流程" @back="closePolicy">
      <div class="container py-3">
        <p>
          非常歡迎您光臨長腳衣櫥線上服務（以下簡稱本網站），為了提供您便利又安心的交易環境，特此向您說明本網站的退換貨政策。為了保障您的權益，請務必詳讀本條款，在您註冊成為本網站會員、或開始使用本服務時，即視為您已經閱讀、瞭解並同意接受本條款及「隱私聲明」之所有內容，並視為您已確認詳閱並同意個人資料保護告知暨同意事項。<br/>
          <br/>
          如有任何問題，請與我們聯繫。<br/>
          <br/>
          一、退款期限<br/>
          <br/>
          本網站販售之商品/服務皆為非以有形媒介提供之數位內容或一經提供即為完成之線上服務，為特殊性質商品，依消費者保護法及通訊交易解除權合理例外情事適用準則之規定，不適用七天猶豫期之權益。<br/>
          <br/>
          本網站提供優於規定的退款期限，若服務尚未使用，可於七天內退款。若服務已使用則無法退款。<br/>
          <br/>
          以下為服務已使用狀態：<br/>
          使用單次服務：已暫存/送出資訊<br/>
          使用訂閱制服務：已輸入個人化資訊或任一個人化設定。<br/>
          <br/>
          二、退款流程<br/>
          <br/>
          請以Email或官方LINE訊息聯繫長腳衣櫥客服人員，待確認可退款後，由客服人員協助完成退款申請。<br/>
          若已取得電子發票，將會於我方完成退款後，將電子發票作廢。<br/>
          <br/>
          三、退款金流說明<br/>
          <br/>
          完成退款申請後，本公司將於7-14個工作日內（不含週休及國定例假日），將該筆金額退刷至您的交易信用卡，完成刷退後將以電子郵件通知。<br/>
          <br/>
          由於各銀行入帳時間、作業程序之不同，可能需 15-20 天入帳，刷退款項可能列於本月或次月的帳單上，或直接與發卡銀行確認。<br/>
          <br/>
          四、聯繫管道<br/>
          <br/>
          如需退款，可前往本站「聯絡我們」頁面提交表單。或使用以下方式<br/>
          客服信箱：service@walkingcloset.co<br/>
          客服LINE：@walkingcloset<br/>
          客服專線：02-7752-2510<br/>
          聯絡地址：台北市中山區林森北路575號7樓之9
        </p>
      </div>
    </FullScreenModal>
  </transition>
</template>

<script>
import {
  mapGetters,
  mapActions
} from 'vuex';
import FullScreenModal from '@/components/FullScreenModal.vue';

export default {
  name: 'ExchangeModal',
  data() {
    return {
    };
  },
  components: {
    FullScreenModal,
  },
  props: {},
  beforeDestroy() {},
  async mounted() {},
  computed: {
    ...mapGetters(['isExchangeShow']),
  },
  watch: {

  },
  methods: {
    ...mapActions(['setExchangeShow']),
    closePolicy() {
      this.setExchangeShow(false);
    },
  }
}
</script>

<style scoped>
</style>
