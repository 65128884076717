export default {
  namespaced: true,
  state: {
    clothManagerShow: false,
    clothId: -1,
    clothType: 'view',
    clothEditStep: -1,
    clothImgUrl: '',
    cropProgress: -1,
  },
  getters: {
    isClothManagerShow(state) {
      return state.clothManagerShow;
    },
    clothId(state) {
      return state.clothId;
    },
    clothType(state) {
      return state.clothType;
    },
    clothEditStep(state) {
      return state.clothEditStep;
    },
    clothImgUrl(state) {
      return state.clothImgUrl;
    },
    cropProgress(state) {
      return state.cropProgress;
    },
  },
  mutations: {
    setClothManagerShow(state, data) {
      state.clothManagerShow = data;
    },
    setClothId(state, data) {
      state.clothId = data;
    },
    setClothType(state, data) {
      state.clothType = data;
    },
    setClothEditStep(state, data) {
      state.clothEditStep = data;
    },
    setClothImgUrl(state, data) {
      state.clothImgUrl = data;
    },
    setCropProgress(state, data) {
      state.cropProgress = data;
    },
  },
  actions: {
    setClothId({ commit }, serial) {
      commit('setClothId', serial);
    },
    setClothType({ commit }, type) {
      commit('setClothType', type);
    },
    setClothEditStep({ commit }, step) {
      commit('setClothEditStep', step);
    },
    setClothImgUrl({ commit }, imgUrl) {
      commit('setClothImgUrl', imgUrl);
    },
    setCropProgress({ commit }, progress) {
      commit('setCropProgress', progress);
    },
    setClothData({ commit }, { clothId, clothType, editStep, clothImgUrl }) {
      if (clothId !== undefined) {
        commit('setClothId', clothId);
      }
      if (clothType !== undefined) {
        commit('setClothType', clothType);
      }
      if (editStep !== undefined) {
        commit('setClothEditStep', editStep);
      }
      if (clothType !== undefined) {
        commit('setClothImgUrl', clothImgUrl);
      }
    },
    clearClothData({ commit }) {
      commit('setClothId', -1);
      commit('setClothType', 'view');
      commit('setClothEditStep', -1);
      commit('setClothImgUrl', '');
      commit('setCropProgress', -1);
    },
    openClothManager({ commit }) {
      commit('setClothManagerShow', true);  // 打開，設為 true
    },
    closeClothManager({ commit }) {
      commit('setClothManagerShow', false); // 關閉，設為 false
    },
  },
}
