<template>
  <div class="cloth-simple-info">
    <div class="info-header">
      <h2 class="fs-h2" v-if="brandNames" v-text="brandNames"></h2>
      <div class="category-group">
        <div class="category-name fs-16px">{{category1Name}}/ {{category2Name}}</div>
        <div class="drawer-item" v-if="drawerData" @click="openViewModal">
          <Icon name="drawer-icon" size="24" />
          <div class="name" v-text="drawerData.name"></div>
        </div>
      </div>
    </div>

    <div class="info-content">
      <h3 class="title fs-h3">衣物資訊</h3>
      <div class="info-group">
        <div class="info-item">
          <div class="fs-14px">
            <span>入手狀態：</span>
            <span>新衣</span>
          </div>
          <div class="fs-14px">
            <span>入手時間：</span>
            <span v-text="getTimeString"></span>
          </div>
          <div class="fs-14px">
            <span>主要材質：</span>
            <span v-text="materialString"></span>
          </div>
          <div class="fs-14px d-flex align-items-center">
            <span>色系：</span>
            <span v-if="cloth.color === -1">-</span>
            <div class="color-group" v-else>
              <div class="preview" :class="cloth.color >= 12 ? `color-${cloth.color}` : ''" :style="cloth.color < 12 ? { background: clothColors[cloth.color].color } : {}"></div>
              <div class="name" v-text="clothColors[cloth.color].title"></div>
            </div>
          </div>
          <div class="fs-14px">
            <span>清洗規範：</span>
            <span v-text="washRuleString"></span>
          </div>
          <div class="fs-14px">
            <span>清洗提醒：</span>
            <span v-text="washAlertString"></span>
          </div>
          <hr>
          <div class="fs-14px">
            <span>衣物定價：</span>
            <span v-text="originalPriceString"></span>
          </div>
          <div class="fs-14px">
            <span>購入價格：</span>
            <span v-text="priceString"></span>
          </div>
        </div>

        <div class="info-item">
          <div class="fs-14px fw-bold">備註</div>
          <div class="fs-14px" v-html="descWithBr ? descWithBr : '無'"></div>
        </div>

        <div class="info-item">
          <div class="tags-list">
            <div class="tags-item fs-14px" v-for="(item) in cloth.tags" :key="item">
              <span v-text="'#' + item"></span>
            </div>
          </div>
        </div>

      </div>
    </div>

    <transition name="fade">
      <DrawerContentModal v-if="showDrawerViewModal" :drawer="drawerData" @back="closeViewModal" @deleted="drawerDeleted" @updated="drawerUpdated" />
    </transition>

  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DrawerContentModal from "@/components/drawer/DrawerContentModal.vue";

export default {
  name: 'ClothSimpleInfo',
  data() {
    return {
      showDrawerViewModal: false,
    };
  },
  components: {
    DrawerContentModal
  },
  props: {
    cloth: {
      type: Object,
      required: true,
    },
    drawerData: {
      type: Object,
      required: false,
      default: () => null,
    }
  },
  created() {
  },
  beforeDestroy() {
  },
  async mounted() {
  },
  computed: {
    ...mapState(['clothColors', 'clothCategories']),
    category1() {
      const res = this.clothCategories.filter((item) => {
        return this.cloth.category1 === item.serial;
      });
      return res[0];
    },
    category1Name() {
      return this.category1.name;
    },
    category2Name() {
      const res = this.category1.sub.filter((item) => {
        return this.cloth.category2 === item.serial;
      });
      if (res.length > 0) {
        return res[0].name;
      }
      return '';
    },
    getTimeString() {
      let res = '';
      if (this.cloth.getYear !== -1) {
        res += `${this.cloth.getYear}`;
      }
      if (this.cloth.getMonth !== -1) {
        const month = String(this.cloth.getMonth).padStart(2, '0');
        res += `.${month}`;
      }
      return res.length>0?res:'-';
    },
    getStatusString() {
      const opts = ['-', '新衣', '二手衣'];
      return opts[this.cloth.getStatus + 1];
    },
    originalPriceString() {
      if (this.cloth.originalPrice === -1) {
        return '-';
      }
      return `$${this.cloth.originalPrice}`;
    },
    priceString() {
      if (this.cloth.price === -1) {
        return '-';
      }
      return `$${this.cloth.price}`;
    },
    materialString() {
      const opts = ['-', '棉Cotton', '麻Linen', '絲Silk', '羊毛 Wool', '聚酯纖維Polyester', '尼龍Nylon', '嫘縈(人造絲)Rayon/Viscose', '彈性纖維Spandex', '環境友善(再生纖維等)', '其他'];
      return opts[this.cloth.material + 1];
    },
    brandNames() {
      return this.cloth.brands.length
          ? this.cloth.brands.join(', ')
          : '尚無品牌資訊';
    },
    washRuleString() {
      const opts = ['-', '手洗', '洗衣機-柔洗', '洗衣機-標準', '送洗', '送洗-乾洗', '40度以下清洗'];
      return opts[this.cloth.washRule + 1];
    },
    washAlertMonthOpt() {
      const opts = [{
        value: -1,
        name: '未設定'
      }];
      opts.push({
        value: 1,
        name: '每個月'
      });
      opts.push({
        value: 2,
        name: '每兩個月'
      });
      opts.push({
        value: 3,
        name: '每一季'
      });
      opts.push({
        value: 6,
        name: '每半年'
      });
      return opts;
    },
    washAlertDayOpt() {
      const opts = [{
        value: -1,
        name: '未設定'
      }];
      opts.push({
        value: 1,
        name: '1日'
      });
      opts.push({
        value: 15,
        name: '15日'
      });
      return opts;
    },
    washAlertString() {
      if (!this.cloth.washAlert) {
        return '無';
      }
      const washAlertMonthString = this.washAlertMonthOpt.filter((item) => {return item.value === this.cloth.washAlertMonth})[0].name;
      const washAlertDayString = this.washAlertDayOpt.filter((item) => {return item.value === this.cloth.washAlertDay})[0].name;
      return `${washAlertMonthString} ${washAlertDayString}`;
    },
    descWithBr() {
      return this.cloth.desc.replace(/(?:\r\n|\r|\n)/g, '<br>');
    },
  },
  watch: {
  },
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
    closeModal() {
      this.$emit('close');
    },
    okClicked() {
      this.$emit('ok');
    },
    openViewModal() {
      this.showDrawerViewModal = true;
    },
    closeViewModal() {
      this.showDrawerViewModal = false;
    },
    drawerDeleted() {
      this.closeViewModal();
      this.showMsgModal(`已刪除抽屜【${this.drawerData.name}】`);
      this.$emit('drawerDeleted', this.cloth.serial);
    },
    drawerUpdated() {
      this.$emit('drawerUpdated', this.cloth.serial);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.cloth-simple-info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .info-header {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    padding: .5rem 0;
    h2.fs-h2 {
      margin-bottom: 0;
    }
    .category-group {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .category-name {
        flex-shrink: 0;
        max-width: calc(75% - 1rem);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow:hidden;
      }
      .drawer-item {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: .125rem;
        flex-shrink: 0;
        max-width: 25%;
        border: solid 1px $color-border;
        border-radius: .5rem;
        padding: .25rem .5rem;
        &:active {
          filter: brightness(.95);
        }
        .name {
          flex-shrink: 0;
          width: calc(100% - 26px);
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow:hidden;
        }
      }
    }
  }

  .info-content {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    .title {
      padding: .5rem 0;
      margin-bottom: 0;
    }
    .info-group {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding-bottom: 1rem;
      .info-item {
        display: flex;
        flex-direction: column;
        gap: .5rem;
        hr {
          margin: 0;
        }
      }
    }
    .color-group {
      display: flex;
      align-items: center;
      gap: .25rem;
      .preview {
        width: 12px;
        height: 12px;
        border-radius: 100px;
        &.color-12 {
          background: conic-gradient(from 226.43deg at 85.42% 110.42%, #808080 0deg, #9F9F9A 46.01deg, #DEDECF 86.17deg, #808080 360deg);
        }
        &.color-13 {
          background: conic-gradient(from 199.62deg at 72.92% 100%, #CAA628 -178.13deg, #E9D58F 128.39deg, #CAA628 181.87deg, #E9D58F 488.39deg);
        }
        &.color-14 {
          background: url('~@/assets/icons/color-other.png');
          background-size: contain;
        }
      }
    }
    .tags-list {
      display: flex;
      gap: 1rem;
      .tags-item {
        color: $typography-text-link;
        border: solid 1px $typography-disabled;
        border-radius: .75rem;
        padding: .25rem .5rem;
      }
    }
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.cloth-simple-info {
}
</style>
