<template>
  <div class="main-wrap">
    <div class="main-app" v-if="inited">
      <div class="main-header" v-if="showNav">
        <top-nav></top-nav>
      </div>

      <div class="main-content" ref="main-content">
        <router-view :scroll="mainScrollTop" ref="main-component" @error="mainContentError"/>
      </div>

      <div class="bottom-nav" v-if="showBottomNav">
        <static-nav @recordCloth="showCreateClothPanel" @recordOutfit="showCreateOutfitPanel" @addByNumber="showAddByNumberModal"></static-nav>
      </div>
    </div>

    <transition name="fade">
      <CreateCloth v-if="showCreateCloth" @created="clothCreated" @back="createClothCancel"></CreateCloth>
    </transition>

    <transition name="fade">
      <CreateOutfit v-if="isCreateOutfitShow" @created="outfitCreated" @back="createOutfitCancel" :create-default-date="outfitCreateDefaultDate"></CreateOutfit>
    </transition>

    <MainConfig />
    <MemberConfig />
    <BodyConfig />
    <MsgModal />
    <PolicyModal />
    <BuyFlowModal />
    <ExchangeModal />
    <ResetPasswordModal />

    <transition name="fade">
      <FullScreenModal v-if="isAddFriendShow" title="加入好友" @back="closeAddFriendModal">
        <AddFriend @close="closeAddFriendModal"></AddFriend>
      </FullScreenModal>
    </transition>

    <transition name="fade">
      <AddByNumberModal v-if="showAddByNumber" @back="showAddByNumber = false"></AddByNumberModal>
    </transition>

    <transition name="fade">
      <walking-closet-loading v-if="busy"></walking-closet-loading>
    </transition>
  </div>
</template>

<script>
import Vue from 'vue';
// import { mapState, mapActions, mapGetters } from 'vuex';
import { mapState, mapGetters, mapActions } from 'vuex';
import { BootstrapVue, BootstrapVueIcons  } from 'bootstrap-vue';

import moment from 'moment';
moment.locale('zh-tw');

import WalkingClosetLoading from '@/components/WalkingClosetLoading.vue';
import CommonInstall from '@/components/commons/regCommonComponents.js';
import AddByNumberModal from '@/components/commons/AddByNumberModal.vue';
CommonInstall.install(Vue);

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(require('vue-cookies'));

import TopNav from '@/views/TopNav.vue';
import StaticNav from '@/components/StaticNav.vue';
import CreateCloth from '@/views/closet/CreateCloth.vue';
import CreateOutfit from '@/views/closet/CreateOutfit.vue';
import MainConfig from '@/views/config/MainConfig.vue';
import MemberConfig from '@/views/config/MemberConfig.vue';
import BodyConfig from '@/views/config/BodyConfig.vue';
import MsgModal from '@/views/common/MsgModal.vue';
import PolicyModal from '@/components/PolicyModal.vue';
import BuyFlowModal from '@/components/BuyFlowModal.vue';
import ExchangeModal from '@/components/ExchangeModal.vue';
import ResetPasswordModal from '@/components/login/ResetPasswordModal.vue';

import FullScreenModal from '@/components/FullScreenModal.vue';
import AddFriend from '@/views/share/AddFriend.vue';

import '@/assets/css/modal-transition.css';
import '@/assets/css/new-css.css';

// import { initializeApp } from "firebase/app";
// import { getMessaging, getToken, onMessage } from "firebase/messaging";

export default {
  name: 'MainApp',
  data() {
    return {
      inited: false,
      reading: false,
      mainScrollTop: 0,

      showCreateCloth: false,
      showAddByNumber: false,

      user: {

      },
    };
  },
  props: {
  },
  components: {
    TopNav,
    StaticNav,
    WalkingClosetLoading,
    CreateCloth,
    MsgModal,
    MainConfig,
    MemberConfig,
    BodyConfig,
    PolicyModal,
    CreateOutfit,
    FullScreenModal,
    AddFriend,
    BuyFlowModal,
    ExchangeModal,
    AddByNumberModal,
    ResetPasswordModal,
    // WcModal
  },
  async mounted() {

    // if ('Notification' in window) {
    //   console.log('Notification.permission:' + Notification.permission);
    //   if (Notification.permission != 'granted') {
    //     console.log('Ask user permission')
    //     Notification.requestPermission(status => {
    //       console.log('Status:' + status)
    //     });
    //   } else if (Notification.permission === 'granted') {
    //     const firebaseConfig = {
    //       apiKey: "AIzaSyCAmuNSnoK76xyke8Y4Z62FSknP8CIq75c",
    //       authDomain: "walking-closet-81ae5.firebaseapp.com",
    //       projectId: "walking-closet-81ae5",
    //       storageBucket: "walking-closet-81ae5.appspot.com",
    //       messagingSenderId: "228227311842",
    //       appId: "1:228227311842:web:f4ff3a298af844c449d6bb",
    //       measurementId: "G-WP6HNKBLWG"
    //     };
    
    //     initializeApp(firebaseConfig);
    //     const messaging = getMessaging();
    //     onMessage(messaging, (payload) => {
    //       console.log('Message received. ', payload);
    //       // ...
    //     });
    //     getToken(messaging, {vapidKey: "BLD_hWTEyVwlG4jLXCV7VqtABmox4xNLQL5NCvB6HG94lmTnznOfOmz_7qmP-r2g2iGJp29mIp2Wt0__IM7BlhE"}).then((currentToken) => {
    //       if (currentToken) {
    //         console.log(currentToken);
    //       } else {
    //         // Show permission request UI
    //         console.log('No registration token available. Request permission to generate one.');
    //         // ...
    //       }
    //     }).catch((err) => {
    //       console.log('An error occurred while retrieving token. ', err);
    //       // ...
    //     });

        
    //   }
    // }

    // Request Permission of Notifications
    // messaging.requestPermission().then(() => {
    //   console.log('Notification permission granted.');

    //   // Get Token
    //   messaging.getToken().then((token) => {
    //     console.log(token)
    //   })
    // }).catch((err) => {
    //   console.log('Unable to get permission to notify.', err);
    // });

    this.reading = true;
    try {
      // 目前在router做init login token
      await this.$store.dispatch('initLoginToken');
      // console.log('try log in');

      // await this.refreshUser();
      // await this.refreshCloths();
      // await this.refreshClothCategories();
      // await this.refreshClothColors();
      console.log('mounted');
      this.inited = true;
      setTimeout(() => {
        this.$refs['main-content'].addEventListener('scroll', () => {
          this.mainScrollTop = this.$refs['main-content'].scrollTop;
        }, false);
        this.safariHacks();
      }, 0);

    } catch(e) {
      console.error(e);
    } finally {
      this.reading = false;
    }
  },
  computed: {
    ...mapState(['debug', 'errMsgs']),
    ...mapGetters(['isStoreBusy', 'isCreateOutfitShow', 'outfitCreateDefaultDate', 'isAddFriendShow', 'hasToken', 'isNavHide', 'isBottomNavHide']),
    busy() {
      return this.reading || this.isStoreBusy;
    },
    showNav() {
      return this.inited && !this.$route.meta.hideTopNav && !this.isNavHide;
    },
    showBottomNav() {
      return this.inited && !this.$route.meta.hideBottomNav && !this.isBottomNavHide;
    },
  },
  watch: {
    errMsgs(errMsgs) {
      if (errMsgs.length > 0) {
        this.receiveErrorMsgs(errMsgs);
      }
    },
    showNav() {
      setTimeout(() => {
        this.adjustSize();
      }, 10);
    },
    showBottomNav() {
      setTimeout(() => {
        this.adjustSize();
      }, 10);
    },
    // async $route (to, from){
    //   if (this.inited) {
    //     console.log(`from: ${from.name}, to: ${to.name}`);
    //
    //     if (!this.$store.getters.hasToken && (this.$route.meta.publicPage === undefined || this.$route.meta.publicPage === false)) {
    //       if (this.$route.name !== 'Login') {
    //         this.forceRedirect('Login');
    //       }
    //     }
    //   }
    // },
  },
  methods: {
    ...mapActions(['refreshCloths', 'refreshClothCategories', 'refreshClothColors', 'updateCloth', 'setMainConfigShow', 'setCreateOutfitShow', 'setAddFriendShow', 'refreshUser']),
    async forceRedirect(name) {
      try {
        if (this.$route.name !== name) {
          await this.$router.replace({name});
        }
      } catch (e) {
        console.error(e);
      }
    },
    mainContentError(e) {
      if (e.toString() === 'need login.') {
        // this.forceRedirect('Login');
      }
    },
    showCreateClothPanel() {
      this.showCreateCloth = true;
    },
    showCreateOutfitPanel() {
      this.setCreateOutfitShow(true);
    },
    createClothCancel() {
      this.showCreateCloth = false;
    },
    createOutfitCancel() {
      this.setCreateOutfitShow(false);
    },
    configCancel() {
      this.setMainConfigShow(false);
    },
    receiveErrorMsgs(errMsgs) {
      for (const errMsg of errMsgs) {
        if (errMsg.toString() === 'need login.') {
          this.forceRedirect('Login');
        } else if (this.debug) {
          console.error(errMsg);
          this.$store.dispatch('showMsgModal', errMsg.toString());
        }
      }
      this.$store.commit('CLEARERRORMSG');
    },
    clothCreated(cloth) {
      this.updateCloth(cloth);
      this.showCreateCloth = false;
      this.$router.push({
        name: 'ClosetContentList',
        params: {
          filterCategory1: cloth.category1
        }
      });
    },
    outfitCreated() {
      this.setCreateOutfitShow(false);
      if (this.$route.name === 'OutfitCalendar') {
        this.$refs['main-component'].__refresh();
      }
    },
    closeAddFriendModal() {
      this.setAddFriendShow(false);
      if (this.$route.name === 'Share') {
        this.$refs['main-component'].__refresh();
      }
    },
    safariHacks() {
      this.adjustSize();
      window.addEventListener('resize', () => {
        this.adjustSize();
      });
    },
    adjustSize() {
      const windowsVH = window.innerHeight / 100;
      document.querySelector('.main-wrap').style.setProperty('--vh', windowsVH + 'px');
      if (this.showNav) {
        const headHeight = document.querySelector('.main-header').offsetHeight;
        document.querySelector('.main-app').style.setProperty('padding-top', headHeight + 'px');
      } else {
        document.querySelector('.main-app').style.setProperty('padding-top', '0px');
      }
      if (this.showBottomNav) {
        const bottomHeight = document.querySelector('.bottom-nav').offsetHeight;
        document.querySelector('.main-app').style.setProperty('padding-bottom', bottomHeight + 'px');
      } else {
        document.querySelector('.main-app').style.setProperty('padding-bottom', '0px');
      }

    },
    showAddByNumberModal() {
      this.showAddByNumber = true;
    },
  }
}
</script>


<style>
:root {
  --main-green: #7DC4B7;
  --main-yellow: #F2C31C;
  --main-dark: #4D4D4D;
  --main-orange: #E3861A;
  --required-orange: #E3861A;
  --text-dark: #4d4d4d;
  --text-light: #8d8d8d;
  --font-size-regular: 14px;
}

body {
  overflow: hidden;
}
</style>

<style scoped>
  .main-wrap {
    height: 100vh; /*給 Safari 以外的瀏覽器讀取*/
    height: calc(var(--vh, 1vh) * 100);
    height: 100dvh;
    overflow: hidden;
  }

  .main-app {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    /* font-size: 14px; */
  }

  .main-header {
    /* flex: 0 0 auto;
    position: relative; */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 30;
    background-color: #fff;
  }

  .main-content {
    flex: 1 1;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
  }

  .bottom-nav {
    /* flex: 0 0 auto;
    position: relative; */
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;

    z-index: 40;
    box-shadow: 0 0 0 1px #8D8D8D;
  }

  /* @media (min-width: 768px) {
    .main-app {
      width: 425px;
      margin-left: auto;
      margin-right: auto;
    }
  } */

</style>
