import { render, staticRenderFns } from "./StaticNav.vue?vue&type=template&id=46ea88d4&scoped=true"
import script from "./StaticNav.vue?vue&type=script&lang=js"
export * from "./StaticNav.vue?vue&type=script&lang=js"
import style0 from "./StaticNav.vue?vue&type=style&index=0&id=46ea88d4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46ea88d4",
  null
  
)

export default component.exports