<template>
  <transition name="fade">
    <FullScreenModal v-if="isPolicyShow" title="隱私權聲明" @back="closePolicy">
      <div class="container py-3">
        <p>
          非常歡迎您光臨長腳衣櫥線上服務（以下簡稱本網站），為了讓您能夠安心使用本網站的各項服務與資訊，特此向您說明本網站的隱私權聲明將如何保護照訪本網頁用戶之相關資訊。使用本網站的同時，即表示您同意長腳衣櫥依此隱私權聲明所述之方式使用您的資訊。<br />
          <br />
          如關於本隱私權聲明有任何問題，或有未盡事宜，請與我們聯繫。<br />
          <br />
          一、隱私權保護聲明的適用範圍<br />
          <br />
          隱私權保護聲明內容，包括本網站如何處理在您使用網站服務時收集到的個人識別資料。隱私權保護聲明不適用於本網站以外的相關連結網站，也不適用於非本網站所委託或參與管理的人員。<br />
          <br />
          二、個人資料的蒐集、處理及利用方式<br />
          <br />
          當您造訪本網站或使用本網站所提供之功能服務時，我們將視該服務功能性質，請您提供必要的個人資料，並在該特定目的範圍內處理及利用您的個人資料；非經您同意，本網站不會將個人資料用於其他用途。<br />
          <br />
          本網站在您使用服務信箱、問卷調查等互動性功能時，會保留您所提供的姓名、電子郵件地址、聯絡方式及使用時間等。<br />
          <br />
          於一般瀏覽時，伺服器會自行記錄相關行徑，包括您使用連線設備的IP位址、使用時間、使用的瀏覽器、瀏覽及點選資料記錄等，做為我們增進網站服務的參考依據，此記錄為內部應用，決不對外公佈。<br />
          <br />
          為提供精確的服務，我們會將收集的問卷調查內容進行統計與分析，分析結果之統計數據或說明文字呈現，除供內部研究外，我們會視需要公佈統計數據及說明文字，但不涉及特定個人之資料。<br />
          <br />
          您可以隨時向我們提出請求，以更正或刪除您的帳戶或本網站所蒐集的個人資料等隱私資訊。聯繫方式請見最下方聯繫管道。<br />
          <br />
          三、資料之保護<br />
          <br />
          本網站主機均設有防火牆、防毒系統等相關的各項資訊安全設備及必要的安全防護措施，加以保護網站及您的個人資料採用嚴格的保護措施，只由經過授權的人員才能接觸您的個人資料，相關處理人員皆簽有保密合約，如有違反保密義務者，將會受到相關的法律處分。<br />
          <br />
          如因業務需要有必要委託其他單位提供服務時，本網站亦會嚴格要求其遵守保密義務，並且採取必要檢查程序以確定其將確實遵守。<br />
          <br />
          四、網站對外的相關連結<br />
          <br />
          本網站的網頁提供其他網站的網路連結，您也可經由本網站所提供的連結，點選進入其他網站。但該連結網站不適用本網站的隱私權保護政策，您必須參考該連結網站中的隱私權保護政策。<br />
          <br />
          五、與第三人共用個人資料之政策<br />
          <br />
          為提供您完整的服務及更感興趣的資訊、市場調查等研究，本網站將提供您的個人資料予長腳衣櫥合作夥伴或委託處理相關資訊之第三方，並會嚴格要求其遵守保密義務，並且採取必要檢查程序以確定其將確實遵守。前述之揭露行為可能將您的個人資料傳輸至尚未制訂相關資料保護法令之國家或地區。<br />
          <br />
          本網站將依法提供您的個人資料予執法單位、主管機關或其他政府機構，或其他依法規命令有義務蒐集您個人資料之其他第三方。<br />
          <br />
          如果您通過本網站提供個人資料，即表示您同意上述之揭露行為。<br />
          <br />
          六、Cookie之使用<br />
          <br />
          為了提供您最佳的服務，本網站會在您的電腦中放置並取用我們的Cookie，若您不願接受Cookie的寫入，您可在您使用的瀏覽器功能項中設定隱私權等級為高，即可拒絕Cookie的寫入，但可能會導至網站某些功能無法正常執行 。<br />
          <br />
          七、隱私權保護政策之修正<br />
          <br />
          本網站隱私權保護政策將因應需求隨時進行修正，修正後的條款將刊登於網站上。<br />
          <br />
          八、聯繫管道<br />
          <br />
          對於本站之隱私權政策有任何疑問，或者想提出變更、移除個人資料之請求，請前往本站「聯絡我們」頁面提交表單。或者 Email 至：hi@walkingcloset.co<br />
        </p>

      </div>
    </FullScreenModal>
  </transition>
</template>

<script>
import {
  mapGetters,
  mapActions
} from 'vuex';
import FullScreenModal from '@/components/FullScreenModal.vue';

export default {
  name: 'PolicyModal',
  data() {
    return {
    };
  },
  components: {
    FullScreenModal,
  },
  props: {},
  beforeDestroy() {},
  async mounted() {},
  computed: {
    ...mapGetters(['isPolicyShow']),
  },
  watch: {

  },
  methods: {
    ...mapActions(['setPolicyShow']),
    closePolicy() {
      this.setPolicyShow(false);
    },
  }
}
</script>

<style scoped>
</style>
