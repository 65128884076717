<template>
  <transition name="fade">
    <WcModal v-if="isMsgModalShow">
      <div class="msgbox">
        <div class="content">
          <p v-html="msgModalContent"></p>
        </div>
        <div class="footer">
          <a href="#" @click.prevent="closeModal">確定</a>
        </div>
      </div>
    </WcModal>
  </transition>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import WcModal from '@/components/commons/WcModal.vue';

export default {
  name: 'msg-modal',
  components: {
    WcModal,
  },
  data() {
    return {
    }
  },
  props: {
  },
  computed: {
    ...mapState(['msgModalContent']),
    ...mapGetters(['isMsgModalShow']),
  },
  methods: {
    ...mapActions(['clearMsg']),
    closeModal() {
      this.clearMsg();
    }
  }

}
</script>

<style scoped>
  .msgbox {
    position: relative;
    border-radius: .5rem;
    overflow: hidden;
    background-color: #fff;
    min-width: 200px;
  }

  .content {
    padding: 1rem 1.5rem;
    font-size: 14px;
  }

  .content>p {
    margin: 0;
    text-align: center;
  }

  .footer {
    display: flex;
  }

  .footer>a {
    font-size: 14px;
    display: block;
    padding: 12px;
    border: solid 1px #fff;
    background-color: #EEEEEE;
    color: #000;
    flex: 1 1;
    text-align: center;
  }

  .footer>a:hover {
    text-decoration: none;
  }
</style>
