import moment from 'moment';

export default () => {
  return {
    outfitPhotos: [],

    brands: [],
    tags: [],

    date: moment().format('YYYY-MM-DD'),
    place: '',
    situation: '',
    weather: 0,
    temperatureSection: -1,
    desc: '',

    userSerial: -1,
  };
};
