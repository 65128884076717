export default {
  namespaced: true,
  state: {
    walletListData: [],
  },
  getters: {
  },
  mutations: {
    setWalletListData(state, data) {
      state.walletListData.splice(0);
      for (const c of data) {
        state.walletListData.push(c);
      }
    },
    updateWalletListData(state, data) {
      for (let i=0;i<state.walletListData.length;i++) {
        if (state.walletListData[i].serial === data.serial) {
          state.walletListData.splice(i, 1, data);
          return;
        }
      }
    }
  },
  actions: {
    async refreshWalletList({ commit, dispatch }) {
      const busyName = 'eventRefreshWalletList';
      await dispatch('appendComponentBusy', busyName, { root: true });
      try {
        const res = await dispatch('api/getWalletListPromise', null, { root: true });
        commit('setWalletListData', res);
      } catch (error) {
        await dispatch('appendErrorMsg', error.toString(), { root: true });
        await dispatch('showMsgModal', error.toString(), { root: true });
      } finally {
        await dispatch('clearComponentBusy', busyName, { root: true });
      }
    },
    updateWalletData({ commit }, wallet) {
      commit('updateWalletListData', wallet);
    },
  }

}
