<template>
  <div>
    <div class="">
      <div class="pic" @click="openInfoModal">
        <OutfitBlock v-if="!reading" :outfit="outfit" @loaded="imgLoaded"></OutfitBlock>
        <div class="fake" v-if="!ready">
        </div>
      </div>

      <router-link :to="{ name: 'UserPublic', params: {serial: userSerial}}" class="user">
        <div class="row no-gutters align-items-center">
          <div class="col-auto">
            <div class="user-pic">
              <img :src="outfit.user.picUrl" v-if="ready"/>
            </div>
          </div>

          <div class="col px-2">
            <p v-if="ready" class="user-name">{{outfit.user.name}}</p>
          </div>
        </div>
      </router-link>
    </div>

    <transition name="fade">
      <ViewOutfit v-if="isInfoModalShow && ready" :id="outfit.serial" @back="closeInfoModal"></ViewOutfit>
    </transition>
  </div>
</template>

<script>
import ViewOutfit from '@/views/closet/ViewOutfit.vue';
import OutfitBlock from '@/components/outfit/OutfitBlock.vue';
import { mapActions } from 'vuex';

export default {
  name: 'OutfitCard',
  data() {
    return {
      readingBusyName: 'OutfitCardAREADING',
      reading: true,
      ready: false,
      outfit: null,
      isInfoModalShow: false,

    };
  },
  props: {
    serial: {
      type: Number,
      required: true,
    },
  },
  components: {
    OutfitBlock,
    ViewOutfit,
  },
  computed: {
    userSerial() {
      if (this.outfit === null) {
        return -1;
      }
      return this.outfit.userSerial;
    }
	},
  watch: {
  },
  async mounted() {
    this.reading = true;
    await this.__refresh();
    this.reading = false;
    this.ready = true;
	},
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
    async __refresh() {
      this.appendComponentBusy(this.readingBusyName);
      try {
        const detail = await this.$store.dispatch('api/getHomeOutfitDetailPromise', this.serial);
        this.outfit = Object.assign({}, this.outfit, detail);
      } catch(e) {
        console.error(e);
        this.$emit('error', e);
      } finally {
        this.clearComponentBusy(this.readingBusyName);
      }
    },
    imgLoaded() {
      this.ready = true;
    },
    openInfoModal() {
      this.isInfoModalShow = true;
    },
    closeInfoModal() {
      this.isInfoModalShow = false;
    },
  }
}
</script>

<style scoped>
  .pic {
    position: relative;
  }

  .fake {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
  }

  .fake::after {
    content: '';
    display: block;
    position: relative;
    width: 100%;
    padding-top: 100%;
    background-color: #C4C4C4;
    border-radius: 0.5rem;
    animation-name: fake-img;
    animation-duration: 1.5s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in;
  }

  @keyframes fake-img {
    0% {
      background-color: #C4C4C4;
    }

    100% {
      background-color: #DDD;
    }
  }

  .user {
    display: block;
    margin-top: .2rem;
  }

  .user-pic {
    display: block;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #C4C4C4;
  }

  .user-pic>img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .user-name {
    margin: 0;
    font-size: 12px;
    color: var(--text-dark);
  }
</style>
