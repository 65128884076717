<template>
  <FullScreenModal class="outfit-manager" :title="modalTitle" :btnType="outfitType === 'edit' ? 'close' : ''" @back="backClicked">
    <template v-if="outfitType === 'view'" #right-top>
      <div class="more-option">
        <b-dropdown variant="link" toggle-class="more-option-btn" right no-caret>
          <template #button-content>
            <Icon name="More" size="24" />
          </template>
          <b-dropdown-item-button @click="toggleToEdit">編輯</b-dropdown-item-button>
          <b-dropdown-item-button @click="deleteClicked">刪除</b-dropdown-item-button>
        </b-dropdown>
      </div>
    </template>
    <div class="outfit-editor" v-if="outfitType === 'edit'">
      <div class="images-group">
        <CropImageButton ref="CropImage" @uploaded="outfitImgUploaded" v-if="!outfitData.outfitPhotos[0] || outfitData.outfitPhotos[0].type !== 'photo'">
          <div class="collection-image upload-btn d-flex flex-column justify-content-center align-items-center">
            <Icon name="Camera" size="24" />
            <div class="fs-16px">上傳封面照</div>
            <div class="fs-16px">記錄這一天</div>
          </div>
        </CropImageButton>
        <CropImageButton @uploaded="outfitImgUploaded" v-if="outfitData && outfitData.outfitPhotos[0] && outfitData.outfitPhotos[0].type === 'photo'">
          <div class="collection-image">
            <img :src="outfitPhoto" alt="clothing image">
            <div class="delete-btn" @click.stop="removeCloth(-1)">
              <Icon name="close" size="24" />
            </div>
          </div>
        </CropImageButton>
        <div class="collection-image" v-for="(item, index) in outfitCloths" :key="index">
          <img :src="item.imgs[0]" alt="clothing image">
          <div class="delete-btn" @click.stop="removeCloth(index)">
            <Icon name="close" size="24" />
          </div>
        </div>
        <div class="collection-image upload-btn d-flex flex-column justify-content-center align-items-center" v-if="isNoClothType" @click="openCollectFromCloset">
          <Icon name="add" size="24" />
          <div class="fs-16px">選擇單品</div>
        </div>
      </div>
      <button class="collect-btn btn-outline-primary btn-sm" v-if="!isNoClothType" @click="openCollectFromCloset">編輯穿搭單品</button>
      <CustomInputGroup labelText="日期" inputType="date" v-model="outfitData.date" />
      <div class="select-row">
        <CustomSelectGroup class="col" labelText="天氣狀況" :options="weatherOpt"  v-model="outfitData.weather" />
        <CustomSelectGroup class="col" labelText="氣溫" :options="temperatureOpt"  v-model="outfitData.temperatureSection" />
      </div>
      <CustomPlaceFinder :placeData="placeData" @updateSearchPlace="handleSearchPlace" />
      <CustomTagEditor :editorData="outfitData.brands" editorType="brand" @updateTagData="handleTagData" />
      <CustomTagEditor :editorData="outfitData.tags" editorType="tag" @updateTagData="handleTagData" />

      <hr>
      <div class="info-group situation">
        <div class="title fs-14px fw-bold">場合</div>
        <div class="option d-flex">
          <label :class="{ picked: outfitData.situation === '上班' }">
            <span>上班</span>
            <input type="radio" value="上班" v-model="outfitData.situation">
          </label>
          <label :class="{ picked: outfitData.situation === '約會' }">
            <span>約會</span>
            <input type="radio" value="約會" v-model="outfitData.situation">
          </label>
          <label :class="{ picked: outfitData.situation === '運動' }">
            <span>運動</span>
            <input type="radio" value="運動" v-model="outfitData.situation">
          </label>
          <label :class="{ picked: outfitData.situation === '上課' }">
            <span>上課</span>
            <input type="radio" value="上課" v-model="outfitData.situation">
          </label>
          <label :class="{ picked: outfitData.situation === '逛街' }">
            <span>逛街</span>
            <input type="radio" value="逛街" v-model="outfitData.situation">
          </label>
          <label :class="{ picked: outfitData.situation === '聚會' }">
            <span>聚會</span>
            <input type="radio" value="聚會" v-model="outfitData.situation">
          </label>
          <label :class="{ picked: outfitData.situation === '婚禮' }">
            <span>婚禮</span>
            <input type="radio" value="婚禮" v-model="outfitData.situation">
          </label>
          <label :class="{ picked: outfitData.situation === '其他' }">
            <span>其他</span>
            <input type="radio" value="其他" v-model="outfitData.situation">
          </label>
        </div>
      </div>
      <hr>
      <div class="info-group emoji">
        <div class="title fs-14px fw-bold">穿搭感受</div>
        <div class="option d-flex">
          <label :class="{ picked: emoji === 1 }">
            <Icon name="emoji-happy" size="30" />
            <input type="radio" :value="1" v-model="emoji">
          </label>
          <label :class="{ picked: emoji === 0 }">
            <Icon name="emoji-fine" size="30" />
            <input type="radio" :value="0" v-model="emoji">
          </label>
          <label :class="{ picked: emoji === -1 }">
            <Icon name="emoji-sad" size="30" />
            <input type="radio" :value="-1" v-model="emoji">
          </label>
        </div>
      </div>
      <hr>
      <div class="info-row align-items-center">
        <div class="info-col-title">
          <label class="fs-16px fw-bold">公開分享</label>
          <p class="public-desc fs-14px">
            所有追蹤的好友可以看到本次穿搭紀錄
          </p>
        </div>

        <div class="col-auto px-0">
          <WcSwitch v-model="outfitData.isPublic" />
        </div>
      </div>
      <hr>
      <textarea placeholder="備註" rows="3" v-model="outfitData.desc">
      </textarea>
    </div>

    <transition name="fade">
      <OutfitViewer v-if="outfitId !== -1 && outfitType === 'view'" />
    </transition>

    <CollectFromCloset v-if="showCollectFromCloset" :collectLimit="12" :collectedList="[]" v-model="showCollectFromCloset" @uploadCollectedItem="uploadCollectedItem" />

    <transition name="fade">
      <QuicklyCreateCloth v-if="showQuicklyCreateModal" @back="closeQuicklyCreateModal" @created="appendClothsAndCloseQuicklyCreateModal" :src-image="outfitPhoto" :limit="12 - outfitCloths.length"></QuicklyCreateCloth>
    </transition>

    <transition name="fade">
      <WcModal v-if="showConfirmQuicklyCreateModal" @backClick="showConfirmQuicklyCreateModal = false">
        <div class="quickly-create-confirm-modal">
          <div class="close-btn" @click="showConfirmQuicklyCreateModal = false">
            <Icon name="close" size="24" />
          </div>
          <h3 class="header fs-h3">
            從穿搭照中新增單品
          </h3>
          <div class="text-center">
            是否有尚未新增在衣櫥中的單品？<br>
            建議您選擇快速新增單品，輕鬆將穿搭照中的單品加入衣櫥。
          </div>
          <div class="option">
            <b-button class="share-btn" variant="primary" @click="openQuicklyCreateModal">
              <span>快速新增單品</span>
            </b-button>
            <b-button class="share-btn" variant="outline-primary" @click="showConfirmQuicklyCreateModal = false">
              <span>跳過</span>
            </b-button>
          </div>
        </div>
      </WcModal>
    </transition>

    <transition name="fade">
      <WcCommonModal title="確定離開" v-if="showConfirmModal" @cancel="showConfirmModal = false" @ok="backConfirm" hide-header>
        <div class="text-center">
          未儲存資料<br/>
          要離開嗎？
        </div>
      </WcCommonModal>
    </transition>

    <transition name="fade">
      <WcCommonModal title="確定刪除" v-if="showConfirmDeleteModal" @cancel="showConfirmDeleteModal = false" @ok="deleteConfirm" hide-header>
        <div class="text-center">
          確定刪除？
        </div>
      </WcCommonModal>
    </transition>

    <CustomModalFooter v-if="outfitType === 'edit'">
      <b-button class="btn-primary" block :disabled="!hasPhoto" @click="saveBtnClicked">儲存</b-button>
    </CustomModalFooter>
  </FullScreenModal>
</template>

<script>
import moment from "moment/moment";
import outfitDefault from "@/dataModel/outfit";
import { mapActions, mapState, mapGetters } from "vuex";
import FullScreenModal from '@/components/FullScreenModal.vue';
import CustomModalFooter from "@/components/CustomModalFooter.vue";
import WcCommonModal from "@/components/commons/WcCommonModal.vue";
import CropImageButton from "@/components/cropImage/CropImageButton.vue";
import CustomPlaceFinder from "@/components/commons/CustomPlaceFinder.vue";
import CustomTagEditor from "@/components/commons/CustomTagEditor.vue";
import CustomSelectGroup from "@/components/commons/CustomSelectGroup.vue";
import CustomInputGroup from "@/components/commons/CustomInputGroup.vue";
import WcSwitch from "@/components/commons/WcSwitch.vue";
import CollectFromCloset from "@/components/CollectFromCloset.vue";
import OutfitViewer from "@/views/closet/OutfitViewer.vue";
import QuicklyCreateCloth from "@/views/closet/QuicklyCreateCloth.vue";
import WcModal from "@/components/commons/WcModal.vue";

export default {
  name: 'OutfitManager',
  data() {
    return {
      initBusyName: 'OutfitManagerInitReading',
      uploadBusyName: 'OutfitManagerUploadingImage',
      saveBusyName: 'OutfitManagerSaving',
      deleteBusyName: 'OutfitManagerDeleting',

      showCollectFromCloset: false,
      showConfirmModal: false,
      showConfirmDeleteModal: false,
      showConfirmQuicklyCreateModal: false,
      showQuicklyCreateModal: false,

      outfitData: {},
      collectFromClosetList: [],
    };
  },
  components: {
    QuicklyCreateCloth,
    CollectFromCloset,
    WcSwitch, CustomInputGroup, CustomSelectGroup, CustomTagEditor, CustomPlaceFinder,
    CropImageButton,
    WcCommonModal,
    FullScreenModal,
    CustomModalFooter,
    OutfitViewer,
    WcModal,
  },
  props: {
  },
  computed: {
    ...mapState(['cloths']),
    ...mapGetters(['outfitCreateDefaultDate']),
    ...mapGetters('outfit', ['outfitId', 'outfitType', 'outfitEditStep', 'outfitImgUrl']),

    isCreate() {
      return this.outfitType === 'edit' && this.outfitId === -1;
    },
    isEdit() {
      return this.outfitType === 'edit' && this.outfitId !== -1;
    },
    isView() {
      return this.outfitType === 'view';
    },
    isNoClothType() {
      return !this.outfitData.outfitPhotos.some(photo => photo.type === 'cloth');
    },
    modalTitle() {
      return this.isCreate ? '新增記錄穿搭'
          : this.isEdit ? '編輯記錄穿搭'
              : this.isView ? '穿搭紀錄'
                  : '';
    },
    hasPhoto() {
      return this.outfitData.outfitPhotos.length > 0;
    },
    outfitPhoto() {
      if (this.hasPhoto) {
        return this.outfitData.outfitPhotos[0].imgUrl;
      }
      return '';
    },
    outfitCloths() {
      const res = [];
      for (const op of this.outfitData.outfitPhotos) {
        if (op.type !== 'cloth') {
          continue;
        }
        const cloth = this.cloths.filter((c) => {
          return c.serial === op.clothSerial;
        });
        res.push(cloth[0]);
      }
      return res;
    },
    weatherOpt() {
      const weatherIds = [0, 5, 1, 2, 3, 4, 6, 7, 8, 9];
      const weatherStrs = ['晴天', '明月', '多雲', '晴時多雲', '雨', '雷雨', '強風', '寒流', '雪', '濃霧'];
      const opt = [];
      for (let i = 0 ; i < weatherIds.length ; i ++ ) {
        opt.push({
          value: weatherIds[i],
          text: weatherStrs[i],
        });
      }
      return opt;
    },
    temperatureOpt() {
      const opt = [{
        value: -1,
        text: '請選擇',
      }];
      const secs = [
        '-10 ~ 0 ℃',
        '1 ~ 10 ℃',
        '11 ~ 15 ℃',
        '16 ~ 20 ℃',
        '21 ~ 25 ℃',
        '26 ~ 30 ℃',
        '31 ~ 35 ℃',
        '36 ~ 40 ℃',
      ];
      for (let i = 0 ; i < secs.length ; i ++ ) {
        opt.push({
          value: i,
          text: secs[i],
        });
      }
      return opt;
    },
    emoji: {
      get() {
        return this.outfitData.feelingUnknown ? null : this.outfitData.feeling;
      },
      set(value) {
        this.outfitData.feeling = value;
        this.outfitData.feelingUnknown = false; // 當選擇 emoji 時，設定 feelingUnknown 為 false
      }
    },
    placeData() {
      return {
        place: this.outfitData.place,
        placeId: this.outfitData.placeId,
        placeLat: this.outfitData.placeLat,
        placeLong: this.outfitData.placeLong,
        placeCountry: this.outfitData.placeCountry,
        areaLevel1: this.outfitData.areaLevel1,
        areaLevel2: this.outfitData.areaLevel2,
        areaLevel3: this.outfitData.areaLevel3,
      }
    },
  },
  watch: {
  },
  created() {
    this.outfitData = Object.assign({}, this.outfitData, outfitDefault());
    if (this.isCreate && this.outfitCreateDefaultDate.length > 0) {
      this.outfitData.date = moment(this.outfitCreateDefaultDate).format('YYYY-MM-DD');
    }
  },
  async mounted() {
    await this.refresh();
    // console.log(this.outfitImgUrl);
    if (this.outfitImgUrl !== undefined && this.outfitImgUrl.length > 0) {
      this.outfitImgUploaded({url: this.outfitImgUrl});
    }
    // if(this.outfitType === 'edit' && !this.showCollectFromCloset) {
    //   await this.delay(1000);
    //   this.$refs['CropImage'].uploadBtnClicked();
    // }
  },
  beforeDestroy() {
    this.clearComponentBusy(this.initBusyName);
    this.clearComponentBusy(this.uploadBusyName);
    this.clearComponentBusy(this.saveBusyName);
    this.clearOutfitData();
  },
  methods: {
    ...mapActions(['appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'updateCloth', 'updateOutfit']),
    ...mapActions('outfit', ['setOutfitData', 'setOutfitId', 'setOutfitType', 'setOutfitEditStep', 'clearOutfitData']),

    async refresh() {
      if(this.outfitType === 'edit') {
        await this.refreshEditData();
      }
    },
    async refreshEditData() {
      this.appendComponentBusy(this.initBusyName);
      try {
        if (this.outfitId !== -1) {
          const outfit = await this.$store.dispatch('api/readOutfitInfoPromise', this.outfitId);
          outfit.outfitPhotos = outfit.outfitPhotos.map((o) => {
            if (o.type === 'photo') {
              return o;
            } else if (o.type === 'cloth') {
              return {
                type: o.type,
                clothSerial: o.clothSerial,
              }
            }
          });
          this.outfitData = Object.assign({}, this.outfitData, outfit);
        } else {
          this.showCollectFromCloset = this.outfitEditStep === 1;
        }
      } catch(e) {
        this.emitError(e);
      } finally {
        this.clearComponentBusy(this.initBusyName);
      }
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    backClicked() {
      if(this.outfitType === 'view') {
        this.$emit('back');
      } else {
        this.showConfirmModal = true;
      }
    },
    backConfirm() {
      this.$emit('back');
    },
    outfitImgUploaded(data) {
      const {url} = data;
      if (this.hasPhoto && this.outfitData.outfitPhotos[0].type === 'photo') {
        this.outfitData.outfitPhotos[0].imgUrl = url;
      } else {
        this.outfitData.outfitPhotos.unshift({
          type: 'photo',
          imgUrl: url,
          tags: [],
        });
      }
      this.openConfirmQuicklyCreateModal();
    },
    uploadCollectedItem(clothSerials) {
      clothSerials.map((serial) => {
        const found = this.outfitData.outfitPhotos.find((p) => {
          return p.type === 'cloth' && p.clothSerial === serial;
        });
        if (found === undefined || found === null) {
          this.outfitData.outfitPhotos.push({
            type: 'cloth',
            clothSerial: serial,
          });
        }
      });
      // console.log(clothSerials);
      this.collectFromClosetList = clothSerials;
    },
    removeCloth(index) {
      let currentIndex = this.outfitData?.outfitPhotos[0]?.type === 'photo' ? index + 1 : index

      // 確保當前項目存在
      const currentItem = this.outfitData.outfitPhotos[currentIndex];
      if (!currentItem) return;

      // 檢查 type 是否為 'cloth'
      if (currentItem.type === 'cloth') {
        const serialToRemove = currentItem.clothSerial;

        // 從 collectFromClosetList 中移除匹配的 serial
        const serialIndex = this.collectFromClosetList.indexOf(serialToRemove);
        if (serialIndex !== -1) {
          this.collectFromClosetList.splice(serialIndex, 1);
        }
      }

      // 從 outfitPhotos 中移除當前項目
      this.outfitData.outfitPhotos.splice(currentIndex, 1);
    },
    openCollectFromCloset() {
      this.showCollectFromCloset = true;
    },
    handleSearchPlace(newPlaceData) {
      console.log('收到的地點資料:', newPlaceData);
      this.outfitData = {
        ...this.outfitData,  // 保持原有資料
        ...newPlaceData     // 更新地點相關欄位
      };
    },
    handleTagData(newTagData) {
      switch (newTagData.type) {
        case 'brand':
          this.outfitData.brands = JSON.parse(JSON.stringify(newTagData.tagData));
          break;
        case 'tag':
        default:
          this.outfitData.tags = JSON.parse(JSON.stringify(newTagData.tagData));
      }
    },
    checkValidate(outfit) {
      // if (outfit.outfitPhotos.length === 0) {
      //   return '請上傳穿搭照片';
      // } else if (outfit.outfitPhotos.length === 1) {
      //   return '請至少選擇一件搭配中的衣物單品';
      // }
      if (outfit.outfitPhotos.length === 0 || !outfit.outfitPhotos.some(item => item.type === 'cloth')) {
        return '請至少選擇一件搭配中的衣物單品';
      } else if (!outfit.situation) {
        return '請選擇穿搭場合';
      }
      return '';
    },
    async saveBtnClicked() {
      const insertData = this.outfitData;
      const errMsg = this.checkValidate(insertData);
      if (errMsg.length > 0) {
        this.appendErrorMsg(errMsg);
      } else {
        this.appendComponentBusy(this.saveBusyName);
        try {
          if(this.outfitId === -1) {
            const res = await this.$store.dispatch('api/postCreateOutfitPromise', insertData);
            await this.$store.dispatch('updateOutfit', res);
            this.$emit('updated');
            this.setOutfitId(res.serial);
            this.setOutfitType('view');
          } else {
            const res = await this.$store.dispatch('api/updateOutfitPromise', {
              id: this.outfitId,
              outfit: insertData,
            });
            await this.updateOutfit(res);
            this.$emit('updated');
            this.setOutfitType('view');
          }
        } catch(e) {
          this.appendErrorMsg(e);
        } finally {
          this.clearComponentBusy(this.saveBusyName);
        }
      }
    },
    async toggleToEdit() {
      this.setOutfitEditStep(-1);
      await this.refreshEditData();
      this.setOutfitType('edit');
    },
    deleteClicked() {
      this.showConfirmDeleteModal = true;
    },
    async deleteConfirm() {
      this.appendComponentBusy(this.deleteBusyName);
      try {
        await this.$store.dispatch('api/deleteOutfitPromise', this.outfitId);
        this.$emit('deleted', this.outfit);
      } catch(err) {
        this.appendErrorMsg(err.toString());
      } finally {
        this.clearComponentBusy(this.deleteBusyName);
      }
      this.$emit('back');
    },
    openConfirmQuicklyCreateModal() {
      this.showConfirmQuicklyCreateModal = true;
    },
    openQuicklyCreateModal() {
      this.showConfirmQuicklyCreateModal = false;
      this.showQuicklyCreateModal = true;
    },
    async appendClothsAndCloseQuicklyCreateModal(cloths) {
      const serials = [];
      for (const cloth of cloths) {
        await this.updateCloth(cloth);
        serials.push(cloth.serial);
      }
      this.uploadCollectedItem(serials);
      this.closeQuicklyCreateModal();
    },
    closeQuicklyCreateModal() {
      this.showQuicklyCreateModal = false;
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
@font-face {
  font-family: Wweather;
  src: url('~@/assets/font/Writes_weather.ttf');
}
.weather-icons {
  font-family: Wweather;
  margin-right: .5rem;
}
.outfit-manager {
  .outfit-editor {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem 1rem 6.5rem;
    .images-group {
      position: relative;
      display: flex;
      align-items: center;
      gap: .5rem;
      width: calc(100% + 1rem);
      overflow-x: auto;
      padding-right: 1rem;
    }
    .collection-image {
      flex: 0 0 auto;
      background: $color-image-background;
      width: 109px;
      height: 109px;
      border-radius: .5rem;
      overflow: hidden;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      a {
        position: absolute;
        top: 0;
        right: 0;
      }
      .delete-btn {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        color: $typography-primary-default;
      }
    }
    .upload-btn {
      color: $typography-secondary-default;
      background: transparent;
      border: 1px dashed $color-border;
      .svg-icon {
        margin-bottom: .5rem;
      }
    }
    .collect-btn {
      border-color: $color-border;
      border-radius: .5rem;
      &:not(:disabled):hover, &:focus, &:not(:disabled):not(.disabled):active {
        border-color: $color-border;
      }
      &:disabled, &.hover:disabled {
        border-color: $color-border;
      }
    }
    .select-row {
      display: flex;
      flex-wrap: nowrap;
      gap: .5rem;
      .col {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .info-row {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      .info-col-title {
        flex: 1 1;
        padding-right: 1rem;
        label {
          margin-bottom: .5rem;
        }
        .public-desc {
          margin-bottom: 0;
        }
      }
      .col-auto {
        display: flex;
      }
    }
    hr {
      width: 100%;
      margin: 0;
    }
    .info-group {
      padding-top: .5rem;
      padding-bottom: .5rem;
      &.situation {
        .option {
          gap: .5rem;
          label {
            color: $typography-primary-default;
            border: 1px solid $color-accent;
            border-radius: 100px;
            padding: 6px 16px;
            &.picked {
              color: $color-white;
              background: $typography-primary-default;
              border-color: $typography-primary-default;
            }
          }
        }
      }
      &.emoji {
        .option {
          gap: 1rem;
          label {
            display: flex;
            justify-content: center;
            align-items: center;
            color: $typography-secondary-default;
            width: 48px;
            height: 48px;
            border: 1px solid $color-accent;
            border-radius: 100px;
            &.picked {
              color: $color-white;
              background: $typography-primary-default;
              border-color: $typography-primary-default;
            }
          }
        }
      }
      .title {
        margin-bottom: .5rem;
      }
      .option {
        flex-wrap: wrap;
        label {
          flex-shrink: 0;
          margin-bottom: 0;
          input {
            display: none;
          }
        }
      }
    }
    textarea {
      width: 100%;
      border-radius: .5rem;
      border: 1px solid $color-border;
      resize: none;
      padding: 1rem;
    }
  }
  .quickly-create-confirm-modal {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    color: $typography-primary-default;
    background: $color-white;
    width: 20rem;
    border-radius: .75rem;
    padding: 1.5rem;
    .close-btn {
      cursor: pointer;
      position: absolute;
      top: 1rem;
      right: 1rem;
      padding: .5rem;
    }
    h3.header.fs-h3 {
      text-align: center;
      margin: 0;
    }
    .option {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.outfit-manager {
  .more-option {
    .more-option-btn {
      padding: .75rem .5rem;
    }
    .dropdown-menu {
      min-width: 5rem;
    }
    .dropdown-item {
      color: $typography-primary-default;
      font-weight: 400;
      text-align: center;
    }
  }
  .outfit-editor {
    .custom-input-group, .custom-select-group {
      margin-bottom: 0;
    }
    .wc-switch {
      width: 54px;
      height: 30px;
      &.on {
        background-color: $typography-primary-default;
        .ball {
          left: 27px;
        }
      }
      .ball {
        top: 3px;
        left: 3px;
        width: 24px;
        height: 24px;
      }
    }
  }
}
</style>
