<template>
  <div class="outfit-block">
    <div class="outfit-img">
      <img :src="outfitImg" @load="imgLoaded"/>
    </div>
  </div>
</template>


<script>
// @ is an alias to /src

export default {
  name: 'OutfitBlock',
  data() {
    return {
    };
  },
  props: {
    outfit: {
      type: Object,
      required: true,
    },
  },
  components: {
  },
  computed: {
    outfitImg() {
      return this.outfit.outfitPhotos[0].imgUrl;
    },
	},
  watch: {
  },
  mounted() {
	},
  methods: {
    imgLoaded() {
      this.$emit('loaded');
    }
  }
}
</script>

<style scoped>
  .outfit-block {
    position: relative;
    width: 100%;
  }

  .outfit-img {
    padding-top: 100%;
    background-color: #C4C4C4;
    border-radius: .5rem;
    overflow: hidden;
    position: relative;
  }

  .outfit-img>img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
</style>
