<template>
  <div class="top-nav" :class="{'nav-green': isGreen}">
    <div class="logo">
      <img :src="require('@/assets/logo-h.png')" v-if="!isInner"/>
      <a href="#" @click.prevent="backClicked" v-if="isInner">
        <img :src="require(isGreen?'@/assets/icons/back-white.png':'@/assets/icons/back.png')" />
      </a>
    </div>

    <ul class="tools">
      <li v-show="showChat">
        <a href="#" class="top-nav-btn">
          <img :src="require('@/assets/nav_icons/tool_chat.png')" />
        </a>
      </li>

      <li v-show="showAddFriend">
        <a href="#" class="top-nav-btn" @click.prevent="addFriendClicked">
          <img :src="require('@/assets/nav_icons/tool_add_friend.png')" />
        </a>
      </li>

      <li v-show="showNotify">
        <a href="#" class="top-nav-btn">
          <img :src="require('@/assets/nav_icons/tool_notify.png')" />
        </a>
      </li>

      <li v-show="showCalendar">
        <router-link :to="{ name: 'OutfitCalendar'}" class="top-nav-btn">
          <img :src="require('@/assets/nav_icons/tool_calendar.png')" />
        </router-link>
      </li>

      <li v-show="showSetting">
        <a href="#" class="top-nav-btn" @click.prevent="settingClicked">
          <img :src="require('@/assets/nav_icons/gear_white.png')" />
        </a>
      </li>

      <li v-show="showProfile">
        <router-link :to="{ name: 'UserPublic', params: {serial: user.serial}}" class="top-nav-btn top-nav-profile-btn">
          <img :src="user.picUrl" />
        </router-link>
      </li>
    </ul>

  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
  name: 'TopNav',
  data() {
    return {
    };
  },
  props: {
  },
  components: {
  },
  mounted() {

  },
  watch: {
  },
  computed: {
    ...mapState(['user']),
    showChat() {
      return false;
    },
    showAddFriend() {
      return (this.$route.meta.navCategory === 'share' || this.$route.meta.navCategory === 'home') && this.$route.name !== 'UserPublic';
    },
    showNotify() {
      return false;
    },
    showCalendar() {
      return this.$route.meta.navCategory === 'closet';
    },
    showProfile() {
      return this.$route.name !== 'UserPublic';
    },
    showSetting() {
      return this.$route.name === 'UserPublic' && parseInt(this.$route.params.serial) === this.$store.getters.loginUserSerial;
    },
    isInner() {
      if (this.$route.meta.inner) {
        return true;
      }
      return false;
    },
    isGreen() {
      if (this.$route.meta.special && this.$route.meta.special === 'green') {
        return true;
      }
      return false;
    },
    hasHistory () {
      return window.history.length > 1;
    },
  },
  methods: {
    ... mapActions(['setMainConfigShow', 'setAddFriendShow']),
    settingClicked() {
      this.setMainConfigShow(true);
    },
    backClicked() {
      // this.$router.go(-1);
      // window.history.back();
      if (this.$route.meta.backName) {
        this.$router.push({name: this.$route.meta.backName});
      } else {
        if (this.hasHistory) {
          this.$router.go(-1);
        } else {
          this.$router.push({name: 'Closet'});
        }
      }
      // history.back();
    },
    addFriendClicked() {
      this.setAddFriendShow(true);
    },
  }
}
</script>

<style scoped>
  .top-nav {
    padding: 1rem;
    display: flex;
    flex-wrap: nowrap;
    min-height: 60px;
    align-items: center;
  }

  .logo {
    position: relative;
    flex: 0 0 auto;
  }

  .logo>img {
    height: 23px;
  }

  .logo>a {
    display: block;
    position: relative;
    font-size: 12px;
    line-height: 1em;
  }

  .logo>a>img {
    height: 1rem;
  }

  ul.tools {
    flex: 1 1;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: nowrap;
    list-style: none;
  }

  ul.tools li {
    padding: 0 .25rem;
    margin: 0;
    flex: 0 0 auto;
  }

  .top-nav-btn {

  }

  .top-nav-btn>img {
    width: 28px;
  }

  .top-nav-btn.top-nav-profile-btn {
    display: block;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    overflow: hidden;
  }

  .top-nav-btn.top-nav-profile-btn>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .top-nav.nav-green {
    background-color: var(--main-green);
  }
</style>
