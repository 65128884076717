<template>
  <div class="brand-size-tag" :class="{'hide-delete': hideDelete}">
    <div class="">
      {{brandSize.brandName}} {{brandSize.sizeType}} | {{brandSize.size}}
    </div>
    <a class="delete-btn" href="#" @click.prevent="showConfirmModal" v-if="!hideDelete">
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 3L3 9" stroke="#F9F8F8" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M3 3L9 9" stroke="#F9F8F8" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </a>

    <transition name="fade">
      <WcCommonModal title="確定刪除" v-if="isConfirmModalShow" @cancel="isConfirmModalShow = false" @ok="deleteBrandSizeConfirmed" hide-header>
        <div class="text-center" style="font-size: 14px;">
          確定刪除這個品牌尺寸資料?
        </div>
      </WcCommonModal>
    </transition>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import WcCommonModal from '@/components/commons/WcCommonModal.vue';

export default {
  name: 'brand-size-tag',
  data() {
    return {
      isConfirmModalShow: false,
      savingBusyName: 'BRANDSIZETAGDELETE',
    }
  },
  components: {
    WcCommonModal,
  },
  beforeDestroy() {
    this.clearComponentBusy(this.savingBusyName);
  },
  created() {
  },
  computed: {
  },
  mounted() {
  },
  props: {
    brandSize: {
      type: Object,
      required: true,
    },
    hideDelete: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
  },
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
    showConfirmModal() {
      this.isConfirmModalShow = true;
    },
    async deleteBrandSizeConfirmed() {
      this.appendComponentBusy(this.savingBusyName);
      try {
        await this.$store.dispatch('api/deleteBrandSizePromise', this.brandSize.serial);
        this.$emit('deleted', this.brandSize);
        this.showMsgModal('已刪除!');
        this.isConfirmModalShow = false;
      } catch(err) {
        this.appendErrorMsg(err.toString());
      } finally {
        this.clearComponentBusy(this.savingBusyName);
      }
    }
  }

}
</script>

<style scoped>
  .brand-size-tag {
    display: inline-block;
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    border-radius: 999px;
    padding: 0px 30px 0px 12px;
    position: relative;
    margin-right: .3rem;
    margin-bottom: .5rem;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }

  .brand-size-tag.hide-delete {
    padding: 5px 12px 5px 12px;
  }

  .brand-size-tag .delete-btn {
    position: absolute;
    top: 0;
    right: 0;
    border: solid 2px #fff;
    background: #D9D9D9;
    border-radius: 50%;
    height: 24px;
    width: 24px;
  }

  .brand-size-tag .delete-btn>svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
