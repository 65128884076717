<template>
  <div class="custom-select-group">
    <label>
      <span class="label" :class="{'empty-input': inputValue === ''}" v-text="labelText"></span>
      <Icon class="select-icon" name="chevron-down" size="24" />
      <b-form-select
          v-model="inputValue"
          :options="options"
          @change="emitChange"
      >
      </b-form-select>
    </label>
    <div class="form-desc" v-if="descText !== ''" v-text="descText"></div>
    <div class="form-desc warning" v-if="errorText !== ''" v-text="errorText"></div>
  </div>
</template>

<script>
export default {
  name: 'CustomSelectGroup',
  data() {
    return {
    }
  },
  components: {
  },
  props: {
    value: {
      type: [String, Number],
      required: true,
      default: '',
    },
    labelText: {
      type: String,
      default: 'label',
    },
    options: {
      type: Array,
      default: () => [],
    },
    descText: {
      type: String,
      default: '',
    },
    errorText: {
      type: String,
      default: '',
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    emitChange(value) {
      this.$emit('change', value);
    }
  }

}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.custom-select-group {
  margin-bottom: 1rem;
  label {
    position: relative;
    width: 100%;
    border-radius: .5rem;
    border: 1px solid $color-border;
    padding: 2.125rem 1rem .75rem;
    margin-bottom: 0;
    &:focus-within {
      .label.empty-input {
        top: .6rem;
        color: $typography-secondary-default;
        font-size: $font-small;
      }
    }
    .label {
      position: absolute;
      top: .6rem;
      color: $typography-secondary-default;
      font-size: $font-small;
      line-height: 1.5;
      transition: .2s;
      &.empty-input {
        top: calc(50% - 12px);
        left: 1rem;
        color: $typography-primary-default;
        font-size: $font-medium;
        padding: 0;
      }
    }
    .select-icon {
      position: absolute;
      top: 50%;
      right: 1.5rem;
      transform: translateY(-50%);
    }
  }
  .form-desc {
    color: $typography-secondary-default;
    font-size: $font-small;
    line-height: 1.5;
    padding-left: 1rem;
    margin-top: .125rem;
    &.warning {
      color: $typography-primary-error;
    }
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.custom-select-group {
  .custom-select {
    background: none;
    border: none;
    padding: 0;
    &:focus {
      box-shadow: none;
    }
  }
}
</style>