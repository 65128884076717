<template>
  <FullScreenModal title="快速新增單品" @back="backClicked">
    <div class="create-cloth">
      <section class="crop-container">
        <div class="crop-inner">
          
          <ImageAdjust ref="cropper" :src="dnsImgUrl" :canMove="true" :stencil-padding="120"></ImageAdjust>
          
          <p class="crop-hint">請透過縮放移動照片，框出照片中的單品</p>
        </div>
      </section>

      <section class="info">
        <div class="info-row">
          <div class="info-col-tsitle">
            <label>單品資訊</label>
          </div>
        </div>

        <div class="info-row v-center">
          <div class="info-col-title sub">
            <label class="required">類別:</label>
          </div>

          <div class="info-col-content">
            <div class="row">
              <div class="col-6 pr-1">
                <custom-select :optionObjects="category1Opt" :value="inputCategory1" @input="category1Changed">
                </custom-select>
              </div>

              <div class="col-6 pl-1">
                <custom-select :optionObjects="category2Opt" v-model="inputCategory2">
                </custom-select>
              </div>
            </div>
          </div>
        </div>

        <div class="info-row v-center">
          <div class="info-col-title sub">
            <label class="required">色系:</label>
          </div>

          <div class="info-col-content">
            <ColorSelect v-model="inputColor"></ColorSelect>
          </div>
        </div>

        <div class="text-right" v-if="showUploadBtn">
          <button class="btn btn-outline-dark px-4" @click="saveCrop" :disabled="uploadDisabled">
            上傳
          </button>
        </div>

        <div class="text-center text-danger py-2" v-if="!showUploadBtn">
          已達數量上限
        </div>

        <hr />

        <div class="row">
          <div class="col-4" v-for="(item, index) in cloths" :key="index">
            <div class="temp-cloth-div">
              <img :src="item.imgData" class="img-fluid"/>

              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" @click="removeCloth(index)">
                <path d="M18 6L6 18" stroke="#8D8D8D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6 6L18 18" stroke="#8D8D8D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

              <p>{{ item.categoryName }}</p>
            </div>
          </div>
        </div>

      </section>

      <section class="actions">
        <button class="horizon-btn" v-if="cloths.length > 0" @click="createBtnClicked">完成</button>
      </section>

      <transition name="fade">
        <WcCommonModal title="確定離開" v-if="isConfirmModalShow" @cancel="isConfirmModalShow = false" @ok="backConfirm" hide-header>
          <div class="text-center">
            未儲存資料<br/>
            要離開嗎？
          </div>
        </WcCommonModal>
      </transition>

    </div>
  </FullScreenModal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ColorSelect from '@/components/closet/ColorSelect.vue';
import clothDefault from '@/dataModel/cloth.js';
import FullScreenModal from '@/components/FullScreenModal.vue';
import WcCommonModal from '@/components/commons/WcCommonModal.vue';
import '@/assets/css/buttons.css';
import ImageAdjust from '@/components/cropImage/ImageAdjust.vue';

export default {
  name: 'QuicklyCreateCloth',
  data() {
    return {
      saveClothBusyName: 'CREATECLOTHSAVING',

      isConfirmModalShow: false,

      inputCategory1: -1,
      inputCategory2: -1,
      inputColor: 0,
      cloths: [],
    };
  },
  components: {
    ColorSelect,
    FullScreenModal,
    WcCommonModal,
    ImageAdjust,
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: -1,
    },
    srcImage: {
      type: String,
      required: true,
    },
    limit: {
      type: Number,
      required: false,
      default: -1,
    }
  },
  created() {
    this.cloth = Object.assign({}, this.cloth, clothDefault());
  },
  beforeDestroy() {
    this.clearComponentBusy(this.saveClothBusyName);
  },
  async mounted() {
  },
  computed: {
    ...mapState(['clothColors', 'clothCategories']),
    dnsImgUrl() {
      const sp = this.srcImage.split('/');
      const newUrl = `https://d3p33rlxepoyz5.cloudfront.net/${sp[sp.length -1]}`;
      return newUrl;
    },
    category1Opt() {
      const opts = [{
        value: -1,
        name: '請選擇'
      }];
      this.clothCategories.map((item) => {
        opts.push({
          name: item.name,
          value: item.serial
        });
      });
      return opts;
    },
    category2Opt() {
      const opts = [{
        value: -1,
        name: '請選擇'
      }];
      const selectedC1 = this.clothCategories.filter((item) => {
        return item.serial === this.inputCategory1;
      });
      if (selectedC1.length > 0) {
        selectedC1[0].sub.map((item) => {
          opts.push({
            name: item.name,
            value: item.serial
          });
        });
      }
      return opts;
    },
    uploadDisabled() {
      return this.inputCategory1 === -1 || this.inputCategory2 == -1;
    },
    showUploadBtn() {
      return this.limit <= 0 || this.cloths.length < this.limit;
    },
  },
  watch: {
  },
  methods: {
    ...mapActions(['appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
    async saveCrop() {
      // console.log(this.$refs.cropper);
      // const data = await this.$refs.cropper.crop();
      // console.log(data);
      this.$refs.cropper.crop().then((data) => {
        const category2Name = this.category2Opt.filter((item) => {
          return item.value === this.inputCategory2;
        });
        this.cloths.push({
          category1: this.inputCategory1,
          category2: this.inputCategory2,
          categoryName: category2Name[0].name,
          color: this.inputColor,
          imgData: data,
        });
        
        this.inputColor = 0;
        this.inputCategory1 = -1;
        this.inputCategory2 = -1;
      });
      // this.$refs['img-cropper'].getCropData((data) => {
      //   const category2Name = this.category2Opt.filter((item) => {
      //     return item.value === this.inputCategory2;
      //   });
      //   this.cloths.push({
      //     category1: this.inputCategory1,
      //     category2: this.inputCategory2,
      //     categoryName: category2Name[0].name,
      //     color: this.inputColor,
      //     imgData: data,
      //   });
        
      //   this.inputColor = 0;
      //   this.inputCategory1 = -1;
      //   this.inputCategory2 = -1;
      // })
    },
    removeCloth(index) {
      this.cloths.splice(index, 1);
    },
    category1Changed(val) {
      this.inputCategory2 = -1;
      this.inputCategory1 = val;
    },
    emitError(err) {
      this.appendErrorMsg(err);
    },
    async createBtnClicked() {
      this.appendComponentBusy(this.saveClothBusyName);
      try {
        const newCloths = [];
        for (const cloth of this.cloths) {
          const res = await this.$store.dispatch('api/postQuickCreateClothPromise', cloth);
          newCloths.push(res);
          // console.log(res);
        }
        this.$emit('created', newCloths);
      } catch(e) {
        this.emitError(e);
      } finally {
        this.clearComponentBusy(this.saveClothBusyName);
      }
    },
    backClicked() {
      if (this.cloths.length > 0) {
        this.isConfirmModalShow = true;
      } else {
        this.backConfirm();
      }
    },
    backConfirm() {
      this.$emit('back');
    },
  }
}
</script>

<style scoped>
  .create-cloth {
  }

  .crop-container{
    position: relative;
    width: 100%;
    padding-top: 100%;
  }

  .crop-inner{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .crop-hint {
    position: absolute;
    left: 50%;
    bottom: 8px;
    font-size: .8rem;
    margin: 0;
    transform: translate(-50%, 0);
    /* text-shadow: 0 0 4px #fff; */
    background-color: #fff8;
    padding: 2px 4px;
    white-space: nowrap;
    font-weight: 1000;
  }

  /* info */
  .info {
    padding: 1rem;
  }

  .info-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    flex-wrap: nowrap;
  }

  .info-row.v-center {
    align-items: center;
  }

  .info-col-title {
    flex: 1 1;
    font-size: 14px;
    padding-right: 1rem;
  }

  .info-col-title.sub {
    padding-left: 1rem;
  }

  .info-col-title>label {
    font-weight: 600;
    margin: 0;
  }

  .info-col-title>label.required::after {
    content: '*';
    color: var(--required-orange);
  }

  .info-col-content {
    flex: 0 0 70%;
    max-width: 70%;
    font-size: 12px;
  }

  .temp-cloth-div {
    position: relative;
  }

  .temp-cloth-div>svg {
    position: absolute;
    top: 0;
    right: 0;
  }

  .temp-cloth-div>img {
    opacity: .4;
  }

  .temp-cloth-div>p {
    font-size: .6rem;
  }

  /deep/ .vue-cropper .crop-info {
    display: none;
  }
</style>
