<template>
  <b-button @click="btnClicked" :disabled="disabled">
    <slot></slot>
  </b-button>
</template>

<script>

export default {
  name: 'DarkButton',
  data() {
    return {
    }
  },
  components: {
  },
  props: {
    colorStyle: {
      type: String,
      required: false,
      default: "regular"
    },
    customStyle: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  computed: {
    
  },
  methods: {
    btnClicked() {
      this.$emit('click');
    },
  }

}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
button.btn {
  background-color: $typography-primary-default;
  width: 100%;
}
</style>
