<template>
  <FullScreenModal class="create-outfit" :title="modalTitle" @back="backClicked">
    <div class="create-outfit-body">
      <div class="images-group">
        <CropImageButton ref="CropImage" @uploaded="outfitImgUploaded" v-if="!outfit.outfitPhotos[0] || outfit.outfitPhotos[0].type !== 'photo'">
          <div class="collection-image upload-btn d-flex flex-column justify-content-center align-items-center">
            <Icon name="Camera" size="24" />
            <div class="fs-16px">上傳封面照</div>
            <div class="fs-16px">記錄這一天</div>
          </div>
        </CropImageButton>
        <CropImageButton @uploaded="outfitImgUploaded" v-if="outfit && outfit.outfitPhotos[0] && outfit.outfitPhotos[0].type === 'photo'">
          <div class="collection-image">
            <img :src="outfitPhoto" alt="clothing image">
            <div class="delete-btn" @click.stop="removeCloth(-1)">
              <Icon name="close" size="24" />
            </div>
          </div>
        </CropImageButton>
        <div class="collection-image" v-for="(item, index) in outfitCloths" :key="index">
          <img :src="item.imgs[0]" alt="clothing image">
          <div class="delete-btn" @click.stop="removeCloth(index)">
            <Icon name="close" size="24" />
          </div>
        </div>
      </div>
      <button class="collect-btn btn-outline-primary btn-sm" @click="openCollectFromCloset">編輯穿搭單品</button>
      <CustomInputGroup labelText="日期" inputType="date" v-model="outfit.date" />
      <div class="select-row">
        <CustomSelectGroup class="col" labelText="天氣狀況" :options="weatherOpt"  v-model="outfit.weather" />
        <CustomSelectGroup class="col" labelText="氣溫" :options="temperatureOpt"  v-model="outfit.temperatureSection" />
      </div>
      <CustomPlaceFinder :placeData="placeData" @updateSearchPlace="handleSearchPlace" />
      <CustomTagEditor :editorData="outfit.brands" editorType="brand" @updateTagData="handleTagData" />
      <CustomTagEditor :editorData="outfit.tags" editorType="tag" @updateTagData="handleTagData" />

      <hr>
      <div class="info-group situation">
        <div class="title fs-14px fw-bold">場合</div>
        <div class="option d-flex">
          <label :class="{ picked: outfit.situation === '上班' }">
            <span>上班</span>
            <input type="radio" value="上班" v-model="outfit.situation">
          </label>
          <label :class="{ picked: outfit.situation === '約會' }">
            <span>約會</span>
            <input type="radio" value="約會" v-model="outfit.situation">
          </label>
          <label :class="{ picked: outfit.situation === '運動' }">
            <span>運動</span>
            <input type="radio" value="運動" v-model="outfit.situation">
          </label>
          <label :class="{ picked: outfit.situation === '上課' }">
            <span>上課</span>
            <input type="radio" value="上課" v-model="outfit.situation">
          </label>
          <label :class="{ picked: outfit.situation === '逛街' }">
            <span>逛街</span>
            <input type="radio" value="逛街" v-model="outfit.situation">
          </label>
          <label :class="{ picked: outfit.situation === '聚會' }">
            <span>聚會</span>
            <input type="radio" value="聚會" v-model="outfit.situation">
          </label>
          <label :class="{ picked: outfit.situation === '婚禮' }">
            <span>婚禮</span>
            <input type="radio" value="婚禮" v-model="outfit.situation">
          </label>
          <label :class="{ picked: outfit.situation === '其他' }">
            <span>其他</span>
            <input type="radio" value="其他" v-model="outfit.situation">
          </label>
        </div>
      </div>
      <hr>
      <div class="info-group emoji">
        <div class="title fs-14px fw-bold">穿搭感受</div>
        <div class="option d-flex">
          <label :class="{ picked: emoji === 1 }">
            <Icon name="emoji-happy" size="30" />
            <input type="radio" :value="1" v-model="emoji">
          </label>
          <label :class="{ picked: emoji === 0 }">
            <Icon name="emoji-fine" size="30" />
            <input type="radio" :value="0" v-model="emoji">
          </label>
          <label :class="{ picked: emoji === -1 }">
            <Icon name="emoji-sad" size="30" />
            <input type="radio" :value="-1" v-model="emoji">
          </label>
        </div>
      </div>
      <hr>
      <div class="info-row align-items-center">
        <div class="info-col-title">
          <label class="fs-16px fw-bold">公開分享</label>
          <p class="public-desc fs-14px">
            所有追蹤的好友可以看到本次穿搭紀錄
          </p>
        </div>

        <div class="col-auto px-0">
          <WcSwitch v-model="outfit.isPublic" />
        </div>
      </div>
      <hr>
      <textarea placeholder="備註" rows="3" v-model="outfit.desc">
      </textarea>
    </div>

    <CollectFromCloset v-if="showCollectFromCloset" :collectLimit="12" :collectedList="[]" v-model="showCollectFromCloset" @uploadCollectedItem="uploadCollectedItem" />

    <transition name="fade">
      <WcCommonModal title="確定離開" v-if="showConfirmModal" @cancel="showConfirmModal = false" @ok="backConfirm" hide-header>
        <div class="text-center">
          未儲存資料<br/>
          要離開嗎？
        </div>
      </WcCommonModal>
    </transition>

    <CustomModalFooter>
      <b-button class="btn-primary" block :disabled="!hasPhoto" @click="saveBtnClicked">儲存</b-button>
    </CustomModalFooter>
  </FullScreenModal>
</template>

<script>
import FullScreenModal from '@/components/FullScreenModal.vue';
import CollectFromCloset from "@/components/CollectFromCloset.vue";
import outfitDefault from "@/dataModel/outfit";
import moment from "moment/moment";
import {mapActions, mapState} from "vuex";
import WcCommonModal from "@/components/commons/WcCommonModal.vue";
import CropImageButton from "@/components/cropImage/CropImageButton.vue";
import CustomInputGroup from "@/components/commons/CustomInputGroup.vue";
import CustomSelectGroup from "@/components/commons/CustomSelectGroup.vue";
import CustomPlaceFinder from "@/components/commons/CustomPlaceFinder.vue";
import CustomTagEditor from "@/components/commons/CustomTagEditor.vue";
import WcSwitch from "@/components/commons/WcSwitch.vue";
import CustomModalFooter from "@/components/CustomModalFooter.vue";

export default {
  name: 'CreateCloth',
  data() {
    return {
      initBusyName: 'CREATEOUTFITINITREADING',
      uploadBusyName: 'CREATEOUTFITUPLOADINGIMAGE',
      saveBusyName: 'OUTFITSAVING',
      init: false,

      showCollectFromCloset: false,
      showConfirmModal: false,

      outfit: {},
      collectFromClosetList: [],
    };
  },
  components: {
    CropImageButton,
    WcCommonModal,
    FullScreenModal,
    CollectFromCloset,
    CustomInputGroup,
    CustomSelectGroup,
    CustomPlaceFinder,
    CustomTagEditor,
    WcSwitch,
    CustomModalFooter,
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: -1,
    },
    createDefaultDate: {
      type: String,
      required: false,
      default: '',
    },
    step: {
      type: Number,
      required: false,
      default: -1,
    }
  },
  created() {
    this.outfit = Object.assign({}, this.outfit, outfitDefault());
    if (this.isCreate && this.createDefaultDate.length > 0) {
      this.outfit.date = moment(this.createDefaultDate).format('YYYY-MM-DD');
    }
  },
  beforeDestroy() {
    this.clearComponentBusy(this.initBusyName);
    this.clearComponentBusy(this.uploadBusyName);
    this.clearComponentBusy(this.saveBusyName);
  },
  async mounted() {
    this.appendComponentBusy(this.initBusyName);
    try {
      if (this.id !== -1) {
        const outfit = await this.$store.dispatch('api/readOutfitInfoPromise', this.id);
        outfit.outfitPhotos = outfit.outfitPhotos.map((o) => {
          if (o.type === 'photo') {
            return o;
          } else if (o.type === 'cloth') {
            return {
              type: o.type,
              clothSerial: o.clothSerial,
            }
          }
        });
        this.outfit = Object.assign({}, this.outfit, outfit);
      } else {
        this.showCollectFromCloset = this.step === 1;
      }
      this.init = true;
    } catch(e) {
      this.emitError(e);
    } finally {
      this.clearComponentBusy(this.initBusyName);
    }
    console.log(this.showCollectFromCloset)
    if(!this.showCollectFromCloset) {
      await this.delay(1000);
      this.$refs['CropImage'].uploadBtnClicked();
    }
  },
  computed: {
    ...mapState(['cloths']),

    isCreate() {
      return this.id === -1;
    },
    isEdit() {
      return this.id !== -1;
    },
    modalTitle() {
      if (this.isCreate) {
        return '新增記錄穿搭';
      }
      if (this.isEdit) {
        return '編輯記錄穿搭';
      }
      return '';
    },
    hasPhoto() {
      return this.outfit.outfitPhotos.length > 0;
    },
    outfitPhoto() {
      if (this.hasPhoto) {
        return this.outfit.outfitPhotos[0].imgUrl;
      }
      return '';
    },
    outfitCloths() {
      const res = [];
      for (const op of this.outfit.outfitPhotos) {
        if (op.type !== 'cloth') {
          continue;
        }
        const cloth = this.cloths.filter((c) => {
          return c.serial === op.clothSerial;
        });
        res.push(cloth[0]);
      }
      return res;
    },
    weatherOpt() {
      const weatherIds = [0, 5, 1, 2, 3, 4, 6, 7, 8, 9];
      const weatherStrs = ['晴天', '明月', '多雲', '晴時多雲', '雨', '雷雨', '強風', '寒流', '雪', '濃霧'];
      const opt = [];
      for (let i = 0 ; i < weatherIds.length ; i ++ ) {
        opt.push({
          value: weatherIds[i],
          text: weatherStrs[i],
        });
      }
      return opt;
    },
    temperatureOpt() {
      const opt = [{
        value: -1,
        text: '請選擇',
      }];
      const secs = [
        '-10 ~ 0 ℃',
        '1 ~ 10 ℃',
        '11 ~ 15 ℃',
        '16 ~ 20 ℃',
        '21 ~ 25 ℃',
        '26 ~ 30 ℃',
        '31 ~ 35 ℃',
        '36 ~ 40 ℃',
      ];
      for (let i = 0 ; i < secs.length ; i ++ ) {
        opt.push({
          value: i,
          text: secs[i],
        });
      }
      return opt;
    },
    emoji: {
      get() {
        return this.outfit.feelingUnknown ? null : this.outfit.feeling;
      },
      set(value) {
        this.outfit.feeling = value;
        this.outfit.feelingUnknown = false; // 當選擇 emoji 時，設定 feelingUnknown 為 false
      }
    },
    placeData() {
      return {
        place: this.outfit.place,
        placeId: this.outfit.placeId,
        placeLat: this.outfit.placeLat,
        placeLong: this.outfit.placeLong,
        placeCountry: this.outfit.placeCountry,
        areaLevel1: this.outfit.areaLevel1,
        areaLevel2: this.outfit.areaLevel2,
        areaLevel3: this.outfit.areaLevel3,
      }
    },
  },
  watch: {
  },
  methods: {
    ...mapActions(['appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'updateCloth']),

    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    backClicked() {
      this.showConfirmModal = true;
    },
    backConfirm() {
      this.$emit('back');
    },
    outfitImgUploaded(data) {
      console.log(data)
      const {url} = data;
      if (this.hasPhoto && this.outfit.outfitPhotos[0] === 'photo') {
        this.outfit.outfitPhotos[0].imgUrl = url;
      } else {
        this.outfit.outfitPhotos.unshift({
          type: 'photo',
          imgUrl: url,
          tags: [],
        });
      }
    },
    uploadCollectedItem(clothSerials) {
      clothSerials.map((serial) => {
        const found = this.outfit.outfitPhotos.find((p) => {
          return p.type === 'cloth' && p.clothSerial === serial;
        });
        if (found === undefined || found === null) {
          this.outfit.outfitPhotos.push({
            type: 'cloth',
            clothSerial: serial,
          });
        }
      });
      // console.log(clothSerials);
      this.collectFromClosetList = clothSerials;
    },
    removeCloth(index) {
      let currentIndex = this.outfit?.outfitPhotos[0]?.type === 'photo' ? index + 1 : index

      // 確保當前項目存在
      const currentItem = this.outfit.outfitPhotos[currentIndex];
      if (!currentItem) return;

      // 檢查 type 是否為 'cloth'
      if (currentItem.type === 'cloth') {
        const serialToRemove = currentItem.clothSerial;

        // 從 collectFromClosetList 中移除匹配的 serial
        const serialIndex = this.collectFromClosetList.indexOf(serialToRemove);
        if (serialIndex !== -1) {
          this.collectFromClosetList.splice(serialIndex, 1);
        }
      }

      // 從 outfitPhotos 中移除當前項目
      this.outfit.outfitPhotos.splice(currentIndex, 1);
    },
    openCollectFromCloset() {
      this.showCollectFromCloset = true;
    },
    handleSearchPlace(newPlaceData) {
      console.log('收到的地點資料:', newPlaceData);
      this.outfit = {
        ...this.outfit,  // 保持原有資料
        ...newPlaceData     // 更新地點相關欄位
      };
    },
    handleTagData(newTagData) {
      switch (newTagData.type) {
        case 'brand':
          this.outfit.brands = JSON.parse(JSON.stringify(newTagData.tagData));
          break;
        case 'tag':
        default:
          this.outfit.tags = JSON.parse(JSON.stringify(newTagData.tagData));
      }
    },
    checkValidate(outfit) {
      if (outfit.outfitPhotos.length === 0) {
        return '請上傳穿搭照片';
      } else if (outfit.outfitPhotos.length === 1) {
        return '請至少選擇一件搭配中的衣物單品';
      }
      return '';
    },
    async saveBtnClicked() {
      const insertData = this.outfit;
      const errMsg = this.checkValidate(insertData);
      if (errMsg.length > 0) {
        this.appendErrorMsg(errMsg);
      } else {
        this.appendComponentBusy(this.saveBusyName);
        try {
          if(this.id === -1) {
            const res = await this.$store.dispatch('api/postCreateOutfitPromise', insertData);
            console.log(res);
            await this.$store.dispatch('updateOutfit', res);
            this.$emit('created', res);
          } else {
            const res = await this.$store.dispatch('api/updateOutfitPromise', {
              id: this.id,
              outfit: insertData,
            });
            await this.$store.dispatch('updateOutfit', res);
            this.$emit('updated', res);
          }
        } catch(e) {
          this.appendErrorMsg(e);
        } finally {
          this.clearComponentBusy(this.saveBusyName);
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
@font-face {
  font-family: Wweather;
  src: url('~@/assets/font/Writes_weather.ttf');
}
.create-outfit {
  .create-outfit-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem 1rem 6.5rem;
  }
  .images-group {
    position: relative;
    display: flex;
    align-items: center;
    gap: .5rem;
    width: calc(100% + 1rem);
    overflow-x: auto;
    padding-right: 1rem;
  }
  .collection-image {
    flex: 0 0 auto;
    background: $color-image-background;
    width: 109px;
    height: 109px;
    border-radius: .5rem;
    overflow: hidden;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    a {
      position: absolute;
      top: 0;
      right: 0;
    }
    .delete-btn {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      color: $typography-primary-default;
    }
  }
  .upload-btn {
    color: $typography-secondary-default;
    background: transparent;
    border: 1px dashed $color-border;
    .svg-icon {
      margin-bottom: .5rem;
    }
  }
  .collect-btn {
    border-color: $color-border;
    border-radius: .5rem;
    &:not(:disabled):hover, &:focus, &:not(:disabled):not(.disabled):active {
      border-color: $color-border;
    }
    &:disabled, &.hover:disabled {
      border-color: $color-border;
    }
  }
  .select-row {
    display: flex;
    flex-wrap: nowrap;
    gap: .5rem;
    .col {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .info-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    .info-col-title {
      flex: 1 1;
      padding-right: 1rem;
      label {
        margin-bottom: .5rem;
      }
      .public-desc {
        margin-bottom: 0;
      }
    }
    .col-auto {
      display: flex;
    }
  }
  hr {
    width: 100%;
    margin: 0;
  }
  .info-group {
    padding-top: .5rem;
    padding-bottom: .5rem;
    &.situation {
      .option {
        gap: .5rem;
        label {
          color: $typography-primary-default;
          border: 1px solid $color-accent;
          border-radius: 100px;
          padding: 6px 16px;
          &.picked {
            color: $color-white;
            background: $typography-primary-default;
            border-color: $typography-primary-default;
          }
        }
      }
    }
    &.emoji {
      .option {
        gap: 1rem;
        label {
          display: flex;
          justify-content: center;
          align-items: center;
          color: $typography-secondary-default;
          width: 48px;
          height: 48px;
          border: 1px solid $color-accent;
          border-radius: 100px;
          &.picked {
            color: $color-white;
            background: $typography-primary-default;
            border-color: $typography-primary-default;
          }
        }
      }
    }
    .title {
      margin-bottom: .5rem;
    }
    .option {
      flex-wrap: wrap;
      label {
        flex-shrink: 0;
        margin-bottom: 0;
        input {
          display: none;
        }
      }
    }
  }
  textarea {
    width: 100%;
    border-radius: .5rem;
    border: 1px solid $color-border;
    resize: none;
    padding: 1rem;
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";

.create-outfit {
  .custom-input-group, .custom-select-group {
    margin-bottom: 0;
  }
  .wc-switch {
    width: 54px;
    height: 30px;
    &.on {
      background-color: $typography-primary-default;
      .ball {
        left: 27px;
      }
    }
    .ball {
      top: 3px;
      left: 3px;
      width: 24px;
      height: 24px;
    }
  }
}
</style>
