<template>
  <div>
    <div class="loadingModal" v-if="busy">
      <div class="innerPanel">
        Now Loading...
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'loading-cover',
  data() {
    return {
    }
  },
  props: {
    busy: Boolean
  },
  methods: {

  }

}
</script>

<style scoped>
  .loadingModal {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #fffa;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

  .innerPanel {
    color: #999;
    font-size: 2em;
  }
</style>
