<template>
  <div class="full-screen-modal">
    <div class="bg"></div>
    <div class="front">
      <p class="input-label">請輸入商品編號</p>
      <div class="input-div">
        <input type="search" ref="main-input" class="search-input" v-model="inputSearch" @keyup.enter="searchClicked">
        <button class="search-btn" @click="searchClicked">
          <img :src="require('@/assets/add_by_number_modal/search-btn.png')"/>
        </button>
      </div>
      
      <button class="back-btn" @click="backClicked">
        <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 1L2 7L8 13" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"/>
        </svg>
      </button>
    </div>

    <transition name="fade">
      <AddClothFromTemplateModal v-if="isViewModalShow" :clothTemplate="foundTemplate" @back="isViewModalShow = false" @buy="buyConfirmed"></AddClothFromTemplateModal>
    </transition>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import AddClothFromTemplateModal from '@/components/commons/AddClothFromTemplateModal.vue';

export default {
  name: 'AddByNumberModal',
  data() {
    return {
      inputSearch: '',
      foundTemplate: null,

      isViewModalShow: false,
    };
  },
  mounted() {
    this.$refs['main-input'].focus();
  },
  components: {
    AddClothFromTemplateModal,
  },
  props: {
  },
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'updateCloth']),
    searchClicked() {
      const epc = this.inputSearch.trim();
      if (epc.length === 0) {
        this.showMsgModal('請輸入要新增的衣物的編號');
      } else {
        this.searchEpc(this.inputSearch);
      }
    },
    async searchEpc(epc) {
      const busyName = "SEARCH_EPC";
      this.$store.dispatch("appendComponentBusy", busyName);
      try {
        const searchRes = await this.$store.dispatch("api/readTemplateByEpcPromise", epc);
        if (searchRes === null) {
          this.showMsgModal('找不到此編號的衣物');
        } else {
          this.foundTemplate = Object.assign({}, searchRes);
          this.isViewModalShow = true;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("clearComponentBusy", busyName);
      }
    },
    buyConfirmed() {
      const epc = this.inputSearch.trim();
      if (epc.length === 0) {
        this.showMsgModal('請輸入要新增的衣物的編號');
      } else {
        this.addToCloset(this.inputSearch);
      }
    },
    async addToCloset(epc) {
      const busyName = "ADDTOCLOSETBUSY";
      this.appendComponentBusy(busyName);
      try {
        const createdCloth = await this.$store.dispatch('api/buyTemplateByEpcPromise', epc);
        this.$store.dispatch('updateCloth', createdCloth);
        this.showMsgModal('新增衣物成功!');
        this.$emit('back');
      } catch (error) {
        console.error(error);
        this.$emit('error', error);
      } finally {
        this.clearComponentBusy(busyName);
      }
    },
    backClicked() {
      this.$emit('back');
    },
  }
}
</script>

<style scoped>
  .full-screen-modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    height: -moz-available;
    height: -webkit-fill-available;
    height: fill-available;
    height: 100dvh;
    z-index: 8000;
    
  }

  .full-screen-modal .bg {
    background-color: #000;
    opacity: .8;
    width: 100%;
    height: 100%;
  }  

  .full-screen-modal .front {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: #fff;
    padding: 6rem 2rem;
  }

  .full-screen-modal .input-label {
    width: 100%;
  }

  .full-screen-modal .input-div {
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: solid 1px #fff;
  }

  .full-screen-modal .input-div input {
    border: none;
    flex: 1 1;
    background-color: transparent;
    color: #fff;
    padding: .5rem 0;
  }

  .full-screen-modal .input-div input:focus {
    outline: none;
  }

  .full-screen-modal .input-div button.search-btn {
    border: none;
    background-color: transparent;
    padding: .5rem;
  }

  .full-screen-modal .input-div button.search-btn>img {
    width: 24px;
  }

  .full-screen-modal .back-btn {
    border: none;
    /* border: solid 1px #fff; */
    /* border-radius: .5rem; */
    color: #fff;
    /* margin-top: 1rem; */
    padding: .75rem 1.25rem;
    background-color: transparent;

    position: absolute;
    top: 0;
    left: 0;
    font-size: .8rem;
  }

</style>
