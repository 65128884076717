<template>
  <transition name="fade">
    <FullScreenModal v-if="isResetPasswordShow" title="變更密碼" @back="backClicked">
      <div class="register">
        
        <div class="register-content">
          <div class="register-with-email">
            <h2>新密碼</h2>
            <div class="input-div">
              <input type="password" v-model="inputNewPassword" placeholder="密碼"/>
            </div>
            <small class="d-block pl-3 mb-4">請輸入 6-20 英數</small>

            <h2>確認新密碼</h2>
            <div class="input-div">
              <input type="password" v-model="inputNewPasswordAgain" placeholder="請輸入相同密碼"/>
            </div>

            <div class="text-center pt-3">
              <button @click="resetClicked">設定新密碼</button>
            </div>
          </div>

        </div>

      </div>
    </FullScreenModal>
  </transition>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import FullScreenModal from '@/components/FullScreenModal.vue';

export default {
  name: 'ResetPasswordModal',
  data() {
    return {
      inputNewPassword: '',
      inputNewPasswordAgain: '',
      savingBusyName: 'RESETBUSY',
    };
  },
  components: {
    FullScreenModal,
  },
  props: {},
  beforeDestroy() {},
  async mounted() {
  },
  computed: {
    ...mapGetters(['isResetPasswordShow']),
  },
  watch: {

  },
  methods: {
    ...mapActions(['setResetPasswordShow', 'showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
    backClicked() {
      this.setResetPasswordShow(false);
      // this.$emit('close');
    },
    async resetClicked() {
      if (this.inputNewPassword.length < 6 || this.inputNewPassword.length > 20) {
        this.showMsgModal('新密碼長度需介於6~20個文字之間');
        return;
      }
      if (!(/^[A-Za-z0-9]*$/.test(this.inputNewPassword))) {
        this.showMsgModal('新密碼僅限使用英文與數字');
        return;
      }
      if (this.inputNewPassword !== this.inputNewPasswordAgain) {
        this.showMsgModal('確認新密碼與設定的新密碼不相同');
        return;
      }
      this.appendComponentBusy(this.savingBusyName);
      try {
        await this.$store.dispatch('api/postResetPasswordPromise', this.inputNewPassword);
        this.showMsgModal('密碼變更成功!');
        this.backClicked();
      } catch(err) {
        this.appendErrorMsg(err.toString());
      } finally {
        this.clearComponentBusy(this.savingBusyName);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .register {
    padding: 2rem 1rem 2rem;
    h2 {
      color: var(--text-color-main);
      font-size: 1.25rem;
      margin-bottom: .5rem;
    }
    .register-div {
      text-align: center;
      margin-bottom: 1.3rem;
      >img {
        width: 40%;
      }
    }

    .register-content {
      .register-with-email {
        padding: 0 1rem;
        margin-bottom: 2rem;
        .input-div {
          input {
            border: none;
            padding: .5rem 1rem;
            background-color: #eeeeee;
            // color: var(--text-color-remark);
            border-radius: 0.5rem;
            width: 100%;
            border-radius: .5rem;
            ::placeholder {
              color: var(--text-color-remark);
              color: red;
            }
          }
        }
        .forgot-psw {
          margin-bottom: 0;
          margin-top: .2rem;
          font-size: .8rem;
        }
        button {
          padding: 0.5rem 2.5rem;
          display: inline-block;
          border: none;
          border-radius: 1rem;
          background: var(--brand-color-primary);
          color: #fff;
        }
      }

      .login-with-sns {
        .sns-logins {
          padding: 0 3rem;
        }
        .sns-desc {
          position: relative;
          width: 100%;
          text-align: center;
          >span {
            position: relative;
            color: var(--text-color-main);
            font-size: .8rem;
            background-color: #ffffff;
            padding: 0 .25rem;
          }
          &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 0;
            left: 0;
            top: 50%;
            border-top: solid 1px var(--border-color-main);
          }
        }

        .btn {
          &.btn-wc {
            font-weight: bold;
            width: 100%;
            border-radius: .5rem;
            padding: .4rem 1rem .5em 1rem;
            position: relative;
            >img {
              position: absolute;
              left: .5rem;
              top: 50%;
              transform: translate(0, -50%);
              height: 24px;
            }
          }
          &.btn-line {
            background-color: #06C755;
            color: #fff;
            text-align: left;
            padding-left: 3rem;
            >img {
              height: 36px;
            }
          }

          &.btn-fb {
            background-color: #4676ED;
            color: #fff;
            text-align: left;
            padding-left: 3rem;
            >img {
              left: .8rem;
            }
          }

          &.btn-desc {
            border-color: #F2C31C;
            width: 100%;
            display: block;
            border-radius: 999px;
            color: #F2C31C;
            letter-spacing: .15em;
            >img {
              height: 1em;
              display: inline-block;
              margin: 0 .2rem;
              position: relative;
              top: -0.2em;
            }
          }
        }

        .register-msg {
          text-align: center;
          color: var(--text-color-main);
          font-size: .8rem;
          margin-top: 1.5rem;
          margin-bottom: 0;
          >a {
            color: var(--text-color-main);
            text-decoration: underline;
          }
        }

        .agree-msg {
          margin-top: 1.1rem;
          text-align: center;
          color: var(--text-color-main);
          font-size: .8rem;
          >a {
            color: var(--text-color-main);
            text-decoration: underline;
          }
        }
      }
    }
  }
</style>
