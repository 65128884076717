<template>
  <WcModal @backClick="backClicked">
    <transition name="slideup">
      <div class="tag-modal" :class="{'modal-brand': isBrand, 'modal-tag': isTag}" v-if="inited">
        <div class="tag-modal-content">
          <div class="search-sec">
            

            <div class="search-bar-outer mb-3">
              <div class="search-bar">
                <input type="text" ref="mainInput" :placeholder="inputPlaceHolder" v-model="inputText" @keyup.enter="addClicked" />
                <!-- <a href="#" @click.prevent="addClicked" class="modal-add" v-show="!isInputEmpty">
                  <img :src="require('@/assets/icons/enter.png')"/>
                </a> -->

              </div>

              <a href="#" @click.prevent="addClicked" class="modal-ok" :class="{'valid': !isInputEmpty}">
                新增
              </a>
            </div>

            <div class="now-tags">
              <span class="tag-pill" v-for="(item, index) in tags" :key="item">
                <span>#{{item}}</span>
                <a href="#" @click.prevent="removeTag(index)">
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 3L3 9" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M3 3L9 9" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>

                </a>
              </span>
            </div>

            <div class="recent-tags">
              <p>最近使用</p>
              <div class="row no-gutters">
                <span @click="attachRecentData(item)" class="recent-tag-pill" v-for="(item, index) in recentData" :key="index">
                  <span>#{{item}}</span>
                </span>
              </div>

            </div>

          </div>

          <a href="#" @click.prevent="closeClicked" class="modal-close">
            <img :src="require('@/assets/icons/search-close.png')"/>
          </a>

          <a href="#" @click.prevent="okClicked" class="modal-save">
            儲存
          </a>

          <transition name="fade">
            <WcCommonModal title="放棄變更" v-if="isConfirmModalShow" @cancel="isConfirmModalShow = false" @ok="cancelTagEdit" hide-header>
              <div class="text-center" style="font-size: 14px;">
                未儲存資料<br/>
                要離開嗎？
              </div>
            </WcCommonModal>
          </transition>

        </div>
      </div>
    </transition>
  </WcModal>
</template>

<script>
import WcCommonModal from '@/components/commons/WcCommonModal.vue';
import WcModal from '@/components/commons/WcModal.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'tag-modal',
  data() {
    return {
      inited: false,
      inputText: '',
      tags: [],
      isConfirmModalShow: false,
      measureCanvas: null,
      inputWidth: '100px',
    }
  },
  components: {
    WcCommonModal,
    WcModal,
  },
  created() {
    if (this.initTags) {
      for (const t of this.initTags) {
        this.tags.push(t);
      }
    }
  },
  mounted() {
    console.log('hi');
    setTimeout(() => {
      this.inited = true;
    }, 0);
    // setTimeout(() => {
    //   this.$refs['mainInput'].focus();
    // }, 1000);
  },
  watch: {
    inputText(val) {
      const w = this.getTextWidth(val);
      if (w < 100) {
        this.inputWidth = '100px';
      } else {
        this.inputWidth = `${w + 10}px`;
      }
    },
  },
  computed: {
    ... mapGetters(['recentBrands', 'recentTags']),
    isInputEmpty() {
      return this.inputText.trim().length === 0;
    },
    isTagEmpty() {
      return this.tags.length === 0;
    },
    isBrand() {
      return this.tagType === 'brand';
    },
    isTag() {
      return this.tagType === 'tag';
    },
    recentData() {
      if (this.isBrand) {
        return this.recentBrands;
      } else if (this.isTag) {
        return this.recentTags;
      }
      return [];
    },
    inputPlaceHolder() {
      if (this.isBrand) {
        return '輸入品牌名稱';
      } else {
        return '姊妹聚會/約會/面試/上班';
      }
    },
    tagChanged() {
      if (this.tags.length !== this.initTags.length) {
        return true;
      }
      for (let i=0;i<this.tags.length;i++) {
        if (this.tags[i] !== this.initTags[i]) {
          return true;
        }
      }
      return false;
    },

  },
  props: {
    tagType: {
      type: String,
      required: false,
      default: 'brand'
    },
    initTags: {
      type: Array,
      required: false,
    }
  },
  methods: {
    addClicked() {
      const tagText = this.inputText.trim();
      if (tagText.length > 0 && this.tags.indexOf(tagText) === -1) {
        this.tags.push(tagText);
        this.inputText = '';
      }
    },
    closeClicked() {
      this.addClicked();
      if (!this.tagChanged) {
        this.$emit('close');
      } else {
        this.isConfirmModalShow = true;
      }
    },
    backClicked() {
      this.closeClicked();
    },
    removeTag(index) {
      this.tags.splice(index, 1);
    },
    okClicked() {
      this.addClicked();
      this.$emit('ok', this.tags);
    },
    cancelTagEdit() {
      this.$emit('close');
    },
    getCssStyle(element, prop) {
      return window.getComputedStyle(element, null).getPropertyValue(prop);
    },
    getInputFont() {
      const el = this.$refs.mainInput;
      const fontWeight = this.getCssStyle(el, 'font-weight') || 'normal';
      const fontSize = this.getCssStyle(el, 'font-size') || '16px';
      const fontFamily = this.getCssStyle(el, 'font-family') || 'Times New Roman';
      return `${fontWeight} ${fontSize} ${fontFamily}`;
    },
    getTextWidth(text) {
      if (this.measureCanvas === null) {
        this.measureCanvas = document.createElement("canvas");
      }
      const context = this.measureCanvas.getContext("2d");
      context.font = this.getInputFont();
      const metrics = context.measureText(text);
      return metrics.width;
    },
    attachRecentData(tag) {
      if (this.tags.indexOf(tag) === -1) {
        this.tags.push(tag);
      }
    },
  }

}
</script>

<style scoped>

  .modal-brand {
    --pill-color: #7DC4B7;
  }

  .modal-tag {
    --pill-color: #F2C31C;
  }

  .tag-modal {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }

  .tag-modal-content {
    position: relative;
    padding: 3rem 1rem 2.5rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    background-color: #fff;
  }

  .search-sec {

  }

  .now-tags {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .tag-pill {
    position: relative;
    background: var(--pill-color);
    border-radius: 999px;
    color: #fff;
    padding: 0px 32px 0 12px;
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    height: 24px;
    margin-right: .5rem;
    margin-bottom: .5rem;
  }

  .tag-pill>span {
    display: block;
    white-space: nowrap;
    font-size: 12px;
  }

  .tag-pill>a {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    top: 1px;
    right: 1px;
  }

  .tag-pill>a>svg path {
    stroke: var(--pill-color);
  }

  .search-bar-outer {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .search-bar {
    flex: 1 1;
    position: relative;
    background-color: #EEEEEE;
    border-radius: .5rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: .3rem .5rem;
    padding-right: 0;
    padding-bottom: 0;
  }

  .search-bar>input {
    background: transparent;
    border: none;
    flex: 1 0;
    margin-bottom: .5rem;
    /* padding: 4px 12px; */
  }

  .search-bar>input::placeholder { /* CSS 3 標準 */
    color: #c4c4c4;
  }

  .search-bar>input:focus {
    box-shadow: none;
    outline: none;
  }

  .recent-tags {
    margin-top: .5rem;
    padding-top: .5rem;
    border-top: dashed 1px #ddd;
  }

  .recent-tags>p {
    font-size: 12px;
    margin-bottom: .5rem;
    color: #ccc;
  }

  .recent-tag-pill {
    position: relative;
    /* border: solid 1px var(--pill-color); */
    /* color: var(--pill-color); */
    border: solid 1px #999;
    color: #999;
    background: #fff;
    border-radius: 999px;
    padding: 0px 12px 0 12px;
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    height: 24px;
    margin-right: .5rem;
    margin-bottom: .5rem;
  }

  .recent-tag-pill>span {
    display: block;
    white-space: nowrap;
    font-size: 12px;
  }

  .modal-add {
    position: absolute;
    right: 12px;
    top: 12px;
    display: block;
  }

  .modal-ok {
    /* position: absolute; */
    /* right: 12px; */
    /* top: 8px; */
    display: block;
    font-size: 14px;
    color: #fff;
    background-color: #444;
    padding: .5rem 1rem;
    border-radius: .5rem;
    margin-left: 1rem;
  }
  
  .modal-ok:hover {
    text-decoration: none;
  }

  .modal-ok:not(.valid) {
    opacity: .5;
  }

  .modal-close {
    position: absolute;
    left: 1rem;
    top: 1rem;
    display: block;
  }

  .modal-save {
    position: absolute;
    right: 1rem;
    top: .7rem;
    display: block;
  }

  .modal-close>img, .modal-add>img {
    width: 12px;
    height: 12px;
    display: block;
  }
</style>
