<template>
  <FullScreenModal :title="modalTitle" @back="backClicked">
    <div class="view-outfit">

      <section class="user-header" v-if="init">
        <img :src="outfit.user.picUrl"/>
        <span>{{outfit.user.displayName}}</span>
      </section>

      <Flickity ref="outfitFlickity" :options="flickityOptions" v-if="init">
        <div class="square-outer" v-for="(photo, index) in outfit.outfitPhotos" :key="index">
          <img :src="photo.imgUrl" style="background-color: #eeeeee; object-fit: cover;" v-if="photo.type === 'photo'" />
          <img :src="photo.cloth.imgs[0]" style="background-color: #eeeeee;" v-if="photo.type === 'cloth'" />
          <div class="float-div">
            <div class="cloth-info-btn" v-if="photo.type === 'cloth'" @click="clothInfoClicked(index)">
              <img :src="require('@/assets/icons/shirt.png')" />
            </div>
          </div>
        </div>
      </Flickity>

      <section class="cloths" v-if="hasPhoto">

        <div class="cloth-div" :class="{'active': showingIndex === index}" v-for="(photo, index) in outfit.outfitPhotos" :key="index">
          <div class="square-outer" @click="navImgClicked(index)">
            <img :src="photo.imgUrl" style="background-color: #eeeeee; object-fit: cover;" v-if="photo.type === 'photo'" />
            <img :src="photo.cloth.imgs[0]" style="background-color: #eeeeee;" v-if="photo.type === 'cloth'" />
          </div>
        </div>

      </section>

      <section class="info">
        <div class="info-row v-center">
          <div class="info-col-title sub">
            <label class="">{{outfitDate}}</label>
          </div>
        </div>

        <div class="info-row v-center" v-if="outfit.situation.length > 0">
          <div class="info-col-title sub">
            <label>場合:</label>
          </div>

          <div class="info-col-content">
            <span class="">{{outfit.situation}}</span>
          </div>
        </div>

        <div class="info-row v-center" v-if="outfit.place.length > 0">
          <div class="info-col-title sub">
            <label>地點:</label>
          </div>

          <div class="info-col-content">
            <span class="">{{outfit.place}}</span>
          </div>
        </div>

        <div class="info-row v-center">
          <div class="info-col-title sub">
            <label class="">天氣:</label>
          </div>

          <div class="info-col-content">
            <span class="weather-icons">{{outfit.weather}}</span>
            <span class="d-inline-block pl-3">{{temperatureString}}</span>
          </div>
        </div>

        <div class="info-row" v-if="outfit.desc.length > 0">
          <div class="info-col-title sub pt-1">
            <p>{{outfit.desc}}</p>
          </div>
        </div>

        <hr/>

        <div class="info-row" v-if="outfit.brands.length > 0">
          <div class="info-col-title sub pt-1">
            <div class="row no-gutters">
              <div class="col-auto pr-2" v-for="(item) in outfit.brands" :key="item">
                <span class="pills brand-pill">
                  <span>#{{item}}</span>
                </span>
              </div>

              <div class="col-auto pr-2" v-for="(item) in outfit.tags" :key="item">
                <span class="pills tag-pill">
                  <span>#{{item}}</span>
                </span>
              </div>
            </div>
          </div>
        </div>

      </section>

      <section class="actions">
        <button class="horizon-btn" v-if="canEdit" @click="editClicked">編輯</button>
        <button class="horizon-btn white" v-if="canDelete" @click="deleteClicked">刪除</button>
      </section>
    </div>

    <transition name="fade">
      <WcCommonModal title="確定刪除" v-if="isConfirmModalShow" @cancel="closeConfirmModal" @ok="deleteConfirm" hide-header>
        <div class="text-center">
          確定刪除?
        </div>
      </WcCommonModal>
    </transition>

    <transition name="fade">
      <ClothSimpleInfoModal v-if="isClothInfoModalShow" :cloth="infoCloth" @close="closeClothInfoModal">
      </ClothSimpleInfoModal>
    </transition>

  </FullScreenModal>
</template>

<script>
import Flickity from 'vue-flickity';
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import outfitDefault from '@/dataModel/outfit.js';
import FullScreenModal from '@/components/FullScreenModal.vue';
import WcCommonModal from '@/components/commons/WcCommonModal.vue';
import ClothSimpleInfoModal from '@/components/outfit/ClothSimpleInfoModal.vue';
import '@/assets/css/buttons.css';

export default {
  name: 'ViewOutfit',
  data() {
    return {
      initBusyName: 'VIEWOUTFITINITREADING',
      deleteBusyName: 'VIEWOUTFITINITDELETEING',
      init: false,

      outfit: {},
      showingIndex: 0,

      flickityOptions: {
        // initialIndex: 3,
        prevNextButtons: false,
        pageDots: false,
        // wrapAround: true
        on: {
          ready: () => {
            // console.log('Flickity ready');
          },
          select: (index) => {
            this.showingIndex = index;
          }
        }
      },

      isConfirmModalShow: false,
      isClothInfoModalShow: false,
      showingClothIndex: -1,
    };
  },
  components: {
    Flickity,
    FullScreenModal,
    WcCommonModal,
    ClothSimpleInfoModal,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    canEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    canDelete: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  created() {
    this.outfit = Object.assign({}, this.outfit, outfitDefault());
  },
  beforeDestroy() {
    this.init = false;
    this.clearComponentBusy(this.initBusyName);
  },
  async mounted() {
    this.appendComponentBusy(this.initBusyName);
    try {
      const outfit = await this.$store.dispatch('api/readOutfitInfoPromise', this.id);
      this.outfit = Object.assign({}, this.outfit, outfit);
      this.init = true;
    } catch(e) {
      this.emitError(e);
    } finally {
      this.clearComponentBusy(this.initBusyName);
    }
  },
  computed: {
    ...mapState(['cloths']),
    hasPhoto() {
      return this.outfit.outfitPhotos.length > 0;
    },
    outfitPhoto() {
      if (this.hasPhoto) {
        return this.outfit.outfitPhotos[0].imgUrl;
      }
      return '';
    },
    outfitCloths() {
      const res = [];
      for (const op of this.outfit.outfitPhotos) {
        if (op.type !== 'cloth') {
          continue;
        }
        const cloth = this.cloths.filter((c) => {
          return c.serial === op.clothSerial;
        });
        res.push(cloth[0]);
      }
      return res;
    },
    outfitDate() {
      if (this.init) {
        return moment(this.outfit.date).format('YYYY/MM/DD');
      }
      return '';
    },
    temperatureString() {
      if (this.outfit.temperatureSection === -1) {
        return '';
      }
      const secs = [
        '-10 ~ 0 ℃',
        '1 ~ 10 ℃',
        '11 ~ 15 ℃',
        '16 ~ 20 ℃',
        '21 ~ 25 ℃',
        '26 ~ 30 ℃',
        '31 ~ 35 ℃',
        '36 ~ 40 ℃',
      ];
      return secs[this.outfit.temperatureSection];
    },
    modalTitle() {
      if (this.init) {
        return `${this.outfit.user.displayName} 穿搭貼文`;
      }
      return '讀取中';
    },
    infoCloth() {
      try {
        if (this.showingClothIndex === -1) {
          return null;
        }
        return this.outfit.outfitPhotos[this.showingClothIndex].cloth;
      } catch(e) {
        console.error(e);
      }
      return null;
    },
  },
  watch: {
  },
  methods: {
    ...mapActions(['appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
    navImgClicked(index) {
      this.$refs['outfitFlickity'].select( index );
    },
    editClicked() {
      this.$emit('edit');
    },
    backClicked() {
      this.$emit('back');
    },
    closeConfirmModal() {
      this.isConfirmModalShow = false;
    },
    deleteClicked() {
      this.isConfirmModalShow = true;
    },
    async deleteConfirm() {
      this.closeConfirmModal();
      this.appendComponentBusy(this.deleteBusyName);
      try {
        await this.$store.dispatch('api/deleteOutfitPromise', this.outfit.serial);
        this.$emit('deleted', this.outfit);
      } catch(err) {
        this.appendErrorMsg(err.toString());
      } finally {
        this.clearComponentBusy(this.deleteBusyName);
      }
    },
    clothInfoClicked(index) {
      this.showingClothIndex = index;
      this.isClothInfoModalShow = true;
    },
    closeClothInfoModal() {
      this.isClothInfoModalShow = false;
    },
  }
}
</script>

<style scoped>
  @font-face {
    font-family: Wweather;
    src: url('~@/assets/font/Writes_weather.ttf');
  }

  .weather-icons {
    font-family: Wweather;
  }

  .view-outfit {
  }

  .user-header {
    padding: 11px 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .user-header>img {
    display: block;
    border-radius: 50%;
    width: 38px;
    height: 38px;
  }

  .user-header>span {
    display: block;
    padding-left: 1rem;
  }

  .imgs {
    position: relative;
  }

  .square-outer {
    position: relative;
    width: 100%;
    padding-top: 100%;
  }

  .square-outer>img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .square-outer>svg {
    position: absolute;
    width: 1rem;
    height: 1rem;
    top: 4px;
    right: 4px;
  }

  .float-div {
    position: absolute;
    right: 1rem;
    bottom: 1rem;

  }

  .cloth-info-btn {
    border-radius: 50%;
    background-color: #fff;
    /* padding: 5px; */
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 4px 0 #0004;
  }

  .cloth-info-btn>img {
    display: block;
    width: 1rem;
  }

  .square-empty {
    width: 100%;
    height: 100%;
    /* border-radius: .5rem; */
    border: dashed 2px #EEEEEE;
  }

  /* cloths */
  .cloths {
    margin-top: 6px;
    width: 100%;
    overflow-x: auto;
    display: flex;
    flex-wrap: nowrap;
    margin-left: -3px;
    margin-right: -3px;
  }

  .cloth-div {
    position: relative;
    margin: 0 3px;
    flex: 0 0 23%;
    width: 23%;
  }

  .cloth-div.active {
    border: solid 2px var(--main-orange);
  }

  /* info */
  .info {
    padding: 1rem;
  }

  .info-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    flex-wrap: nowrap;
  }

  .info-row.v-center {
    align-items: center;
  }

  .info-col-title {
    flex: 1 1;
    font-size: 14px;
    padding-right: 1rem;
  }

  .info-col-title.sub {
    padding-left: 1rem;
  }

  .info-col-title>label {
    font-weight: 600;
    margin: 0;
  }

  .info-col-title>label.required::after {
    content: '*';
    color: var(--required-orange);
  }

  .info-col-content {
    flex: 0 0 75%;
    max-width: 75%;
    font-size: 12px;
  }

  .btn-add-tag {
    font-size: 12px;
    color: #fff;
    border-radius: 999px;
    padding: 3px 14px;
    margin-bottom: .25rem;
    margin-top: .25rem;
  }

  .btn-add-tag:focus {
    color: #fff;
  }

  .btn-add-tag-brand {
    background-color: var(--main-green);
  }

  .btn-add-tag-tag {
    background-color: var(--main-yellow);
  }

  .pills.brand-pill {
    --pill-color: var(--main-green);
  }

  .pills.tag-pill {
    --pill-color: var(--main-yellow);
  }

  .pills {
    position: relative;
    display: block;
    font-size: 12px;
    background-color: var(--pill-color);
    color: #fff;
    border-radius: 999px;
    padding: 3px 14px;
    /* border: 1px solid var(--pill-color); */
    margin-bottom: .25rem;
    margin-top: .25rem;
  }
</style>
