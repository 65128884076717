<template>
  <FullScreenModal :title="modalTitle" @back="backClicked">
    <div class="create-outfit">
      <section class="imgs">
        <CropImageButton @uploaded="outfitImgUploaded">
          <div class="square-outer">
            <div class="square-inner" v-if="!hasPhoto" style="background-color: #eeeeee;">
              <img :src="require('@/assets/icons/camera.png')"/>
              <p>新增穿搭照片</p>
            </div>

            <img :src="outfitPhoto" v-if="hasPhoto" style="background-color: #eeeeee; object-fit: cover;" />
          </div>
        </CropImageButton>
        <!-- <image-upload-button :upload-function="uploadFunction" :sizelimit="5" accept-type="image/*" @busy="uploadingImageBusy" @uploaded="outfitImgUploaded" @error="emitError">
          <div class="square-outer">
            <div class="square-inner" v-if="!hasPhoto" style="background-color: #eeeeee;">
              <img :src="require('@/assets/icons/camera.png')"/>
              <p>新增穿搭照片</p>
            </div>

            <img :src="outfitPhoto" v-if="hasPhoto" style="background-color: #eeeeee; object-fit: cover;" />
          </div>
        </image-upload-button> -->
      </section>

      <section class="cloths" v-if="hasPhoto">

        <div class="cloth-div" v-for="(cloth, index) in outfitCloths" :abc="index" :key="index">
          <div class="square-outer">
            <img :src="cloth.imgs[0]" style="background-color: #eeeeee;" />
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" @click.prevent="removeCloth(index)">
              <rect width="12" height="12" fill="none"/>
              <path d="M9 3L3 9" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M3 3L9 9" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>

        <div class="cloth-div" v-if="outfitCloths.length < 12">
          <div class="square-outer">
            <div class="square-inner" style="background-color: #eeeeee;" @click="showActionModal">
              <img :src="require('@/assets/icons/add_white.png')" style="width: 24px; height: 24px;"/>
              <p>新增單品</p>
            </div>
          </div>
        </div>

      </section>

      <section class="info">
        <div class="info-row v-center">
          <div class="info-col-title sub">
            <label class="required">日期:</label>
          </div>

          <div class="info-col-content">
            <div class="row">
              <div class="col-4 pr-1">
                <custom-select :optionObjects="yearOpt" v-model="outfitYear">
                </custom-select>
              </div>

              <div class="col-4 px-1">
                <custom-select :optionObjects="monthOpt" v-model="outfitMonth">
                </custom-select>
              </div>

              <div class="col-4 pl-1">
                <custom-select :optionObjects="dayOpt" v-model="outfitDay">
                </custom-select>
              </div>
            </div>
          </div>
        </div>

        <div class="info-row v-center">
          <div class="info-col-title sub">
            <label>場合:</label>
          </div>

          <div class="info-col-content">
            <custom-text-input placeholder="例: 母親節聚餐" v-model="outfit.situation"></custom-text-input>
          </div>
        </div>

        <div class="info-row v-center">
          <div class="info-col-title sub">
            <label>地點:</label>
          </div>

          <div class="info-col-content">
            <custom-text-input placeholder="例: 微風百貨" v-model="outfit.place"></custom-text-input>
          </div>
        </div>

        <div class="info-row v-center">
          <div class="info-col-title sub">
            <label class="">天氣:</label>
          </div>

          <div class="info-col-content">
            <div class="row">
              <div class="col-6 pr-1">
                <custom-select :optionObjects="weatherOpt" v-model="outfit.weather" class="weather-icons">
                </custom-select>
              </div>

              <div class="col-6 pl-1">
                <custom-select :optionObjects="temperatureOpt" v-model="outfit.temperatureSection">
                </custom-select>
              </div>
            </div>
          </div>
        </div>

        <div class="info-row">
          <textarea class="form-control" rows="3" placeholder="寫些什麼吧" v-model="outfit.desc">
          </textarea>
        </div>

        <hr/>

        <div class="info-row">
          <div class="info-col-title sub pt-1">
            <label>品牌:</label>
          </div>

          <div class="info-col-content">
            <div class="row no-gutters">
              <div class="col-auto pr-2" v-for="(item, index) in outfit.brands" :key="item">
                <span class="pills brand-pill">
                  <span>#{{item}}</span>
                  <a href="#" @click.prevent="removeBrand(index)">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 3L3 9" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M3 3L9 9" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </a>
                </span>
              </div>
              <div class="col-auto">
                <button class="btn btn-add-tag btn-add-tag-brand" @click="showBrandModal">#</button>
              </div>
            </div>
          </div>
        </div>

        <div class="info-row">
          <div class="info-col-title sub pt-1">
            <label>標籤:</label>
          </div>

          <div class="info-col-content">
            <div class="row no-gutters">
              <div class="col-auto pr-2" v-for="(item, index) in outfit.tags" :key="item">
                <span class="pills tag-pill">
                  <span>#{{item}}</span>
                  <a href="#" @click.prevent="removeTag(index)">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 3L3 9" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M3 3L9 9" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </a>
                </span>
              </div>
              <div class="col-auto">
                <button class="btn btn-add-tag btn-add-tag-tag" @click="showTagModal">#</button>
              </div>
            </div>
          </div>
        </div>

        <div class="info-row" v-if="outfitClothTags.length > 0">
          <div class="info-col-content">
            <label>你可能想加...</label>
            <div class="row no-gutters">
              <div class="col-auto pr-2" v-for="(item) in outfitClothTags" :key="item.text">
                <span @click="quickAddTag(item)" class="pills fill" :class="{'tag-pill': item.type === 'tag', 'brand-pill': item.type === 'brand'}">
                  <span>#{{item.text}}</span>
                </span>
              </div>
            </div>
          </div>
        </div>

        <hr/>

        <div class="info-row align-items-center">
          <div class="info-col-title">
            <label>公開分享</label>
            <p class="public-desc">
              所有追蹤的好友可以看到本次穿搭紀錄
            </p>
          </div>

          <div class="col-auto px-0">
            <WcSwitch v-model="outfit.isPublic" />
          </div>
        </div>

      </section>

      <section class="actions">
        <button class="horizon-btn" v-if="isCreate" @click="createBtnClicked">送出</button>
        <button class="horizon-btn" v-if="isEdit" @click="editBtnClicked">儲存修改</button>
      </section>

      <!-- <transition name="fade">
        <ClothPicker v-if="isClothPickerShow" @back="closeClothPicker" @ok="appendCloths" :except="outfitClothSerials" :limit="12 - outfitCloths.length"></ClothPicker>
      </transition> -->

      <transition name="fade">
        <TagModal v-if="isBrandModalShow" @ok="brandModalOk" @close="brandModalClose" :init-tags="outfit.brands"></TagModal>
      </transition>

      <transition name="fade">
        <TagModal v-if="isTagModalShow" @ok="tagModalOk" @close="tagModalClose" :init-tags="outfit.tags" tag-type="tag"></TagModal>
      </transition>

      <transition name="fade">
        <WcActionModal v-if="isActionModalShow" :actions="actions" @cancel="closeActionModal" @action="actionClicked"></WcActionModal>
      </transition>

      <transition name="fade">
        <WcCommonModal title="確定離開" v-if="isConfirmModalShow" @cancel="isConfirmModalShow = false" @ok="backConfirm" hide-header>
          <div class="text-center">
            未儲存資料<br/>
            要離開嗎？
          </div>
        </WcCommonModal>
      </transition>

      <transition name="fade">
        <QuicklyCreateCloth v-if="isQuicklyCreateShow" @back="closeQuicklyCreateModal" @created="receiveQuickCreatedCloths" :src-image="outfitPhoto" :limit="12 - outfitCloths.length"></QuicklyCreateCloth>
      </transition>

      <CollectFromCloset v-model="isClothPickerShow" :collectLimit="12 - outfitCloths.length" :collectedList="[]" @uploadCollectedItem="uploadCollectedItem"></CollectFromCloset>
      
    </div>
  </FullScreenModal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import TagModal from '@/components/commons/TagModal.vue';
import outfitDefault from '@/dataModel/outfit.js';
import FullScreenModal from '@/components/FullScreenModal.vue';
import WcCommonModal from '@/components/commons/WcCommonModal.vue';
import WcActionModal from '@/components/commons/WcActionModal.vue';
// import ClothPicker from '@/components/ClothPicker.vue';
import WcSwitch from '@/components/commons/WcSwitch.vue';
import QuicklyCreateCloth from '@/views/closet/QuicklyCreateCloth.vue';
import CropImageButton from '@/components/cropImage/CropImageButton.vue';
import CollectFromCloset from "@/components/CollectFromCloset.vue";

import '@/assets/css/buttons.css';

export default {
  name: 'CreateOutfit',
  data() {
    return {
      initBusyName: 'CREATEOUTFITINITREADING',
      uploadBusyName: 'CREATEOUTFITUPLOADINGIMAGE',
      saveBusyName: 'OUTFITSAVING',
      init: false,

      outfit: {},

      isClothPickerShow: false,
      isActionModalShow: false,
      isBrandModalShow: false,
      isTagModalShow: false,
      isConfirmModalShow: false,
      isQuicklyCreateShow: false,
    };
  },
  components: {
    TagModal,
    FullScreenModal,
    WcCommonModal,
    // ClothPicker,
    WcSwitch,
    WcActionModal,
    QuicklyCreateCloth,
    CropImageButton,
    CollectFromCloset,
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: -1,
    },
    createDefaultDate: {
      type: String,
      required: false,
      default: '',
    }
  },
  created() {
    this.outfit = Object.assign({}, this.outfit, outfitDefault());
    if (this.isCreate && this.createDefaultDate.length > 0) {
      this.outfit.date = moment(this.createDefaultDate).format('YYYY-MM-DD');
    }
  },
  beforeDestroy() {
    this.clearComponentBusy(this.initBusyName);
    this.clearComponentBusy(this.uploadBusyName);
    this.clearComponentBusy(this.saveBusyName);
  },
  async mounted() {
    this.appendComponentBusy(this.initBusyName);
    try {
      if (this.id !== -1) {
        const outfit = await this.$store.dispatch('api/readOutfitInfoPromise', this.id);
        outfit.outfitPhotos = outfit.outfitPhotos.map((o) => {
          if (o.type === 'photo') {
            return o;
          } else if (o.type === 'cloth') {
            return {
              type: o.type,
              clothSerial: o.clothSerial,
            }
          }
        });
        this.outfit = Object.assign({}, this.outfit, outfit);
      }
      this.init = true;
    } catch(e) {
      this.emitError(e);
    } finally {
      this.clearComponentBusy(this.initBusyName);
    }
  },
  computed: {
    ...mapState(['cloths']),
    noAvailableCloths() {
      const availableCloths = this.cloths.filter((c) => {
        return c.available && !c.isDeleted && c.recycleType === -1;
      });
      return availableCloths.length === 0;
    },
    actions() {
      return [
        {
          text: '從穿搭照中快速新增單品',
          value: 'crop',
        },
        {
          text: '從衣櫥中挑選單品',
          value: 'pick',
          disabled: this.noAvailableCloths,
        }
      ]
    },
    hasPhoto() {
      return this.outfit.outfitPhotos.length > 0;
    },
    outfitPhoto() {
      if (this.hasPhoto) {
        return this.outfit.outfitPhotos[0].imgUrl;
      }
      return '';
    },
    outfitCloths() {
      const res = [];
      for (const op of this.outfit.outfitPhotos) {
        if (op.type !== 'cloth') {
          continue;
        }
        const cloth = this.cloths.filter((c) => {
          return c.serial === op.clothSerial;
        });
        res.push(cloth[0]);
      }
      return res;
    },
    outfitClothSerials() {
      return this.outfitCloths.map((c) => {
        return c.serial;
      });
    },
    outfitClothTags() {
      const tags = [];
      const brands = [];
      const lengthLimit = 5;

      for (const cloth of this.outfitCloths) {
        for (const tag of cloth.tags) {
          if (this.outfit.tags.indexOf(tag) === -1){
            tags.push(tag);
          }
        }
        for (const brand of cloth.brands) {
          if (this.outfit.brands.indexOf(brand) === -1){
            brands.push(brand);
          }
        }
      }

      const parseTags = tags.reduce((ary, item) => {
        for (let i=0;i<ary.length;i++) {
          const temp = ary[i];
          if (temp.content === item) {
            temp.count += 1;
            return ary;
          }
        }
        ary.push({
          content: item,
          count: 1,
        });
        return ary;
      }, []);

      const parseBrands = brands.reduce((ary, item) => {
        for (let i=0;i<ary.length;i++) {
          const temp = ary[i];
          if (temp.content === item) {
            temp.count += 1;
            return ary;
          }
        }
        ary.push({
          content: item,
          count: 1,
        });
        return ary;
      }, []);

      const sortedTags = parseTags.sort((a, b) => {
        return b.count - a.count;
      });

      const sortedBrands = parseBrands.sort((a, b) => {
        return b.count - a.count;
      });

      const all = [];
      sortedTags.map((item, index) => {
        if (index < lengthLimit) {
          all.push({
            type: 'tag',
            text: item.content,
          });
        }
      });
      sortedBrands.map((item, index) => {
        if (index < lengthLimit) {
          all.push({
            type: 'brand',
            text: item.content,
          });
        }
      });
      return all;
    },
    outfitYear: {
      get() {
        return moment(this.outfit.date).year();
      },
      set(val) {
        const m = moment(this.outfit.date);
        m.year(val);
        this.outfit.date = m.format('YYYY-MM-DD');
      }
    },
    outfitMonth: {
      get() {
        return moment(this.outfit.date).month() + 1;
      },
      set(val) {
        const m = moment(this.outfit.date);
        m.month(val - 1);
        this.outfit.date = m.format('YYYY-MM-DD');
      }
    },
    outfitDay: {
      get() {
        return moment(this.outfit.date).date();
      },
      set(val) {
        const m = moment(this.outfit.date);
        m.date(val);
        this.outfit.date = m.format('YYYY-MM-DD');
      }
    },
    yearOpt() {
      const opt = [];
      const nowYear = new Date().getFullYear();
      for (let i = 1980 ; i <= nowYear ; i ++ ) {
        opt.push({
          value: i,
          name: i,
        });
      }
      return opt;
    },
    monthOpt() {
      const opt = [];
      for (let i = 1 ; i <= 12 ; i ++ ) {
        opt.push({
          value: i,
          name: `${i} 月`,
        });
      }
      return opt;
    },
    dayOpt() {
      const opt = [];
      for (let i = 1 ; i <= 31 ; i ++ ) {
        opt.push({
          value: i,
          name: `${i} 日`,
        });
      }
      return opt;
    },
    weatherOpt() {
      const weatherIds = [0, 5, 1, 2, 3, 4, 6, 7, 8, 9];
      const weatherStrs = ['晴天', '明月', '多雲', '晴時多雲', '雨', '雷雨', '強風', '寒流', '雪', '濃霧'];
      const opt = [];
      for (let i = 0 ; i < weatherIds.length ; i ++ ) {
        opt.push({
          value: weatherIds[i],
          name: `${weatherIds[i]} ${weatherStrs[i]}`,
        });
      }
      return opt;
    },
    temperatureOpt() {
      const opt = [{
        value: -1,
        name: '氣溫',
      }];
      const secs = [
        '-10 ~ 0 ℃',
        '1 ~ 10 ℃',
        '11 ~ 15 ℃',
        '16 ~ 20 ℃',
        '21 ~ 25 ℃',
        '26 ~ 30 ℃',
        '31 ~ 35 ℃',
        '36 ~ 40 ℃',
      ];
      for (let i = 0 ; i < secs.length ; i ++ ) {
        opt.push({
          value: i,
          name: secs[i],
        });
      }
      return opt;
    },
    isCreate() {
      return this.id === -1;
    },
    isEdit() {
      return this.id !== -1;
    },
    modalTitle() {
      if (this.isCreate) {
        return '新增穿搭貼文';
      }
      if (this.isEdit) {
        return '編輯穿搭貼文';
      }
      return '';
    },
  },
  watch: {
  },
  methods: {
    ...mapActions(['appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'updateCloth']),
    uploadFunction(file) {
      return this.$store.dispatch('api/postUploadOutfitImagePromise', file);
    },
    openClothPicker() {
      this.isClothPickerShow = true;
    },
    closeClothPicker() {
      this.isClothPickerShow = false;
    },
    appendCloths(clothSerials) {
      clothSerials.map((serial) => {
        this.outfit.outfitPhotos.push({
          type: 'cloth',
          clothSerial: serial,
        });
      });
      this.closeClothPicker();
    },
    uploadCollectedItem(clothSerials) {
      clothSerials.map((serial) => {
        const found = this.outfit.outfitPhotos.find((p) => {
          return p.type === 'cloth' && p.clothSerial === serial;
        });
        if (found === undefined || found === null) {
          this.outfit.outfitPhotos.push({
            type: 'cloth',
            clothSerial: serial,
          });
        }
      });
      // console.log(clothSerials);
    },
    removeCloth(index) {
      this.outfit.outfitPhotos.splice(index + 1, 1);
    },
    uploadingImageBusy(val) {
      if (val) {
        this.appendComponentBusy(this.uploadBusyName);
      } else {
        this.clearComponentBusy(this.uploadBusyName);
      }
    },
    outfitImgUploaded(data) {
      const {url} = data;
      if (this.hasPhoto) {
        this.outfit.outfitPhotos[0].imgUrl = url;
      } else {
        this.outfit.outfitPhotos.push({
          type: 'photo',
          imgUrl: url,
          tags: [],
        });
      }
    },
    emitError(err) {
      this.appendErrorMsg(err);
    },
    checkValidate(outfit) {
      if (outfit.outfitPhotos.length === 0) {
        return '請上傳穿搭照片';
      } else if (outfit.outfitPhotos.length === 1) {
        return '請至少選擇一件搭配中的衣物單品';
      }
      return '';
    },
    async createBtnClicked() {
      const insertData = this.outfit;
      const errMsg = this.checkValidate(insertData);
      if (errMsg.length > 0) {
        this.emitError(errMsg);
      } else {
        this.appendComponentBusy(this.saveBusyName);
        try {
          const res = await this.$store.dispatch('api/postCreateOutfitPromise', insertData);
          console.log(res);
          this.$emit('created', res);
        } catch(e) {
          this.emitError(e);
        } finally {
          this.clearComponentBusy(this.saveBusyName);
        }
      }
    },
    async editBtnClicked() {
      const insertData = this.outfit;
      const errMsg = this.checkValidate(insertData);
      if (errMsg.length > 0) {
        this.emitError(errMsg);
      } else {
        this.appendComponentBusy(this.saveBusyName);
        try {
          const res = await this.$store.dispatch('api/updateOutfitPromise', {
            id: this.id,
            outfit: insertData,
          });
          this.$emit('updated', res);
        } catch(e) {
          this.emitError(e);
        } finally {
          this.clearComponentBusy(this.saveBusyName);
        }
      }
    },
    showBrandModal() {
      this.isBrandModalShow = true;
    },
    showTagModal() {
      this.isTagModalShow = true;
    },
    brandModalOk(brandTags) {
      this.isBrandModalShow = false;
      this.outfit.brands.splice(0, this.outfit.brands.length);
      for (const brand of brandTags) {
        this.outfit.brands.push(brand);
      }
    },
    brandModalClose() {
      this.isBrandModalShow = false;
    },
    tagModalOk(tags) {
      this.isTagModalShow = false;
      this.outfit.tags.splice(0, this.outfit.tags.length);
      for (const tag of tags) {
        this.outfit.tags.push(tag);
      }
    },
    tagModalClose() {
      this.isTagModalShow = false;
    },
    removeBrand(index) {
      this.outfit.brands.splice(index, 1);
    },
    removeTag(index) {
      this.outfit.tags.splice(index, 1);
    },
    backClicked() {
      this.isConfirmModalShow = true;
    },
    backConfirm() {
      this.$emit('back');
    },
    quickAddTag(tag) {
      if (tag.type === 'tag') {
        this.outfit.tags.push(tag.text);
      } else if (tag.type === 'brand') {
        this.outfit.brands.push(tag.text);
      }
    },
    showActionModal() {
      this.isActionModalShow = true;
    },
    closeActionModal() {
      this.isActionModalShow = false;
    },
    openQuicklyCreateModal() {
      this.isQuicklyCreateShow = true;
    },
    closeQuicklyCreateModal() {
      this.isQuicklyCreateShow = false;
    },
    actionClicked(action) {
      switch(action) {
        case 'pick':
          this.openClothPicker();
          break;
        case 'crop':
          this.openQuicklyCreateModal();
          break
      }
      this.closeActionModal();
    },
    receiveQuickCreatedCloths(cloths) {
      for (const cloth of cloths) {
        this.outfit.outfitPhotos.push({
          type: 'cloth',
          clothSerial: cloth.serial,
        });
        this.updateCloth(cloth);
      }
      this.closeQuicklyCreateModal();
    },
  }
}
</script>

<style scoped>
  @font-face {
    font-family: Wweather;
    src: url('~@/assets/font/Writes_weather.ttf');
  }

  .weather-icons {
    font-family: Wweather;
  }

  .create-outfit {
  }

  .imgs {
    position: relative;
  }

  .square-outer {
    position: relative;
    width: 100%;
    padding-top: 100%;
  }

  .square-outer>img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .square-outer>svg {
    position: absolute;
    width: 1rem;
    height: 1rem;
    top: 4px;
    right: 4px;
  }

  .square-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .square-inner>img {
    width: 46px;
    height: 46px;
    object-fit: contain;
  }

  .square-inner>p {
    margin: 0;
    font-size: 12px;
    color: #fff;
  }

  .square-empty {
    width: 100%;
    height: 100%;
    /* border-radius: .5rem; */
    border: dashed 2px #EEEEEE;
  }

  /* cloths */
  .cloths {
    margin-top: 6px;
    display: flex;
    width: 100%;
    overflow-x: auto;
    margin-left: -3px;
    margin-right: -3px;
    /* display: grid;
    column-gap: 6px;
    grid-template-columns: auto auto auto auto; */
  }

  .cloth-div {
    position: relative;
    width: 20vw;
    flex: 0 0 20vw;
    padding: 0 3px;
  }

  /* info */
  .info {
    padding: 1rem;
  }

  .info-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    flex-wrap: nowrap;
  }

  .info-row.v-center {
    align-items: center;
  }

  .info-col-title {
    flex: 1 1;
    font-size: 14px;
    padding-right: 1rem;
  }

  .info-col-title.sub {
    padding-left: 1rem;
  }

  .info-col-title>label {
    font-weight: 600;
    margin: 0;
  }

  .info-col-title>label.required::after {
    content: '*';
    color: var(--required-orange);
  }

  .info-col-content {
    flex: 0 0 75%;
    max-width: 75%;
    font-size: 12px;
  }

  .btn-add-tag {
    font-size: 12px;
    color: #fff;
    border-radius: 999px;
    padding: 3px 14px;
    margin-bottom: .25rem;
    margin-top: .25rem;
  }

  .btn-add-tag:focus {
    color: #fff;
  }

  .btn-add-tag-brand {
    background-color: var(--main-green);
  }

  .btn-add-tag-tag {
    background-color: var(--main-yellow);
  }

  .pills.brand-pill {
    --pill-color: var(--main-green);
  }

  .pills.tag-pill {
    --pill-color: var(--main-yellow);
  }

  .pills {
    position: relative;
    display: block;
    font-size: 12px;
    color: var(--pill-color);
    border-radius: 999px;
    padding: 3px 32px 3px 14px;
    border: 1px solid var(--pill-color);
    margin-bottom: .25rem;
    margin-top: .25rem;
  }

  .pills.fill {
    background-color: var(--pill-color);
    color: #ffffff;
    padding: 3px 14px 3px 14px;
  }

  .pills>a {
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: #D9D9D9;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
