<template>
  <nav class="static-nav">
    <transition name="fade">
      <div class="add-panel" v-if="showAddPanel" @click="closeAddPanel">
      </div>
    </transition>

    <div class="add-panel-tools">
      <button class="add-tool-btn" :class="{'left-active': showAddPanel}" @click.stop="recordClothClicked">
        <img class="" :src="require('@/assets/nav_icons/add_cloth.png')" />
        <p>新增單品</p>
      </button>

      <button class="add-tool-btn" :class="{'right-active': showAddPanel}" @click.stop="recordOutfitClicked">
        <img class="" :src="require('@/assets/nav_icons/add_cloth_set.png')" />
        <p>新增穿搭</p>
      </button>

      <button class="add-tool-btn" :class="{'up-active': showAddPanel}" @click.stop="addByNumberClicked">
        <img class="" :src="require('@/assets/nav_icons/add_by_number.png')" />
        <p>商品編號</p>
      </button>
    </div>

    <ul>
      <li>
        <button class="nav-btn" :class="{'active': navIsHome}" @click.prevent="goToHome">
          <div class="nav-icon">
            <img class="normal-icon" :src="require('@/assets/nav_icons/home.png')" />
            <img class="active-icon" :src="require('@/assets/nav_icons/home_active.png')" />
          </div>
          <p class="nav-text">首頁</p>
        </button>
      </li>

      <li>
        <button class="nav-btn" :class="{'active': navIsCloset}" @click.prevent="goToCloset">
          <div class="nav-icon">
            <img class="normal-icon" :src="require('@/assets/nav_icons/closet.png')" />
            <img class="active-icon" :src="require('@/assets/nav_icons/closet_active.png')" />
          </div>
          <p class="nav-text">衣櫥</p>
        </button>
      </li>

      <li>
        <button class="nav-btn nav-btn-lg" :class="{'active': navIsAdd}" @click.prevent="addClicked">
          <div class="nav-icon">
            <img class="normal-icon" :src="require('@/assets/nav_icons/add.png')" />
            <img class="active-icon" :src="require('@/assets/nav_icons/add_active.png')" />
          </div>
        </button>
      </li>

      <li>
        <button class="nav-btn" :class="{'active': navIsShare}" @click.prevent="goToShare">
          <div class="nav-icon">
            <img class="normal-icon" :src="require('@/assets/nav_icons/share.png')" />
            <img class="active-icon" :src="require('@/assets/nav_icons/share_active.png')" />
          </div>
          <p class="nav-text">交流</p>
        </button>
      </li>

      <li>
        <button class="nav-btn" :class="{'active': navIsAnalyze}" @click.prevent="goToAnalyze">
          <div class="nav-icon">
            <img class="normal-icon" :src="require('@/assets/nav_icons/analyze.png')" />
            <img class="active-icon" :src="require('@/assets/nav_icons/analyze_active.png')" />
          </div>
          <p class="nav-text">數據</p>
        </button>
      </li>

      <li class="d-none">
        <button class="nav-btn" :class="{'active': navIsTopic}" @click.prevent="goToTopic">
          <div class="nav-icon">
            <img class="normal-icon" :src="require('@/assets/nav_icons/topic.png')" />
            <img class="active-icon" :src="require('@/assets/nav_icons/topic_active.png')" />
          </div>
          <p class="nav-text">閱讀</p>
        </button>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  name: 'StaticNav',
  data() {
    return {
      // state: 'Home',
      showAddPanel: false,
    };
  },
  props: {
  },
  components: {

  },
  mounted() {

  },
  watch: {
  },
  computed: {
    ...mapState(['cloths']),
    navIsAdd() {
      // return this.state === 'Add';
      return this.showAddPanel;
    },
    navIsHome() {
      // return this.state === 'Home';
      return !this.navIsAdd && (typeof this.$route.meta.navCategory !== 'undefined') && this.$route.meta.navCategory === 'home';
    },
    navIsCloset() {
      // return this.state === 'Closet';
      return !this.navIsAdd && (typeof this.$route.meta.navCategory !== 'undefined') && this.$route.meta.navCategory === 'closet';
    },
    navIsShare() {
      // return this.state === 'Share';
      return !this.navIsAdd && (typeof this.$route.meta.navCategory !== 'undefined') && this.$route.meta.navCategory === 'share';
    },
    navIsTopic() {
      // return this.state === 'Topic';
      return !this.navIsAdd && (typeof this.$route.meta.navCategory !== 'undefined') && this.$route.meta.navCategory === 'topic';
    },
    navIsAnalyze() {
      // return this.state === 'Topic';
      return !this.navIsAdd && (typeof this.$route.meta.navCategory !== 'undefined') && this.$route.meta.navCategory === 'analyze';
    },
  },
  methods: {
    ...mapActions(['showMsgModal']),
    recordClothClicked() {
      this.closeAddPanel();
      this.$emit('recordCloth');
      window.trackBtnClicked('btnNavNewCloth');
    },
    recordOutfitClicked() {
      this.closeAddPanel();
      this.$emit('recordOutfit');
      window.trackBtnClicked('btnNavNewOutfit');
    },
    addByNumberClicked() {
      this.closeAddPanel();
      this.$emit('addByNumber');
      window.trackBtnClicked('btnNavAddByNumber');
    },
    addClicked() {
      if (this.navIsAdd) {
        this.closeAddPanel();
      } else {
        this.openAddPanel();
      }
    },
    openAddPanel() {
      this.showAddPanel = true;
    },
    closeAddPanel() {
      this.showAddPanel = false;
    },
    goToHome() {
      // this.showMsgModal('功能尚在開發中<br/>再等我們一下下，就要開放囉！');
      this.__gotoName('Home');
    },
    goToCloset() {
      this.__gotoName('ClosetContentListAll');
    },
    goToShare() {
      this.__gotoName('Share');
    },
    goToTopic() {
      this.__gotoName('Topic');
    },
    goToAnalyze() {
      this.__gotoName('Analyze');
    },
    __gotoName(name) {
      // if (this.state !== name) {
      //   this.$router.push({name});
      //   this.state = name;
      // }
      this.closeAddPanel();
      if (this.$route.name !== name){
        this.$router.push({name});
      }
    }
  }
}
</script>

<style scoped>
  .static-nav {
    background-color: #fff;
    /* z-index: 1000; */
    /* border-top: solid 1px #8D8D8D; */
  }

  ul {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    list-style: none;
    position: relative;
    background-color: #fff;
    padding-bottom: 16px;
  }

  li {
    padding: 0;
    margin: 0;
    flex: 1 1;
  }

  .nav-btn {
    display: flex;
    width: 100%;
    border: none;
    background: #fff;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    padding: .5rem .2rem;
    color: #8D8D8D;
    /* font-size: 12px; */
  }

  .nav-btn.coomingsoon {
    opacity: .4;
  }

  .nav-btn:disabled {
    opacity: .4;
  }

  .nav-btn:hover {
    text-decoration: none;
  }

  .nav-icon{
    width: 24px;
    height: 24px;
  }

  .nav-btn.nav-btn-lg .nav-icon {
    width: 36px;
    height: 36px;
  }

  .nav-icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .nav-icon img.normal-icon {
    display: inline-block;
  }

  .nav-icon img.active-icon {
    display: none;
  }

  .nav-text {
    margin: 0;
    white-space: nowrap;
  }

  .nav-btn.active {
    color: var(--main-green);
  }

  .nav-btn.active .nav-icon img.normal-icon {
    display: none;
  }

  .nav-btn.active .nav-icon img.active-icon {
    display: inline-block;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .4s ease;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  .fade-enter-to, .fade-leave {
    opacity: 1;
  }

  /* add panel */
  .add-panel {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #000a;
  }

  .add-panel-tools {
    position: relative;
    height: 0;
    /* z-index: 3; */
  }

  .add-tool-btn {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
    transition: transform .4s ease, opacity .4s ease;
    opacity: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
    background-color: transparent;
    color: #C4C4C4;
  }

  .add-tool-btn:disabled {
    display: none;
  }

  .add-tool-btn>img {
    width: 34px;
    height: 34px;
    object-fit: contain;
  }

  .add-tool-btn>p {
    margin: 0;
    /* font-size: 12px; */
  }

  .add-tool-btn.left-active {
    transform: translate(-150%, -120%);
    opacity: 1;
  }

  .add-tool-btn.right-active {
    transform: translate(50%, -120%);
    opacity: 1;
  }

  .add-tool-btn.up-active {
    transform: translate(-50%, -200%);
    opacity: 1;
  }

</style>
