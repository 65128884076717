import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    updateCookieName: 'update20241015',
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    getUpdatePanelRead({state}) {
      const updateRead = Vue.$cookies.get(state.updateCookieName);
      console.log(updateRead);
      if (updateRead === null) {
        return true;
      } else {
        return false;
      }
    },
    setUpdatePanelRead({state}) {
      Vue.$cookies.set(state.updateCookieName, 'read', "365d");
    },
  }

}
