<template>
  <div class="image-adjust">
    <canvas ref="background-canvas" class="invisible-canvas"></canvas>
    <Cropper ref="cropper" :src="imgData" :class="{ 'twitter-cropper': true, 'cropping': canMove }"
      background-class="twitter-cropper__background" foreground-class="twitter-cropper__foreground"
      :image-restriction="imageRestrict" :stencil-size="stencilSize" :resizeImage="resizeImageSetting"
      :moveImage="moveImageSetting" :stencil-props="{
        lines: {},
        handlers: {},
        movable: false,
        scalable: false,
        aspectRatio: cropRatio,
        previewClass: (stencilPadding>0)?'twitter-cropper__stencil show-stencil':'twitter-cropper__stencil',
      }" :canvas="true" :default-size="defaultSize" :min-width="150"
      :min-height="150" @change="onChange($event)" @zoom="onZoom($event)"></Cropper>

  </div>
</template>

<script>
// @ is an alias to /src
import { Cropper } from 'vue-advanced-cropper'
// import 'vue-advanced-cropper/dist/style.css';
import 'vue-advanced-cropper/dist/style.css';
import 'vue-advanced-cropper/dist/theme.compact.css';
import 'context-filter-polyfill';

import { mapActions } from 'vuex';

export default {
  name: 'ImageAdjust',
  data() {
    return {
    };
  },
  components: {
    Cropper,
  },
  props: {
    src: {
      required: true,
    },
    brightness: {
      type: Number,
      default: 1,
      required: false,
    },
    canMove: {
      type: Boolean,
      default: false,
      required: false,
    },
    stencilPadding: {
      type: Number,
      default: 0,
      required: false,
    },
    restrict: {
      type: Boolean,
      default: false,
      required: false,
    },
    cropRatio: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    imageRestrict() {
      return this.restrict? 'stencil':'none';
    },
    imgData() {
      return this.src;
    },
    resizeImageSetting() {
      if (this.canMove) {
        return {
          touch: true,
          wheel: {
            ratio: 0.1
          },
          adjustStencil: false
        };
      } else {
        return {
          touch: false,
          wheel: {
            ratio: 0
          },
          adjustStencil: false
        }
      }
    },
    moveImageSetting() {
      if (this.canMove) {
        return {
          touch: true,
          mouse: true
        };
      } else {
        return {
          touch: false,
          mouse: false
        };
      }
    },
  },
  watch: {
    brightness(val) {
      if (this.$refs.cropper) {
        this.$refs.cropper.$el.style.setProperty("--bright-var", val);
      }
    }
  },
  async mounted() {
    this.$refs.cropper.$el.style.setProperty("--bright-var", 1);
    setTimeout(() => {
      this.$refs.cropper.refresh();
    }, 1000);
  },
  methods: {
    ...mapActions(['appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
    rotateRight() {
      const cropper = this.$refs.cropper;
      cropper.rotate(90);
    },
    rotateLeft() {
      const cropper = this.$refs.cropper;
      cropper.rotate(-90);
    },
    defaultSize({ visibleArea, imageSize, stencilRatio, sizeRestrictions }) {
      console.log(visibleArea);
      console.log(imageSize);
      console.log(stencilRatio);
      console.log(sizeRestrictions);
      if (this.restrict) {
        return {
          width: Math.min(imageSize.height, imageSize.width),
          height: Math.min(imageSize.height, imageSize.width),
        };
      }
      return {
        width: Math.max(imageSize.height, imageSize.width),
        height: Math.max(imageSize.height, imageSize.width),
      };
    },
    stencilSize({ boundaries }) {
      return {
        width: Math.min(boundaries.height, boundaries.width) - this.stencilPadding,
        height: Math.min(boundaries.height, boundaries.width) - this.stencilPadding,
      };
    },
    onChange(event) {
      console.log(`event: ${event}`);

      // const cropper = this.$refs.cropper;
      // if (cropper) {
      //   const { coordinates, imageSize } = cropper;
      //   if (
      //     imageSize.width / imageSize.height >
      //     coordinates.width / coordinates.height
      //   ) {
      //     // Determine the position of slider bullet
      //     // It's 0 if the stencil has the maximum size and it's 1 if the has the minimum size
      //     this.zoom =
      //       (cropper.imageSize.height - cropper.coordinates.height) /
      //       (cropper.imageSize.height - cropper.sizeRestrictions.minHeight);
      //   } else {
      //     this.zoom =
      //       (cropper.imageSize.width - cropper.coordinates.width) /
      //       (cropper.imageSize.width - cropper.sizeRestrictions.minWidth);
      //   }
      // }
    },
    onZoom(value) {
      console.log(`${value}`);
      const cropper = this.$refs.cropper;
      if (cropper) {
        console.log(`${cropper.imageSize.height}, ${cropper.imageSize.width}`);
        if (cropper.imageSize.height < cropper.imageSize.width) {
          const minHeight = cropper.sizeRestrictions.minHeight;
          const imageHeight = cropper.imageSize.height;
          // Determine the current absolute zoom and the new absolute zoom
          // to calculate the sought relative zoom value
          cropper.zoom(
            (imageHeight - this.zoom * (imageHeight - minHeight)) /
            (imageHeight - value * (imageHeight - minHeight))
          );
        } else {
          const minWidth = cropper.sizeRestrictions.minWidth;
          const imageWidth = cropper.imageSize.width;
          cropper.zoom(
            (imageWidth - this.zoom * (imageWidth - minWidth)) /
            (imageWidth - value * (imageWidth - minWidth))
          );
        }
      }
    },
    applyBrightnessPromise(dataUrl) {
      return new Promise((resolve, reject) => {
        try {
          
          const image = new Image();
          image.src = dataUrl;
    
          const canvas = this.$refs['background-canvas'];
          const ctx = canvas.getContext('2d');
          image.onload = () => {
            canvas.width = image.width;
            canvas.height = image.height;
    
            // apply css filters here
            ctx.filter = `brightness(${this.brightness})`;
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
    
            // turn canvas back into a base64 image URL
            resolve(canvas.toDataURL("image/png"));
          }
        } catch (error) {
          reject(error);
        }
        
      });
    },
    crop() {
      const { canvas } = this.$refs.cropper.getResult();
      return this.applyBrightnessPromise(canvas.toDataURL());
    }
  }
}
</script>

<style lang="scss" scoped>
.image-adjust {
  width: 100%;
  height: 100%;
  .square {
    position: relative;
    width: 100%;
    padding-top: 100%;

    .square-inner {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }
}

.invisible-canvas {
  position: absolute;
  transform: translate(-100%, -100%);
  opacity: 0;
}

::v-deep .vue-advanced-cropper {

  .twitter-cropper__background {
    background-color: #edf2f4;
  }

  .twitter-cropper__foreground {
    background-color: #edf2f4;
  }

  .twitter-cropper__stencil {
    border: solid 1px var(--main-orange);
    .show-stencil {

    }
  }
}

.twitter-cropper {
  height: 100%;

  &__background {
    background-color: #edf2f4;
  }

  &__foreground {
    background-color: #edf2f4;
  }

  // &__stencil {
  //   border: solid 5px rgb(29, 161, 242);
  // }

  &.cropping {
    position: relative;

    ::v-deep .twitter-cropper__stencil {

      &::before,
      &::after {
        opacity: 1;
      }
    }
    
  }

  ::v-deep img.vue-preview__image {
    filter: brightness(var(--bright-var));
  }
}

</style>
