<template>
  <FullScreenModal :title="modalTitle" @back="backClicked">
    <div class="create-cloth">
      <section class="imgs">
        <CropImageButton @uploaded="clothImgUploaded" can-remove-bg>
          <div class="square-outer">
            <div class="square-inner" v-if="!hasImg">
              <img :src="require('@/assets/icons/camera.png')"/>
              <p>新增照片</p>
            </div>

            <img :src="cloth.imgs[0]" v-if="hasImg" />
          </div>
        </CropImageButton>
      </section>

      <section>
        <div class="sub-imgs">
          <div class="sub-imgs-content">
            <CropImageButton v-show="!hasPhotoBack" @uploaded="photoBackUploaded" btn-class="sub-imgs-empty">
              <div>
                <img :src="require('@/assets/icons/add_white.png')"/>
                <p>單品背面照</p>
              </div>
            </CropImageButton>

            <div class="sub-img-photo" v-if="hasPhotoBack">
              <img :src="cloth.photoBack" />
              <span @click="removePhotoBack">
                <img :src="require('@/assets/icons/search-close.png')"/>
              </span>
            </div>
          </div>

          <div class="sub-imgs-content">
            <CropImageButton v-show="!hasPhotoBrand" @uploaded="photoBrandUploaded" btn-class="sub-imgs-empty">
              <div>
                <img :src="require('@/assets/icons/add_white.png')"/>
                <p>領口品牌標</p>
              </div>
            </CropImageButton>

            <div class="sub-img-photo" v-if="hasPhotoBrand">
              <img :src="cloth.photoBrand" />
              <span @click="removePhotoBrand">
                <img :src="require('@/assets/icons/search-close.png')"/>
              </span>
            </div>
          </div>

          <div class="sub-imgs-content">
            <CropImageButton v-show="!hasPhotoWash" @uploaded="photoWashUploaded" btn-class="sub-imgs-empty">
              <div>
                <img :src="require('@/assets/icons/add_white.png')"/>
                <p>洗標</p>
              </div>
            </CropImageButton>

            <div class="sub-img-photo" v-if="hasPhotoWash">
              <img :src="cloth.photoWash" />
              <span @click="removePhotoWash">
                <img :src="require('@/assets/icons/search-close.png')"/>
              </span>
            </div>

          </div>

          <div class="sub-imgs-content">
            <CropImageButton v-show="!hasMaterialPhoto" @uploaded="materialPhotoUploaded" btn-class="sub-imgs-empty">
              <div>
                <img :src="require('@/assets/icons/add_white.png')"/>
                <p>材質照片</p>
              </div>
            </CropImageButton>

            <div class="sub-img-photo" v-if="hasMaterialPhoto">
              <img :src="cloth.materialPhoto" />
              <span @click="removeMaterialPhoto">
                <img :src="require('@/assets/icons/search-close.png')"/>
              </span>
            </div>

          </div>

          <!-- <div class="sub-imgs-content">
            <div class="sub-imgs-empty no-use">

            </div>
          </div> -->
        </div>
      </section>

      <section class="info">
        <div class="info-row">
          <div class="info-col-title">
            <label>單品資訊</label>
          </div>
        </div>

        <div class="info-row v-center">
          <div class="info-col-title sub">
            <label class="required">類別:</label>
          </div>

          <div class="info-col-content">
            <div class="row">
              <div class="col-6 pr-1">
                <custom-select :optionObjects="category1Opt" :value="cloth.category1" @input="category1Changed">
                </custom-select>
              </div>

              <div class="col-6 pl-1">
                <custom-select :optionObjects="category2Opt" v-model="cloth.category2">
                </custom-select>
              </div>
            </div>
          </div>
        </div>

        <div class="info-row v-center">
          <div class="info-col-title sub">
            <label class="required">色系:</label>
          </div>

          <div class="info-col-content">
            <ColorSelect v-model="cloth.color"></ColorSelect>
          </div>
        </div>

        <hr/>

        <div class="info-row">
          <div class="info-col-title sub pt-1">
            <label>品牌:</label>
          </div>

          <div class="info-col-content">
            <div class="row no-gutters">
              <div class="col-auto pr-2" v-for="(item, index) in cloth.brands" :key="item">
                <span class="pills brand-pill">
                  <span>#{{item}}</span>
                  <a href="#" @click.prevent="removeBrand(index)">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 3L3 9" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M3 3L9 9" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </a>
                </span>
              </div>
              <div class="col-auto">
                <button class="btn btn-add-tag btn-add-tag-brand" @click="showBrandModal">#</button>
              </div>
            </div>
          </div>
        </div>

        <div class="info-row">
          <div class="info-col-title sub pt-1">
            <label>標籤:</label>
          </div>

          <div class="info-col-content">
            <div class="row no-gutters">
              <div class="col-auto pr-2" v-for="(item, index) in cloth.tags" :key="item">
                <span class="pills tag-pill">
                  <span>#{{item}}</span>
                  <a href="#" @click.prevent="removeTag(index)">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 3L3 9" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M3 3L9 9" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </a>
                </span>
              </div>
              <div class="col-auto">
                <button class="btn btn-add-tag btn-add-tag-tag" @click="showTagModal">#</button>
              </div>
            </div>
          </div>
        </div>

        <div class="info-row">
          <div class="info-col-title sub pt-1">
            <label>尺寸:</label>
          </div>

          <div class="info-col-content">
            <ClothSizeSelect v-model="cloth.size"></ClothSizeSelect>
          </div>
        </div>

        <div class="info-row v-center">
          <div class="info-col-title sub">
            <label>入手時間:</label>
          </div>

          <div class="info-col-content">
            <div class="row">
              <div class="col-6 pr-1">
                <custom-select :optionObjects="getYearOpt" v-model="cloth.getYear">
                </custom-select>
              </div>

              <div class="col-6 pl-1">
                <custom-select :optionObjects="getMonthOpt" v-model="cloth.getMonth">
                </custom-select>
              </div>
            </div>
          </div>
        </div>

        <div class="info-row v-center">
          <div class="info-col-title sub">
            <label>入手狀態:</label>
          </div>

          <div class="info-col-content">
            <custom-select :optionObjects="getStatusOpt" v-model="cloth.getStatus">
            </custom-select>
          </div>
        </div>

        <div class="info-row v-center">
          <div class="info-col-title sub">
            <label>入手價格:</label>
          </div>

          <div class="info-col-content">
            <div class="row align-items-center">
              <div class="col-auto">
                NTD
              </div>

              <div class="col">
                <custom-text-input v-model="inputClothPrice" placeholder="">
                </custom-text-input>
              </div>
            </div>
          </div>
        </div>

        <div class="info-row v-center">
          <div class="info-col-title sub">
            <label>主要材質:</label>
          </div>

          <div class="info-col-content">
            <custom-select :optionObjects="materialOpt" v-model="cloth.material">
            </custom-select>
          </div>
        </div>

        <div class="info-row v-center">
          <div class="info-col-title sub">
            <label>清洗規範:</label>
          </div>

          <div class="info-col-content">
            <custom-select :optionObjects="washRuleOpt" v-model="cloth.washRule">
            </custom-select>
          </div>
        </div>

        <div class="info-row">
          <div class="info-col-title sub">
            <label>清洗提醒:</label>
          </div>

          <div class="info-col-content">
            <div class="row align-items-center no-gutters w-100">
              <div class="col-auto">
                <input type="checkbox" style="margin-top: .5em;" id="washAlert" v-model="cloth.washAlert"/>
              </div>
              <div class="col-auto pl-2">
                <label for="washAlert" class="mb-0">開啟通知</label>
              </div>
            </div>

            <div class="w-100 mt-1" v-if="cloth.washAlert">
              <div class="row">
                <div class="col-6 pr-1">
                  <custom-select :optionObjects="washAlertMonthOpt" v-model="cloth.washAlertMonth">
                  </custom-select>
                </div>

                <div class="col-6 pl-1">
                  <custom-select :optionObjects="washAlertDayOpt" v-model="cloth.washAlertDay">
                  </custom-select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="info-row">
          <div class="info-col-title sub">
            <label>備註:</label>
          </div>

          <div class="info-col-content">
            <textarea class="form-control" rows="3" v-model="cloth.desc">
            </textarea>
          </div>
        </div>

        <hr/>

        <div class="info-row align-items-center">
          <div class="info-col-title">
            <label>公開分享</label>
            <p class="public-desc">
              所有追蹤的好友可以看到此單品
            </p>
          </div>

          <div class="col-auto px-0">
            <WcSwitch v-model="cloth.isPublic" />
          </div>
        </div>

      </section>

      <section class="actions">
        <button class="horizon-btn" v-if="cloth.serial === -1" @click="createBtnClicked">上傳</button>
        <button class="horizon-btn" v-if="cloth.serial !== -1" @click="editBtnClicked">儲存修改</button>
      </section>

      <transition name="fade">
        <TagModal v-if="isBrandModalShow" @ok="brandModalOk" @close="brandModalClose" :init-tags="cloth.brands"></TagModal>
      </transition>

      <transition name="fade">
        <TagModal v-if="isTagModalShow" @ok="tagModalOk" @close="tagModalClose" :init-tags="cloth.tags" tag-type="tag"></TagModal>
      </transition>

      <transition name="fade">
        <WcCommonModal title="確定離開" v-if="isConfirmModalShow" @cancel="isConfirmModalShow = false" @ok="backConfirm" hide-header>
          <div class="text-center">
            未儲存資料<br/>
            要離開嗎？
          </div>
        </WcCommonModal>
      </transition>
    </div>
  </FullScreenModal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ColorSelect from '@/components/closet/ColorSelect.vue';
import ClothSizeSelect from '@/components/ClothSizeSelect.vue';
import TagModal from '@/components/commons/TagModal.vue';
import clothDefault from '@/dataModel/cloth.js';
import FullScreenModal from '@/components/FullScreenModal.vue';
import WcCommonModal from '@/components/commons/WcCommonModal.vue';
import WcSwitch from '@/components/commons/WcSwitch.vue';
import CropImageButton from '@/components/cropImage/CropImageButton.vue';
import '@/assets/css/buttons.css';

export default {
  name: 'CreateCloth',
  data() {
    return {
      initBusyName: 'CREATECLOTHINITREADING',
      uploadBusyName: 'CREATECLOTHUPLOADINGIMAGE',
      saveClothBusyName: 'CREATECLOTHSAVING',
      init: false,

      inputClothPrice: '',

      cloth: {},

      isBrandModalShow: false,
      isTagModalShow: false,
      isConfirmModalShow: false,
    };
  },
  components: {
    ColorSelect,
    ClothSizeSelect,
    TagModal,
    FullScreenModal,
    WcCommonModal,
    WcSwitch,
    CropImageButton,
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: -1,
    }
  },
  created() {
    this.cloth = Object.assign({}, this.cloth, clothDefault());
  },
  beforeDestroy() {
    this.clearComponentBusy(this.initBusyName);
    this.clearComponentBusy(this.uploadBusyName);
    this.clearComponentBusy(this.saveClothBusyName);
  },
  async mounted() {
    this.appendComponentBusy(this.initBusyName);
    try {
      if (this.id !== -1) {
        const cloth = await this.$store.dispatch('api/readClothInfoPromise', this.id);
        this.cloth = Object.assign({}, this.cloth, cloth);
        if (this.cloth.price > -1) {
          this.inputClothPrice = '' + this.cloth.price;
        }
      } else {
        // console.log(clothDefault);
        // console.log(clothDefault());
        // this.cloth = Object.assign({}, this.cloth, clothDefault());
        // console.log(this.cloth);
      }
      this.init = true;
    } catch(e) {
      this.emitError(e);
    } finally {
      this.clearComponentBusy(this.initBusyName);
    }

  },
  computed: {
    ...mapState(['clothColors', 'clothCategories']),
    hasImg() {
      return this.cloth.imgs.length > 0;
    },
    category1Opt() {
      const opts = [{
        value: -1,
        name: '請選擇'
      }];
      this.clothCategories.map((item) => {
        opts.push({
          name: item.name,
          value: item.serial
        });
      });
      return opts;
    },
    category2Opt() {
      const opts = [{
        value: -1,
        name: '請選擇'
      }];
      const selectedC1 = this.clothCategories.filter((item) => {
        return item.serial === this.cloth.category1;
      });
      if (selectedC1.length > 0) {
        selectedC1[0].sub.map((item) => {
          opts.push({
            name: item.name,
            value: item.serial
          });
        });
      }
      return opts;
    },
    getYearOpt() {
      const opts = [{
        value: -1,
        name: '年'
      }];
      const thisYear = new Date().getFullYear();
      for (let i=0;i<50;i++) {
        opts.push({
          value: thisYear - i,
          name: `${thisYear - i}`
        });
      }
      return opts;
    },
    getMonthOpt() {
      const opts = [{
        value: -1,
        name: '月'
      }];
      const monthAry = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'];
      for (let i=0;i<12;i++) {
        opts.push({
          value: i + 1,
          name: monthAry[i]
        });
      }
      return opts;
    },
    getStatusOpt() {
      const opts = [{
        value: -1,
        name: '請選擇',
      }];

      opts.push({
        value: 0,
        name: '新品'
      });

      opts.push({
        value: 1,
        name: '二手'
      });
      return opts;
    },
    materialOpt() {
      return ['請選擇', '棉Cotton', '麻Linen', '絲Silk', '羊毛 Wool', '聚酯纖維Polyester', '尼龍Nylon', '嫘縈(人造絲)Rayon/Viscose', '彈性纖維Spandex', '環境友善(再生纖維等)', '其他'].map((item, index) => {
        return {
          value: index - 1,
          name: item,
        };
      });
    },
    washRuleOpt() {
      return ['請選擇', '手洗', '洗衣機-柔洗', '洗衣機-標準', '送洗', '送洗-乾洗'].map((item, index) => {
        return {
          value: index - 1,
          name: item,
        };
      });
    },
    washAlertMonthOpt() {
      const opts = [{
        value: -1,
        name: '時間'
      }];
      opts.push({
        value: 1,
        name: '每個月'
      });
      opts.push({
        value: 2,
        name: '每兩個月'
      });
      opts.push({
        value: 3,
        name: '每一季'
      });
      opts.push({
        value: 6,
        name: '每半年'
      });
      return opts;
    },
    washAlertDayOpt() {
      const opts = [{
        value: -1,
        name: '日'
      }];
      opts.push({
        value: 1,
        name: '1日'
      });
      opts.push({
        value: 15,
        name: '15日'
      });
      return opts;
    },
    isCreate() {
      return this.id === -1;
    },
    isEdit() {
      return this.id !== -1;
    },
    modalTitle() {
      if (this.isCreate) {
        return '新增單品';
      }
      if (this.isEdit) {
        return '編輯單品';
      }
      return '';
    },
    hasPhotoBack() {
      return this.cloth.photoBack.length > 0;
    },
    hasPhotoBrand() {
      return this.cloth.photoBrand.length > 0;
    },
    hasPhotoWash() {
      return this.cloth.photoWash.length > 0;
    },
    hasMaterialPhoto() {
      return this.cloth.materialPhoto.length > 0;
    },
  },
  watch: {
    inputClothPrice(val) {
      let i = parseInt(val);
      if (isNaN(i)) {
        this.cloth.price = -1;
      } else {
        this.cloth.price = i;
      }
    },
  },
  methods: {
    ...mapActions(['appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
    uploadFunction(file) {
      return this.$store.dispatch('api/postUploadImagePromise', file);
    },
    uploadSubImgFunction(file) {
      return this.$store.dispatch('api/postUploadSubImagePromise', file);
    },
    uploadingImageBusy(val) {
      if (val) {
        this.appendComponentBusy(this.uploadBusyName);
      } else {
        this.clearComponentBusy(this.uploadBusyName);
      }
    },
    category1Changed(val) {
      this.cloth.category2 = -1;
      this.cloth.category1 = val;
    },
    clothImgUploaded(data) {
      const {url} = data;
      if (this.cloth.imgs.length > 0) {
        this.cloth.imgs.splice(0, 1, url);
      } else {
        this.cloth.imgs.push(url);
      }
    },
    photoBackUploaded(data) {
      const {url} = data;
      this.cloth.photoBack = url;
    },
    photoBrandUploaded(data) {
      const {url} = data;
      this.cloth.photoBrand = url;
    },
    photoWashUploaded(data) {
      const {url} = data;
      this.cloth.photoWash = url;
    },
    materialPhotoUploaded(data) {
      const {url} = data;
      this.cloth.materialPhoto = url;
    },
    removePhotoBack() {
      this.cloth.photoBack = '';
    },
    removePhotoBrand() {
      this.cloth.photoBrand = '';
    },
    removePhotoWash() {
      this.cloth.photoWash = '';
    },
    removeMaterialPhoto() {
      this.cloth.materialPhoto = '';
    },
    emitError(err) {
      this.appendErrorMsg(err);
    },
    checkClothValidate(cloth) {
      if (cloth.imgs.length === 0) {
        return '缺少衣物圖片';
      } else if (cloth.category1 === -1) {
        return '請選擇衣物大分類';
      } else if (cloth.category2 === -1) {
        return '請選擇衣物小分類';
      }
      return '';
    },
    async createBtnClicked() {
      const insertData = this.cloth;
      const errMsg = this.checkClothValidate(insertData);
      if (errMsg.length > 0) {
        this.emitError(errMsg);
      } else {
        this.appendComponentBusy(this.saveClothBusyName);
        try {
          const res = await this.$store.dispatch('api/postCreateClothPromise', insertData);
          this.$emit('created', res);
        } catch(e) {
          this.emitError(e);
        } finally {
          this.clearComponentBusy(this.saveClothBusyName);
        }
      }
    },
    async editBtnClicked() {
      const insertData = this.cloth;
      const errMsg = this.checkClothValidate(insertData);
      if (errMsg.length > 0) {
        this.emitError(errMsg);
      } else {
        this.appendComponentBusy(this.saveClothBusyName);
        try {
          const res = await this.$store.dispatch('api/updateClothInfoPromise', {
            id: this.id,
            cloth: insertData,
          });
          this.$emit('updated', res);
        } catch(e) {
          this.emitError(e);
        } finally {
          this.clearComponentBusy(this.saveClothBusyName);
        }
      }
    },
    showBrandModal() {
      this.isBrandModalShow = true;
    },
    showTagModal() {
      this.isTagModalShow = true;
    },
    brandModalOk(brandTags) {
      this.isBrandModalShow = false;
      this.cloth.brands.splice(0, this.cloth.brands.length);
      for (const brand of brandTags) {
        this.cloth.brands.push(brand);
      }
    },
    brandModalClose() {
      this.isBrandModalShow = false;
    },
    tagModalOk(tags) {
      this.isTagModalShow = false;
      this.cloth.tags.splice(0, this.cloth.tags.length);
      for (const tag of tags) {
        this.cloth.tags.push(tag);
      }
    },
    tagModalClose() {
      this.isTagModalShow = false;
    },
    removeBrand(index) {
      this.cloth.brands.splice(index, 1);
    },
    removeTag(index) {
      this.cloth.tags.splice(index, 1);
    },
    backClicked() {
      this.isConfirmModalShow = true;
    },
    backConfirm() {
      this.$emit('back');
    },
  }
}
</script>

<style lang="scss" scoped>
  .create-cloth {
  }

  .imgs {
    position: relative;
  }

  .square-outer {
    position: relative;
    width: 100%;
    background-color: #eeeeee;
    padding-top: 100%;
  }

  .square-outer>img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .square-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .square-inner>img {
    width: 46px;
    height: 46px;
    object-fit: contain;
  }

  .square-inner>p {
    margin: 0;
    font-size: 12px;
    color: #fff;
  }

  /* info */
  .info {
    padding: 1rem;
  }

  .info-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    flex-wrap: nowrap;
  }

  .info-row.v-center {
    align-items: center;
  }

  .info-col-title {
    flex: 1 1;
    font-size: 14px;
    padding-right: 1rem;
  }

  .info-col-title.sub {
    padding-left: 1rem;
  }

  .info-col-title>label {
    font-weight: 600;
    margin: 0;
  }

  .info-col-title>label.required::after {
    content: '*';
    color: var(--required-orange);
  }

  .info-col-content {
    flex: 0 0 70%;
    max-width: 70%;
    font-size: 12px;
  }

  .btn-add-tag {
    font-size: 12px;
    color: #fff;
    border-radius: 999px;
    padding: 3px 14px;
    margin-bottom: .25rem;
    margin-top: .25rem;
  }

  .btn-add-tag:focus {
    color: #fff;
  }

  .btn-add-tag-brand {
    background-color: var(--main-green);
  }

  .btn-add-tag-tag {
    background-color: var(--main-yellow);
  }

  .pills.brand-pill {
    --pill-color: var(--main-green);
  }

  .pills.tag-pill {
    --pill-color: var(--main-yellow);
  }

  .pills {
    position: relative;
    display: block;
    font-size: 12px;
    color: var(--pill-color);
    border-radius: 999px;
    padding: 3px 32px 3px 14px;
    border: 1px solid var(--pill-color);
    margin-bottom: .25rem;
    margin-top: .25rem;
  }

  .pills>a {
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: #D9D9D9;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .public-desc {
    color: var(--text-light);
    font-size: 12px;
  }

  .sub-imgs {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 4px;
    padding: 4px 0;
  }

  .sub-imgs-content {
    position: relative;
  }

  .sub-img-photo {
    display: block;
    position: relative;
    padding-top: 100%;
    background-color: #ddd;
  }

  .sub-img-photo>img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: contain;
  }

  .sub-img-photo>span {
    position: absolute;
    display: flex;
    top: .25rem;
    right: .25rem;
    width: 1rem;
    height: 1rem;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 50%;
  }

  .sub-img-photo>span>img {
    width: .8rem;
  }

  ::v-deep .sub-imgs-empty {
    display: block;
    position: relative;
    padding-top: 100%;
    background-color: #eeeeee;
  }

  .sub-imgs-empty.no-use {
    background-color: transparent;
    border: dashed 2px #eeeeee;
  }

  .sub-imgs-empty>div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
  }

  .sub-imgs-empty>div>img {
    display: block;
    width: 24px;
  }

  .sub-imgs-empty>div>p {
    margin: 0;
    font-size: .8rem;
    font-weight: 1000;
  }
</style>
