<template>
  <WcCommonModal title="ID設定" class="green-head" @close="closeModal" @cancel="closeModal" @ok="okClicked" :disable-ok="okBtnDisabled">
    <div class="">
      <p class="desc mb-1">設定專屬 ID 讓朋友也找得到你吧！</p>
      <p class="desc text-orange">！ID設定後不可修改</p>
      <div class="row mb-2 no-gutters align-items-center">
        <div class="col-auto pr-3">
          <span class="">ID<span class="text-orange">*</span></span>
        </div>

        <div class="col">
          <input class="id-input" v-model="inputId" maxlength="12"/>
        </div>

        <div class="col-auto pl-2">
          <img :src="inputStatusImg" class="check-icon" />
        </div>
      </div>
      <p class="input-desc"><small>6~12個字，限英文與數字且不得與他人重複</small></p>
    </div>
  </WcCommonModal>
</template>


<script>
import WcCommonModal from '@/components/commons/WcCommonModal.vue';
import { mapActions } from 'vuex';
import _ from 'lodash';

export default {
  name: 'SetPublicIdModal',
  data() {
    return {
      savingBusyName: 'SETPUBLICIDMODALSAVING',
      inputId: '',
      inputStatus: 0,
      inputStatusImgs: [
        require('@/assets/icons/id_checking.png'),
        require('@/assets/icons/id_passed.png'),
        require('@/assets/icons/id_invalid.png')
      ]
    };
  },
  created() {
  },
  props: {

  },
  components: {
    WcCommonModal
  },
  computed: {
    trimed() {
      return this.inputId.trim();
    },
    isEmpty() {
      return this.trimed.length === 0;
    },
    inputStatusImg() {
      return this.inputStatusImgs[this.inputStatus];
    },
    okBtnDisabled() {
      return this.inputStatus !== 1;
    },
	},
  beforeDestroy() {
    this.clearComponentBusy(this.savingBusyName);
  },
  watch: {
    inputId(val) {
      this.inputStatus = 0;
      this.searchId(val);
    },
  },
  mounted() {
	},
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
    closeModal() {
      this.$emit('close');
    },
    async okClicked() {
      this.appendComponentBusy(this.savingBusyName);
      try {
        const res = await this.$store.dispatch('api/postUpdateUserPublicIcPromise', this.inputId);
        this.$emit('updated', res.publicId);
      } catch(e) {
        this.showMsgModal(e);
      } finally {
        this.clearComponentBusy(this.savingBusyName);
      }
    },
    searchId: _.debounce(async function (id) {
      const regex = /^[A-Za-z][A-Za-z0-9]*$/;
      if (regex.test(id) && id.trim().length > 5 && id.trim().length < 13) {
        const res = await this.$store.dispatch('api/postSearchUserPublicIdPromise', {
          publicId: id,
          precise: true,
        });
        if (this.inputId === id) {
          if (res.list.length === 0 && id.trim().length > 0) {
            this.inputStatus = 1;
          } else {
            this.inputStatus = 2;
          }
        }
      } else {
        this.inputStatus = 2;
      }
    }, 500),
  }
}
</script>

<style scoped>
  .green-head >>> .header {
    background-color: var(--main-green);
  }

  .desc {
    font-size: 12px;
    text-align: center;
  }

  .text-orange {
    color: var(--main-orange);
  }

  .id-input {
    border: none;
    background-color: #EEEEEE;
    width: 100%;
  }

  .check-icon {
    display: block;
    width: 22px;
    height: 22px;
  }

  .input-desc {
    text-align: center;
    margin: 0;
    color: #aaa;
  }
</style>
