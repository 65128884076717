<template>
  <transition name="fade">
    <FullScreenModal v-if="isMemberConfigShow" title="會員帳號設定" @back="configCancel">
      <div class="member-config">
        <ul class="config m-0">
          <li class="sect">
            <span class="required">*</span>務必填寫
          </li>
        </ul>

        <div class="config-form">

          <div class="config-row">
            <CropImageButton @uploaded="userProfileImgUploaded" mask="circle">
              <div class="profile-img">
                <img :src="user.picUrl"/>
              </div>
            </CropImageButton>
          </div>

          <div class="config-row">
            <div class="row align-items-center no-gutters">
              <div class="col-auto">
                <label class="mr-3">姓名<span class="required">*</span></label>
              </div>
              <div class="col-auto">
                <custom-text-input type="text" v-model="user.displayName" />
              </div>
            </div>
          </div>

          <div class="config-row">
            <label class="mb-3">出生年月日<span class="required">*</span></label>
            <div class="row align-items-center no-gutters">
              <div class="col-4 pr-2">
                <custom-select :optionObjects="birthdayYearOpt" v-model="user.birthdayYear">
                </custom-select>
              </div>

              <div class="col-4">
                <custom-select :optionObjects="birthdayMonthOpt" v-model="user.birthdayMonth">
                </custom-select>
              </div>

              <div class="col-4 pl-2">
                <custom-select :optionObjects="birthdayDayOpt" v-model="user.birthdayDay">
                </custom-select>
              </div>
            </div>
          </div>

          <div class="config-row">
            <div class="row align-items-center no-gutters">
              <div class="col-auto">
                <label class="mr-3">性別<span class="required">*</span></label>
              </div>
              <div class="col-auto">
                <custom-select :optionObjects="genderOpt" v-model="user.gender">
                </custom-select>
              </div>
            </div>
          </div>

          <div class="config-row">
            <div class="row align-items-center no-gutters">
              <div class="col-auto">
                <label class="mr-3">希望推薦</label>
              </div>
              <div class="col-auto">
                <custom-select :optionObjects="susumeOpt" v-model="user.susume">
                </custom-select>
              </div>
            </div>
          </div>

          <div class="config-row">
            <div class="row align-items-center no-gutters">
              <div class="col-auto">
                <label class="mb-3">電子郵件(不可修改)</label>
              </div>
              <div class="col-12">
                <custom-text-input type="text" :value="user.email" disabled />
              </div>
            </div>
          </div>

          <div class="config-row">
            <div class="row align-items-center no-gutters">
              <div class="col-auto">
                <label class="mb-3">連絡電話</label>
              </div>
              <div class="col-12">
                <custom-text-input type="text" v-model="user.phone"/>
              </div>
            </div>
          </div>
        </div>

        <ul class="config">
          <li class="sect">
            變更密碼
          </li>
        </ul>

        <div class="config-form">
          <div class="config-row">
            <div class="row align-items-center no-gutters">
              <div class="col-auto">
                <label class="mb-2">新密碼</label>
              </div>
              <div class="col-12 mb-3">
                <custom-text-input type="text" v-model="inputNewPassword" placeholder="請輸入6~20英數" secret/>
              </div>
              <div class="col-auto">
                <label class="mb-2">確認新密碼</label>
              </div>
              <div class="col-12">
                <custom-text-input type="text" v-model="inputNewPasswordAgain" placeholder="請輸入6~20英數" secret/>
              </div>
            </div>
          </div>

          <div class="config-row">
            <div class="row align-items-center justify-content-end no-gutters">
              <button class="btn btn-dark btn-sm" @click="resetPasswordClicked">確認變更密碼</button>
            </div>
          </div>
        </div>

        <ul class="config">
          <li class="sect">
            訂閱
          </li>
        </ul>

        <div class="config-form">
          <div class="config-row">
            <div class="row align-items-center justify-content-between no-gutters">
              <div class="col-auto">
                <label class="mr-3">電子報EDM<span class="required">*</span></label>
              </div>
              <div class="col-auto">
                <WcSwitch v-model="user.subscription" />
              </div>
            </div>
          </div>
        </div>

        <button class="horizon-btn mt-3" @click="saveClicked">儲存</button>

      </div>
    </FullScreenModal>
  </transition>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import '@/assets/css/config-ul.css';
import '@/assets/css/buttons.css';
import '@/assets/css/form-inputs.css';
import FullScreenModal from '@/components/FullScreenModal.vue';
import WcSwitch from '@/components/commons/WcSwitch.vue';
import CropImageButton from '@/components/cropImage/CropImageButton.vue';

import moment from 'moment';

export default {
  name: 'MemberConfig',
  data() {
    return {
      readingBusyName: 'READINGUSERDATA',
      savingBusyName: 'UPDATINGUSERDATA',
      isLogoutModalShow: false,

      genderOpt: [
        {
          value: 0,
          name: '未設定',
        },
        {
          value: 1,
          name: '生理女',
        },
        {
          value: 2,
          name: '生理男',
        },
      ],

      susumeOpt: [
        {
          value: 0,
          name: '未設定',
        },
        {
          value: 1,
          name: '女裝',
        },
        {
          value: 2,
          name: '男裝',
        },
        {
          value: 3,
          name: '全部',
        },
      ],

      inputNewPassword: '',
      inputNewPasswordAgain: '',

      user: {
        picUrl: '',
        displayName: '',
        birthdayYear: -1,
        birthdayMonth: -1,
        birthdayDay: -1,
        gender: 0,
        susume: 0,
        email: '',
        phone: '',
        subscription: true,
      }
    };
  },
  components: {
    FullScreenModal,
    WcSwitch,
    CropImageButton,
  },
  props: {
  },
  beforeDestroy() {
    this.clearComponentBusy(this.readingBusyName);
    this.clearComponentBusy(this.savingBusyName);
  },
  async mounted() {

  },
  computed: {
    ...mapGetters(['isMemberConfigShow']),
    birthdayYearOpt() {
      const opt = [];
      const nowYear = new Date().getFullYear();
      for (let i = 1980 ; i <= nowYear ; i ++ ) {
        opt.push({
          value: i,
          name: i,
        });
      }
      return opt;
    },
    birthdayMonthOpt() {
      const opt = [];
      for (let i = 1 ; i <= 12 ; i ++ ) {
        opt.push({
          value: i,
          name: i,
        });
      }
      return opt;
    },
    birthdayDayOpt() {
      const opt = [];
      for (let i = 1 ; i <= 31 ; i ++ ) {
        opt.push({
          value: i,
          name: i,
        });
      }
      return opt;
    },
  },
  watch: {
    isMemberConfigShow(val) {
      if (val) {
        this.__refresh();
      }
    },
  },
  methods: {
    ...mapActions(['showMsgModal', 'setMemberConfigShow', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
    async __refresh() {
      this.appendComponentBusy(this.readingBusyName);
      try {
        const user = await this.$store.dispatch('api/getUserDataPromise');
        console.log(user);
        this.user.picUrl = user.picUrl;
        this.user.displayName = user.displayName;
        this.user.gender = user.gender;
        this.user.susume = user.susume;
        this.user.email = user.email;
        this.user.phone = user.phone;
        this.user.subscription = user.subscription;

        if (user.birthday !== null) {
          const m = moment(user.birthday);
          this.user.birthdayYear = m.year(),
          this.user.birthdayMonth = m.month() + 1;
          this.user.birthdayDay = m.date();
        }
      } catch(err) {
        this.appendErrorMsg(err.toString());
      } finally {
        this.clearComponentBusy(this.readingBusyName);
      }
    },
    configCancel() {
      this.setMemberConfigShow(false);
    },
    async saveClicked() {
      const user = {...this.user};
      if (user.displayName.trim().length === 0) {
        this.showMsgModal('請輸入姓名');
        return;
      }
      if (user.birthdayDay === -1 || user.birthdayMonth === -1 || user.birthdayYear === -1) {
        this.showMsgModal('請輸入生日');
        return;
      }
      if (user.gender === 0) {
        this.showMsgModal('請輸入性別');
        return;
      }
      const birthday = moment().date(this.user.birthdayDay).month(this.user.birthdayMonth - 1).year(this.user.birthdayYear);
      user.birthday = birthday.format('YYYY-MM-DD');

      this.appendComponentBusy(this.savingBusyName);
      try {
        await this.$store.dispatch('api/postUpdateUserDataPromise', user);
        this.setMemberConfigShow(false);
      } catch(err) {
        this.appendErrorMsg(err.toString());
      } finally {
        this.clearComponentBusy(this.savingBusyName);
      }
    },
    async resetPasswordClicked() {
      if (this.inputNewPassword.length < 6 || this.inputNewPassword.length > 20) {
        this.showMsgModal('新密碼長度需介於6~20個文字之間');
        return;
      }
      if (!(/^[A-Za-z0-9]*$/.test(this.inputNewPassword))) {
        this.showMsgModal('新密碼僅限使用英文與數字');
        return;
      }
      if (this.inputNewPassword !== this.inputNewPasswordAgain) {
        this.showMsgModal('確認新密碼與設定的新密碼不相同');
        return;
      }
      this.appendComponentBusy(this.savingBusyName);
      try {
        await this.$store.dispatch('api/postResetPasswordPromise', this.inputNewPassword);
        this.showMsgModal('密碼變更成功!');
        this.inputNewPassword = '';
        this.inputNewPasswordAgain = '';
      } catch(err) {
        this.appendErrorMsg(err.toString());
      } finally {
        this.clearComponentBusy(this.savingBusyName);
      }
    },
    userProfileImgUploaded(data) {
      const {url} = data;
      this.user.picUrl = url;
    },
  }
}
</script>

<style lang="scss" scoped>
  .member-config {

  }

  .config-form {
    padding: 0 30px;
    font-size: 14px;
  }

  .config-row {
    padding: 1rem 0;
  }

  .config-row:not(:last-child) {
    border-bottom: solid 1px #EEEEEE;
  }

  .config-form label {
    margin: 0;
  }

  .profile-img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    >img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
</style>
