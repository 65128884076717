export default {
  namespaced: true,
  state: {
    outfitManagerShow: false,
    outfitId: -1,
    outfitType: 'view',
    outfitEditStep: -1,
    outfitImgUrl: '',
  },
  getters: {
    isOutfitManagerShow(state) {
      return state.outfitManagerShow;
    },
    outfitId(state) {
      return state.outfitId;
    },
    outfitType(state) {
      return state.outfitType;
    },
    outfitEditStep(state) {
      return state.outfitEditStep;
    },
    outfitImgUrl(state) {
      return state.outfitImgUrl;
    }
  },
  mutations: {
    setOutfitManagerShow(state, data) {
      state.outfitManagerShow = data;
    },
    setOutfitId(state, data) {
      state.outfitId = data;
    },
    setOutfitType(state, data) {
      state.outfitType = data;
    },
    setOutfitEditStep(state, data) {
      state.outfitEditStep = data;
    },
    setOutfitImgUrl(state, data) {
      state.outfitImgUrl = data;
    }
  },
  actions: {
    setOutfitId({ commit }, serial) {
      commit('setOutfitId', serial);
    },
    setOutfitType({ commit }, type) {
      commit('setOutfitType', type);
    },
    setOutfitEditStep({ commit }, step) {
      commit('setOutfitEditStep', step);
    },
    setOutfitImgUrl({ commit }, imgUrl) {
      commit('setOutfitImgUrl', imgUrl);
    },
    setOutfitData({ commit }, { outfitId, outfitType, editStep, outfitImgUrl }) {
      if (outfitId !== undefined) {
        commit('setOutfitId', outfitId);
      }
      if (outfitType !== undefined) {
        commit('setOutfitType', outfitType);
      }
      if (editStep !== undefined) {
        commit('setOutfitEditStep', editStep);
      }
      if (outfitType !== undefined) {
        commit('setOutfitImgUrl', outfitImgUrl);
      }
    },
    clearOutfitData({ commit }) {
      commit('setOutfitId', -1);
      commit('setOutfitType', 'view');
      commit('setOutfitEditStep', -1);
      commit('setOutfitImgUrl', '');
    },
    openOutfitManager({ commit }) {
      commit('setOutfitManagerShow', true);  // 打開，設為 true
    },
    closeOutfitManager({ commit }) {
      commit('setOutfitManagerShow', false); // 關閉，設為 false
    },
  },
}
