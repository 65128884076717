<template>
  <div class="brand-size-container">
    <BrandSizeTag v-for="(item) in tags" :key="item.serial" :brand-size="item" @deleted="tagDeleted" :hide-delete="hideDelete"></BrandSizeTag>

    <BrandSizeTag style="opacity: .4;" v-if="tags.length === 0"  :brand-size="fakeSizeTag" hide-delete></BrandSizeTag>

    <div v-if="showAdd" @click="addClicked">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 11C3 7.22876 3 5.34315 4.17157 4.17157C5.34315 3 7.22876 3 11 3H13C16.7712 3 18.6569 3 19.8284 4.17157C21 5.34315 21 7.22876 21 11V13C21 16.7712 21 18.6569 19.8284 19.8284C18.6569 21 16.7712 21 13 21H11C7.22876 21 5.34315 21 4.17157 19.8284C3 18.6569 3 16.7712 3 13V11Z" stroke="#C4C4C4" stroke-width="2"/>
        <path d="M12 8L12 16" stroke="#C4C4C4" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
        <path d="M16 12L8 12" stroke="#C4C4C4" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
      </svg>
    </div>

  </div>
</template>

<script>
import BrandSizeTag from '@/components/body/BrandSizeTag.vue';

export default {
  name: 'brand-size-container',
  data() {
    return {
      fakeSizeTag: {
        brandName: '品牌名',
        sizeType: '上衣',
        size: 'M',
      }
    }
  },
  components: {
    BrandSizeTag,
  },
  created() {
  },
  computed: {
    tags: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  mounted() {
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    hideDelete: {
      type: Boolean,
      required: false,
      default: false,
    },
    showAdd: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
  },
  methods: {
    parseInput(ary) {
      this.tags.splice(0, this.tags.length);
      for (const t of ary) {
        this.tags.push(t);
      }
    },
    tagDeleted(tag) {
      for (let i=0;i<this.tags.length;i++) {
        if (this.tags[i].serial === tag.serial) {
          this.tags.splice(i, 1);
          return;
        }
      }
    },
    addClicked() {
      this.$emit('addClick');
    }
  }

}
</script>

<style scoped>
  .brand-size-container {
    background-color: #EEEEEE;
    border-radius: .5rem;
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    min-height: 100px;
    align-items: flex-start;
  }

  .brand-size-container>svg {
    margin-top: 0px;
  }
</style>
