<template>
  <div id="app">
    <MainApp></MainApp>
  </div>
</template>

<script>

import MainApp from '@/MainApp.vue'

export default {
  name: 'App',
  data() {
    return {
    }
  },
  components: {
    MainApp
  },
  mounted() {

  },
  computed: {

  },
  methods: {

  }
}
</script>

<style lang="scss">
  #app {
    background-color: #ddd;
  }
</style>
