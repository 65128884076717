<template>
  <WcCommonModal title="單品資訊" @cancel="closeModal" @ok="okClicked" @close="closeModal" @backClick="closeModal" hide-cancel hide-ok>

    <div class="py-2">
      <div class="info-row v-center">
        <div class="info-col-title">
          <label>類別:</label>
        </div>

        <div class="info-col-content">
          {{category1Name}} / {{category2Name}}
        </div>
      </div>

      <div class="info-row v-center">
        <div class="info-col-title">
          <label>色系:</label>
        </div>

        <div class="info-col-content">
          <ColorSelect v-model="cloth.color" :selection="false"></ColorSelect>
        </div>
      </div>

      <hr/>

      <div class="info-row v-center">
        <div class="info-col-title">
          <label>品牌:</label>
        </div>

        <div class="info-col-content">
          <div class="row no-gutters">
            <div class="col-auto pr-2" v-for="(item) in cloth.brands" :key="item">
              <span class="pills brand-pill">
                <span>#{{item}}</span>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="info-row v-center">
        <div class="info-col-title">
          <label>標籤:</label>
        </div>

        <div class="info-col-content">
          <div class="row no-gutters">
            <div class="col-auto pr-2" v-for="(item) in cloth.tags" :key="item">
              <span class="pills tag-pill">
                <span>#{{item}}</span>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="info-row v-center">
        <div class="info-col-title">
          <label>入手時間:</label>
        </div>

        <div class="info-col-content">
          {{getTimeString}}
        </div>
      </div>

      <div class="info-row v-center">
        <div class="info-col-title">
          <label>入手狀態:</label>
        </div>

        <div class="info-col-content">
          {{getStatusString}}
        </div>
      </div>

      <div class="info-row v-center">
        <div class="info-col-title">
          <label>入手價格:</label>
        </div>

        <div class="info-col-content">
          {{priceString}}
        </div>
      </div>

      <div class="info-row v-center">
        <div class="info-col-title">
          <label>主要材質:</label>
        </div>

        <div class="info-col-content">
          {{materialString}}
        </div>
      </div>
    </div>

    <!-- <div class="rent-footer">
      <p>完整的借用申請服務將於近期公開<br/>敬請期待</p>
    </div> -->
  </WcCommonModal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ColorSelect from '@/components/closet/ColorSelect.vue';
// import moment from 'moment';
import WcCommonModal from '@/components/commons/WcCommonModal.vue';

export default {
  name: 'ClothSimpleInfoModal',
  data() {
    return {
    };
  },
  components: {
    WcCommonModal,
    ColorSelect,
  },
  props: {
    cloth: {
      type: Object,
      required: true,
    },
  },
  created() {
  },
  beforeDestroy() {
  },
  async mounted() {
  },
  computed: {
    ...mapState(['clothColors', 'clothCategories']),
    category1() {
      const res = this.clothCategories.filter((item) => {
        return this.cloth.category1 === item.serial;
      });
      return res[0];
    },
    category1Name() {
      return this.category1.name;
    },
    category2Name() {
      const res = this.category1.sub.filter((item) => {
        return this.cloth.category2 === item.serial;
      });
      if (res.length > 0) {
        return res[0].name;
      }
      return '';
    },
    getTimeString() {
      let res = '';
      if (this.cloth.getYear !== -1) {
        res += `${this.cloth.getYear} 年`;
      }
      if (this.cloth.getMonth !== -1) {
        const monthAry = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'];
        res += ` ${monthAry[this.cloth.getMonth-1]}`
      }
      return res.length>0?res:'-';
    },
    getStatusString() {
      const opts = ['-', '新衣', '二手衣'];
      return opts[this.cloth.getStatus + 1];
    },
    priceString() {
      if (this.cloth.price === -1) {
        return '-';
      }
      return `$ ${this.cloth.price}`;
    },
    materialString() {
      const opts = ['-', '棉Cotton', '麻Linen', '絲Silk', '羊毛 Wool', '聚酯纖維Polyester', '尼龍Nylon', '嫘縈(人造絲)Rayon/Viscose', '彈性纖維Spandex', '環境友善(再生纖維等)', '其他'];
      return opts[this.cloth.material + 1];
    },
  },
  watch: {
  },
  methods: {
    ...mapActions(['appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
    closeModal() {
      this.$emit('close');
    },
    okClicked() {
      this.$emit('ok');
    },
  }
}
</script>

<style scoped>
  .info-row {
    display: flex;
    flex-direction: row;
    margin-bottom: .2rem;
    flex-wrap: nowrap;
  }

  .info-row.v-center {
    align-items: center;
  }

  .info-col-title {
    flex: 1 1;
    font-size: 14px;
    padding-right: 1rem;
  }


  .info-col-title>label {
    font-weight: 600;
    margin: 0;
  }

  .info-col-title>label.required::after {
    content: '*';
    color: var(--required-orange);
  }

  .info-col-content {
    flex: 0 0 60%;
    max-width: 60%;
    font-size: 12px;
  }

  .pills.brand-pill {
    --pill-color: var(--main-green);
  }

  .pills.tag-pill {
    --pill-color: var(--main-yellow);
  }

  .pills {
    position: relative;
    display: block;
    font-size: 12px;
    color: var(--pill-color);
    border-radius: 999px;
    padding: 3px 14px;
    border: 1px solid var(--pill-color);
    margin-bottom: .25rem;
    margin-top: .25rem;
  }

  .public-pill {
    position: absolute;
    top: .5rem;
    right: 1rem;
    display: block;
    font-size: 12px;
    color: #fff;
    border-radius: 999px;
    padding: 3px 14px;
    border: 1px solid var(--main-green);
    background-color: var(--main-green);
    margin-bottom: .25rem;
    margin-top: .25rem;
  }

  .rent-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;
    background-color: var(--main-orange);
    padding: 1rem;
  }

  .rent-footer p {
    color: #fff;
    text-align: center;
    margin-bottom: 0;
  }
</style>
