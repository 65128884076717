<template>
  <div class="custom-tag-editor">
    <button class="editor-btn" v-if="!hasEditorData" @click="toggleShowEditorModal">
      <span v-text="btnName"></span>
    </button>
    <div class="editor-info" v-if="hasEditorData">
      <div class="title fs-h4" v-text="typeName"></div>
      <div class="tag-list">
        <div class="tag-item" v-for="(item, index) in editorData" :key="index">
          <span class="fs-14px" v-text="'#' + item"></span>
        </div>
        <button class="edit-btn fs-14px fw-bold" @click="toggleShowEditorModal('open')">
          <span>編輯</span>
        </button>
      </div>
    </div>
    <semiOverlayModal class="tag-editor" v-model="showEditorModal">
      <div class="tag-editor-nav">
        <button class="close-btn" @click="toggleShowEditorModal('close')">
          <Icon name="close" size="24" />
        </button>
        <span class="fs-18px fw-bold" v-text="typeName"></span>
        <button class="save-btn fs-16px" @click="updateTagData">
          <span>儲存</span>
        </button>
      </div>
      <div class="tag-editor-option-panel">
        <div class="option-group">
          <input type="text" class="form-control fs-16px" :placeholder="placeholder" v-model="inputText">
          <button class="option-btn btn-primary" :disabled="!inputText" @click="addClicked">新增</button>
        </div>
        <div class="tag-list">
          <div class="tag-item" v-for="(item, index) in tags" :key="index">
            <span class="fs-14px" v-text="'#' + item"></span>
            <button class="delete-btn" @click="removeTag(index)">
              <Icon name="close" size="10" />
            </button>
          </div>
        </div>
        <div class="recent-features" v-if="recentData.length > 0">
          <div class="fs-h3 mb-0">最近使用</div>
          <div class="tag-list">
            <div class="tag-item" v-for="(item, index) in recentData" :key="index" @click="addTag(item)">
              <span class="fs-14px" v-text="'#' + item"></span>
            </div>
          </div>
        </div>
      </div>
    </semiOverlayModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import semiOverlayModal from "@/components/SemiOverlayModal.vue";

export default {
  name: 'CustomTagEditor',
  data() {
    return {
      showEditorModal: false,

      inputText: '',
      tags: [],
    }
  },
  components: {
    semiOverlayModal,
  },
  props: {
    editorData: {
      type: Array,
      default: () => [],
    },
    editorType: {
      type: String,
      default: 'tag',
    }
  },
  watch: {
    showEditorModal(newVal) {
      if (!newVal) {
        this.inputText = '';
      } else {
        this.tags = JSON.parse(JSON.stringify(this.editorData));
      }
    },
  },
  computed: {
    ... mapGetters(['recentBrands', 'recentTags']),

    btnName() {
      switch(this.editorType) {
        case 'brand':
          return '輸入品牌名稱';
        case 'tag':
        default:
          return '輸入標籤';
      }
    },
    typeName() {
      switch(this.editorType) {
        case 'brand':
          return '品牌';
        case 'tag':
        default:
          return '標籤';
      }
    },
    placeholder() {
      switch(this.editorType) {
        case 'brand':
          return '例如：ZARA';
        case 'tag':
        default:
          return '野餐/面試/登山/海邊';
      }
    },
    hasEditorData() {
      return this.editorData && this.editorData.length > 0;
    },
    recentData() {
      switch(this.editorType) {
        case 'brand':
          return this.recentBrands;
        case 'tag':
        default:
          return this.recentTags;
      }
    },
  },
  mounted() {
    this.tags = JSON.parse(JSON.stringify(this.editorData));
  },
  methods: {
    toggleShowEditorModal(action) {
      switch(action) {
        case 'close':
          this.showEditorModal = false;
          break;
        case 'open':
          this.showEditorModal = true;
          break;
        default:
          this.showEditorModal = !this.showEditorModal;
      }
    },
    addClicked() {
      const tagText = this.inputText.trim();
      if (tagText.length > 0 && this.tags.indexOf(tagText) === -1) {
        this.tags.push(tagText);
        this.inputText = '';
      }
    },
    removeTag(index) {
      this.tags.splice(index, 1);
    },
    updateTagData() {
      this.$emit('updateTagData', {
        type: this.editorType,
        tagData: this.tags,
      });
      this.showEditorModal = false;
    },
    addTag(tagText) {
      if (tagText.length > 0 && this.tags.indexOf(tagText) === -1) {
        this.tags.push(tagText);
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.custom-tag-editor {
  .editor-btn {
    color: $typography-secondary-default;
    background: $color-white;
    width: 100%;
    text-align: left;
    border-radius: .5rem;
    border: solid 1px $color-border;
    padding: 1.5rem 1rem;
  }
  .tag-list {
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
    .tag-item {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      gap: .25rem;
      flex-shrink: 0;
      color: $color-white;
      background: $typography-primary-default;
      border-radius: 100px;
      padding: .25rem .5rem;
    }
  }
  .semi-overlay-modal.tag-editor {
    .tag-editor-nav {
      position: relative;
      text-align: center;
      padding: 1rem 4rem;
      .close-btn {
        position: absolute;
        top: .5rem;
        left: .5rem;
        display: flex;
        background: transparent;
        border: none;
        padding: .5rem;
      }
      .save-btn {
        position: absolute;
        top: .5rem;
        right: .5rem;
        color: $typography-text-link;
        background: transparent;
        line-height: 24px;
        border: none;
        padding: .5rem;
      }
    }
    .tag-editor-option-panel {
      padding: 1.25rem 1.75rem;
      .option-group {
        display: flex;
        flex-wrap: nowrap;
        gap: .5rem;
        .form-control {
          flex-grow: 1;
          width: auto;
          font-size: .875rem;
          line-height: 1.25rem;
          padding: .75rem 1rem;
        }
        .option-btn {
          flex-shrink: 0;
          min-width: 88px;
          border-radius: .75rem;
          padding: .5rem 1rem;
        }
      }
      .tag-list {
        padding-top: 1rem;
        padding-bottom: 2rem;
        .tag-item {
          padding: .25rem .375rem .25rem .5rem;
          .delete-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            background: $color-white;
            width: 1rem;
            height: 1rem;
            border: solid 1px $typography-primary-default;
            border-radius: 100px;
            padding: 0;
          }
        }
      }
    }
  }
  .editor-info {
    padding-top: .5rem;
    padding-bottom: .5rem;
    .title {
      margin-bottom: .75rem;
    }
    .name {
      position: relative;
      display: flex;
      gap: .25rem;
      padding-left: .25rem;
      padding-right: 4rem;
      .svg-icon {
        flex-shrink: 0;
      }
    }
    .tag-list {
      position: relative;
      padding-right: 4rem;
    }
    .edit-btn {
      position: absolute;
      top: 50%;
      right: .5rem;
      color: $typography-text-link;
      background: transparent;
      line-height: 24px;
      border: none;
      padding: .5rem;
      transform: translateY(-50%);
    }
  }
  .recent-features {
    .tag-list {
      .tag-item {
        color: $typography-secondary-default;
        background: $color-white;
        border: solid .5px $typography-secondary-default;
      }
    }
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.custom-tag-editor {
  .semi-overlay-modal.tag-editor {
    .modal-content {
      padding: 0;
    }
  }
}
</style>