<template>
  <WcModal class="" @backClick="backClicked">
    <ul class="action-list">
      <li v-for="(action) in actions" :class="{'disabled': action.disabled}" :key="action.value">
        <div class="" @click="emitAction(action)">
          {{action.text}}
        </div>
      </li>

      <li v-if="!hideCancel">
        <div class="" @click="cancelClicked">
          取消
        </div>
      </li>
    </ul>
  </WcModal>
</template>

<script>
import WcModal from '@/components/commons/WcModal.vue';

export default {
  name: 'WcActionModal',
  data() {
    return {
    }
  },
  components: {
    WcModal
  },
  props: {
    actions: {
      type: Array,
      required: false,
      default() {
        return [{
          text: '確定',
          value: 'ok',
        }];
      },
    },
    hideCancel: {
      type: Boolean,
      required: false,
      default: false,
    },
    staticBackground: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    emitAction(cmd) {
      if (!cmd.disabled) {
        this.$emit('action', cmd.value);
      }
    },
    cancelClicked() {
      this.$emit('cancel');
    },
    backClicked() {
      if (!this.staticBackground) {
        this.cancelClicked();
      }
    },
  }

}
</script>

<style scoped>
  ul.action-list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    width: 90vw;
    position: relative;
  }

  ul.action-list>li {
    padding: 0;
    margin: 0;
    list-style: none;
    width: 100%;
    text-align: center;

    color: #4D4D4D;
    background-color: #fff;
    padding: 1.2em;
    border-radius: 1em;
  }

  ul.action-list>li.disabled {
    background-color: #ccc;
    color: #888;
  }

  ul.action-list>li:not(:last-child) {
    margin-bottom: .5em;
  }
</style>
