<template>
  <div class="wc-text-input">
    <input :type="textInputType" :disabled="disabled" v-model="textData" @focus="inputFocus" @blur="inputBlur" :placeholder="placeholder"/>
  </div>
</template>

<script>

export default {
  name: 'custom-text-input',
  data() {
    return {
      textData: '',
      focusing: false,
    }
  },
  props: {
    type: {
      required: false,
      default: 'text',   // text, number
      validator: function (value) {
        return ['text', 'number'].indexOf(value) !== -1;
      }
    },
    value: {
      required: false,
      default: '',
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    secret: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  created() {
    this.fromOutsideValueToInsideValue();
  },
  computed: {
    isText() {
      return this.type === 'text';
    },
    isNumber() {
      return this.type === 'number';
    },
    textInputType() {
      return this.isNumber?'number':(this.secret?'password':'text');
    }
  },
  watch: {
    textData(newVal) {
      this.$emit('input', newVal);
    },
    value() {
      this.fromOutsideValueToInsideValue();
    }
  },
  methods: {
    fromOutsideValueToInsideValue() {
      if (this.isText) {
        if (this.textData !== this.value) {
          this.textData = this.value;
        }
      } else if (this.isNumber) {
        const f = parseFloat(this.value);
        this.textData = '' + f;
      }
    },
    inputFocus() {
      this.focusing = true;
    },
    inputBlur() {
      this.focusing = false;
      if (this.isNumber) {
        let t = parseFloat(this.textData);
        if (isNaN(t)) {
          this.textData = '0';
        } else {
          this.textData = '' + parseFloat(t.toFixed(6));
        }
      }
    },
  }

}
</script>

<style scoped>
  .wc-text-input {
    border: none;
  }

  .wc-text-input>input {
    border-radius: 0.25rem;
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.1rem 0.75rem;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    color: #444;
    vertical-align: middle;
    background: #eeeeee;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
</style>
