import Vue from 'vue'
import App from './App.vue'

import moment from 'moment-timezone';
// 設置全局默認時區為用戶的時區
const userTimeZone = moment.tz.guess();
moment.tz.setDefault(userTimeZone);

import VCalendar from 'v-calendar'
import './registerServiceWorker'
import router from './router'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(fas, far, fab)

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import store from './store'

import '/src/assets/scss/main.scss'

Vue.config.productionTip = false

Vue.use(VCalendar);
Vue.component('font-awesome-icon', FontAwesomeIcon)

const requireAll = requireContext => requireContext.keys().map(requireContext);
const req = require.context("./assets/icons/svg", true, /\.svg$/);
requireAll(req);
import Icon from "@/components/commons/Icon";
Vue.component("Icon", Icon);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
