<template>
  <div class="full-screen-modal">
    <div class="box">
      <div class="box-header" v-if="!hideHeader">
        <a href="#" @click.prevent="backClicked">
<!--          <img :src="require('@/assets/icons/back.png')" />-->
          <Icon v-if="btnType === 'close'" name="close" size="24" />
          <Icon v-else name="chevron-left" size="24" />
        </a>
        <h2 class="fs-h3">{{title}}</h2>
        <div class="right-top">
          <slot name="right-top"></slot>
        </div>
      </div>

      <div class="box-body">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FullScreenModal',
  data() {
    return {

    };
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    btnType: {
      type: String,
      required: false,
      default: '',
    },
    hideHeader: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  methods: {
    backClicked() {
      this.$emit('back');
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
  .full-screen-modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    height: -moz-available;
    height: -webkit-fill-available;
    height: fill-available;
    height: 100dvh;
    z-index: 9000;
    background-color: #fff;
    .fs-h3 {
      margin-bottom: 0;
    }
  }

  .box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .box .box-header {
    width: 100%;
    min-height: 52px;
    flex: 0 0 auto;
    padding: .75rem 2.5rem;
    position: relative;
    border-bottom: solid 1px $color-border;
    text-align: center;
    >a {
      /* display: block; */
      position: absolute;
      left: .1rem;
      top: 50%;
      transform: translate(0, -50%);
      font-size: 12px;
      line-height: 1em;
      /* padding: .5rem; */
      color: $typography-primary-default;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      >img {
        height: 1em;
      }
    }
    .right-top {
      position: absolute;
      right: 0;
      top: 0;
    }
  }


  //.box .box-header>h2 {
  //  font-size: 14px;
  //  font-weight: 1000;
  //  text-align: center;
  //  text-overflow: ellipsis;
  //  white-space: nowrap;
  //  overflow: hidden;
  //  margin: 0;
  //}

  .box .box-body {
    width: 100%;
    flex: 1 1;
    overflow-x: hidden;
    overflow-y: auto;
  }
</style>
