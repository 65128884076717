<template>
  <WcModal class="" @backClick="backClick">
    <div class="wc-modal">
      <div class="header" :class="headerClass" v-if="!hideHeader">
        <p class="title">{{title}}</p>

        <a href="#" @click.prevent="closeClicked" class="modal-close" v-if="!hideClose">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 6L6 18" stroke="#F9F8F8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6 6L18 18" stroke="#F9F8F8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </a>
      </div>

      <div class="content">
        <slot></slot>
      </div>

      <div class="footer">
        <button @click.prevent="cancelClicked" v-if="!hideCancel"><slot name="cancel">取消</slot></button>
        <button @click.prevent="okClicked" v-if="!hideOk" :disabled="disableOk"><slot name="ok">確定</slot></button>
      </div>
    </div>
  </WcModal>
</template>

<script>
import WcModal from '@/components/commons/WcModal.vue';

export default {
  name: 'wc-common-modal',
  data() {
    return {
    }
  },
  components: {
    WcModal
  },
  props: {
    hideHeader: {
      type: Boolean,
      default: false,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
    hideCancel: {
      type: Boolean,
      default: false,
    },
    hideOk: {
      type: Boolean,
      default: false,
    },
    disableOk: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    headerClass: {
      type: String,
      default: '',
    }
  },
  methods: {
    emitClick(cmd) {
      this.$emit('btnClick', cmd);
    },
    closeClicked() {
      this.$emit('close');
    },
    cancelClicked() {
      this.$emit('cancel');
    },
    okClicked() {
      this.$emit('ok');
    },
    backClick() {
      this.$emit('backClick');
    },
  }

}
</script>

<style scoped>

  .wc-modal {
    border-radius: .5rem;
    overflow: hidden;
    min-width: 300px;
    background-color: #fff;
    box-shadow: 0 0 8px 0 #ffffff78;
    max-width: 90vw;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
  }

  .wc-modal .header {
    position: relative;
    background-color: #4D4D4D;
    color: #fff;
    padding: 18px 1rem 14px;
    flex: 0 0 auto;
  }

  .wc-modal .header .title {
    text-align: center;
    margin: 0;
    font-size: 14px;
  }

  .wc-modal .header .modal-close {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translate(0, -50%);
  }

  .wc-modal .content {
    padding: 1rem 1.5rem;
    flex: 1 1;
    overflow-y: auto;
  }

  .wc-modal .footer {
    display: flex;
  }

  .wc-modal .footer>button {
    font-size: 14px;
    display: block;
    padding: 12px;
    border: solid 1px #fff;
    background-color: #EEEEEE;
    color: #000;
    flex: 1 1;
    text-align: center;
  }

  .wc-modal .footer>button:disabled {
    color: #aaa;
  }

  .wc-modal .footer>a:hover {
    text-decoration: none;
  }
</style>
