import Vue from 'vue'
import router from '@/router'

export default {
  namespaced: true,
  state: {
    redirectCookieKey: 'LOOPII_LOGIN_REDIRECT',
  },
  getters: {
  },
  mutations: {
    setRedirect(state, redirectRoute) {
      const redirectObj = {
        name: redirectRoute.name,
        params: redirectRoute.params,
      };
      Vue.$cookies.set(state.redirectCookieKey, JSON.stringify(redirectObj), "30d");
    },
  },
  actions: {
    setRedirect({ commit }, redirectRoute) {
      commit('setRedirect', redirectRoute);
    },
    clearRedirect(context) {
      Vue.$cookies.remove(context.state.redirectCookieKey);
    },
    getRedirect(context) {
      const redirectRoute = Vue.$cookies.get(context.state.redirectCookieKey);
      try {
        if (redirectRoute) {
          if (typeof(redirectRoute) === 'object') {
            return redirectRoute;
          } else if (typeof(redirectRoute) === 'string') {
            return JSON.parse(redirectRoute);
          }
        }
      } catch (error) {
        console.error(error);
      }
      return null;
    },
    redirectToLogin({ dispatch }, redirectRoute) {
      dispatch('setRedirect', redirectRoute);
      router.push({
        name: 'LoopiiLogin',
      })
    }
  }

}
