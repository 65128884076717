<template>
  <span class="wc-switch" :class="{'on': on}" @click="toggleOn">
    <span class="ball">
    </span>
  </span>
</template>


<script>
// @ is an alias to /src

export default {
  name: 'WcSwitch',
  data() {
    return {
      on: false,
    };
  },
  props: {
    value: {
      type: Boolean,
      required: false,
    }
  },
  created() {
    this.on = this.value;
  },
  components: {
  },
  computed: {

	},
  watch: {
    value(val) {
      this.on = val;
    },
    on(val) {
      this.$emit('input', val);
    }
  },
  mounted() {
	},
  methods: {
    toggleOn() {
      this.on = !this.on;
    }
  }
}
</script>

<style scoped>
  .wc-switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 26px;
    background-color: #aaa;
    border-radius: 999px;
    transition: background-color .4s ease;
  }

  .wc-switch.on {
    background-color: var(--brand-color-primary);
  }

  .wc-switch .ball {
    position: absolute;
    display: inline-block;
    width: 24px;
    height: 24px;
    background-color: #fff;
    border-radius: 50%;
    top: 1px;
    left: 1px;
    transition: left .4s ease;
  }

  .wc-switch.on .ball {
    left: 19px;
  }
</style>
