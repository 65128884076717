export default () => {
  return {
    serial: -1,
    imgs: [],
    category1: -1,
    category2: -1,
    color: 0,
    name: '',
    brands: [],
    tags: [],
    size: '',
    getYear: -1,
    getMonth: -1,
    getStatus: -1,  // -1: 未設定, 0: 新衣, 1: 二手衣
    price: -1, // 入手價格
    originalPrice: -1, // 購入定價
    material: -1,  // -1: 未設定, 0: 棉Cotton, 1: 麻Linen, 2: 絲Silk, 3: 羊毛 Wool, 4: 聚酯纖維Polyester, 5: 尼龍Nylon, 6: 嫘縈(人造絲)Rayon/Viscose, 7: 彈性纖維Spandex, 8: 環境友善(再生纖維等）, 9: 其他
    washRule: -1, // -1: 未設定, 0: 手洗, 1: 洗衣機-柔洗, 2: 洗衣機-標準, 3: 送洗, 4: 送洗-乾洗
    washAlert: false,
    washAlertMonth: -1,
    washAlertDay: -1,
    desc: '',
    recycleTime: null,
    recycleType: -1,
    isPublic: false,
    photoBack: '',
    photoBrand: '',
    photoWash: '',
    materialPhoto: '',
  };
};
