<template>
  <FullScreenModal class="create-cloth" :title="modalTitle" @back="backClicked">
    <div class="create-cloth-body">
      <UploadMask class="images-group" v-model="cropProgress">
        <div
            class="collection-image main-image crop-size"
            v-for="(index) in activeImagesArray"
            :key="index"
        >
          <CropImageButton
              @uploaded="clothImgUploaded(index, $event)"
              @progress="updateCropProgress"
              can-remove-bg
          >
            <div
                class="upload-btn d-flex flex-column justify-content-center align-items-center"
                v-if="cloth.imgs.length === index"
            >
              <Icon name="Camera" size="24" />
              <div class="fs-16px fw-bold">{{ index === 0 ? '新增照片' : '其他照片' }}</div>
            </div>
            <div class="upload-btn" v-if="cloth.imgs.length > index">
              <img :src="cloth.imgs[index]" alt="cloth" />
            </div>
          </CropImageButton>
          <div class="image-delete-btn" v-if="index > 0 && cloth.imgs.length > index" @click="removePhotoIndex(index)">
            <Icon class="btn-icon" name="close" size="24" />
          </div>
        </div>
      </UploadMask>
      <div class="content-block">
        <h3 class="header fs-h3">
          <span>單品資訊</span>
          <span class="ps">（必填）</span>
        </h3>
        <b-form-group class="form-group-types">
          <div class="col-half-group">
            <b-form-select
                v-model="cloth.category1"
                :options="category1Options"
                required
            />
            <b-form-select
                v-model="cloth.category2"
                :options="category2Options"
                required
            />
          </div>
        </b-form-group>
        <div class="color-select-group">
          <h4 class="fs-h4">色系</h4>
          <ColorSelect v-model="cloth.color" :size="40" />
        </div>
        <hr>
        <div class="optional-input-collapse">
          <div class="collapse-btn" v-b-toggle:optional-input-collapse>
            <h3 class="header fs-h3">
              <span>進階資訊</span>
              <span class="ps">（選填）</span>
            </h3>
            <div class="desc fs-14px">建議填寫完整會得到更細的使用分析</div>
            <Icon class="btn-icon" name="chevron-down" size="24" />
          </div>
          <b-collapse id="optional-input-collapse">
            <b-form-input id="input-brand-name" v-model="cloth.name" trim placeholder="衣物名稱" />
            <CustomTagEditor :editorData="cloth.brands" editorType="brand" @updateTagData="handleTagData" />
            <div class="col-half-group">
              <label class="custom-input-with-title">
                <span class="title fs-14px">衣物定價</span>
                <b-form-input v-model="formattedOriginalPrice" placeholder="NTD$" number />
              </label>
              <label class="custom-input-with-title">
                <span class="title fs-14px">購入價格</span>
                <b-form-input v-model="formattedPrice" placeholder="NTD$" number />
              </label>
            </div>
            <CustomTagEditor :editorData="cloth.tags" editorType="tag" @updateTagData="handleTagData" />
            <ClothSizeSelectNewStyle v-model="cloth.size" null-text="尺寸" />
            <div class="col-half-group">
              <label class="custom-select-with-title">
                <span class="title fs-14px">入手年份</span>
                <b-form-select
                    v-model="cloth.getYear"
                    :options="getYearOptions"
                />
              </label>
              <label class="custom-select-with-title">
                <span class="title fs-14px">入手月份</span>
                <b-form-select
                    v-model="cloth.getMonth"
                    :options="getMonthOptions"
                />
              </label>
            </div>
            <b-form-group class="custom-radio-group" label="商品狀態">
              <b-form-radio-group
                  v-model="cloth.getStatus"
                  :options="getStatusOptions"
              />
            </b-form-group>
            <label class="custom-select-with-title">
              <span class="title fs-14px">主要材質</span>
              <b-form-select
                  v-model="cloth.material"
                  :options="materialOptions"
              />
            </label>
            <label class="custom-select-with-title">
              <span class="title fs-14px">清洗規範</span>
              <b-form-select
                  v-model="cloth.washRule"
                  :options="washRuleOptions"
              />
            </label>
            <div class="detail-image-block">
              <div class="header">
                <h4 class="fs-h4">衣物細節照片</h4>
                <div class="desc fs-14px">完整地紀錄衣物資料，未來能更便利填寫相關資料</div>
              </div>
              <UploadMask class="images-group" v-model="cropProgress">
                <div class="collection-image sub-image">
                  <CropImageButton @uploaded="photoBackUploaded">
                    <div class="upload-btn d-flex flex-column justify-content-center align-items-center" v-if="!hasPhotoBack">
                      <Icon name="Camera" size="24" />
                      <div class="fs-12px fw-bold">單品背面照</div>
                    </div>
                    <div class="upload-btn" v-if="hasPhotoBack">
                      <img :src="cloth.photoBack" alt="cloth" />
                    </div>
                  </CropImageButton>
                  <div class="image-delete-btn" v-if="hasPhotoBack" @click="removePhotoBack">
                    <Icon class="btn-icon" name="close" size="24" />
                  </div>
                </div>
                <div class="collection-image sub-image">
                  <CropImageButton @uploaded="photoWashUploaded">
                    <div class="upload-btn d-flex flex-column justify-content-center align-items-center" v-if="!hasPhotoWash">
                      <Icon name="Camera" size="24" />
                      <div class="fs-12px fw-bold">洗標</div>
                    </div>
                    <div class="upload-btn" v-if="hasPhotoWash">
                      <img :src="cloth.photoWash" alt="cloth" />
                    </div>
                  </CropImageButton>
                  <div class="image-delete-btn" v-if="hasPhotoWash" @click="removePhotoWash">
                    <Icon class="btn-icon" name="close" size="24" />
                  </div>
                </div>
                <div class="collection-image sub-image">
                  <CropImageButton @uploaded="materialPhotoUploaded">
                    <div class="upload-btn d-flex flex-column justify-content-center align-items-center" v-if="!hasMaterialPhoto">
                      <Icon name="Camera" size="24" />
                      <div class="fs-12px fw-bold">材質照片</div>
                    </div>
                    <div class="upload-btn" v-if="hasMaterialPhoto">
                      <img :src="cloth.materialPhoto" alt="cloth" />
                    </div>
                  </CropImageButton>
                  <div class="image-delete-btn" v-if="hasMaterialPhoto" @click="removeMaterialPhoto">
                    <Icon class="btn-icon" name="close" size="24" />
                  </div>
                </div>
                <div class="collection-image sub-image">
                  <CropImageButton @uploaded="photoBrandUploaded">
                    <div class="upload-btn d-flex flex-column justify-content-center align-items-center" v-if="!hasPhotoBrand">
                      <Icon name="Camera" size="24" />
                      <div class="fs-12px fw-bold">領口品牌標</div>
                    </div>
                    <div class="upload-btn" v-if="hasPhotoBrand">
                      <img :src="cloth.photoBrand" alt="cloth" />
                    </div>
                  </CropImageButton>
                  <div class="image-delete-btn" v-if="hasPhotoBrand" @click="removePhotoBrand">
                    <Icon class="btn-icon" name="close" size="24" />
                  </div>
                </div>
              </UploadMask>
            </div>
            <div class="common-block">
              <h4 class="fs-h4">清洗提醒</h4>
              <b-form-checkbox v-model="cloth.washAlert">開啟通知</b-form-checkbox>
              <div class="col-half-group">
                <b-form-select
                    v-model="cloth.washAlertMonth"
                    :options="washAlertMonthOptions"
                />
                <b-form-select
                    v-model="cloth.washAlertDay"
                    :options="washAlertDayOptions"
                />
              </div>
            </div>
            <b-form-textarea
                id="textarea"
                v-model="cloth.desc"
                placeholder="備註"
                rows="3"
                max-rows="6"
            />
          </b-collapse>
          <div class="common-block share">
            <h4 class="fs-h4">公開分享</h4>
            <div class="desc fs-14px">所有追蹤你的好友可以看到此單品</div>
            <WcSwitch v-model="cloth.isPublic" />
          </div>
        </div>
      </div>

    </div>

    <CustomModalFooter>
      <b-button class="btn-primary" v-if="cloth.serial === -1" block :disabled="saveBtnDisabled" @click="createBtnClicked">上傳</b-button>
      <b-button class="btn-primary" v-if="cloth.serial !== -1" block :disabled="saveBtnDisabled" @click="editBtnClicked">儲存修改</b-button>
    </CustomModalFooter>

    <transition name="fade">
      <WcCommonModal title="確定離開" v-if="isConfirmModalShow" @cancel="isConfirmModalShow = false" @ok="backConfirm" hide-header>
        <div class="text-center">
          未儲存資料<br/>
          要離開嗎？
        </div>
      </WcCommonModal>
    </transition>

  </FullScreenModal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ColorSelect from '@/components/closet/ColorSelect.vue';
import clothDefault from '@/dataModel/cloth.js';
import FullScreenModal from '@/components/FullScreenModal.vue';
import WcCommonModal from '@/components/commons/WcCommonModal.vue';
import WcSwitch from '@/components/commons/WcSwitch.vue';
import CropImageButton from '@/components/cropImage/CropImageButton.vue';
import '@/assets/css/buttons.css';
import CustomTagEditor from "@/components/commons/CustomTagEditor.vue";
import CustomModalFooter from "@/components/CustomModalFooter.vue";
import ClothSizeSelectNewStyle from "@/components/ClothSizeSelectNewStyle.vue";
import UploadMask from "@/components/UploadMask.vue";

export default {
  name: 'CreateCloth',
  data() {
    return {
      initBusyName: 'CREATECLOTHINITREADING',
      uploadBusyName: 'CREATECLOTHUPLOADINGIMAGE',
      saveClothBusyName: 'CREATECLOTHSAVING',
      init: false,

      inputClothPrice: '',

      clothImgUploading: false,

      cloth: {},

      cropProgress: -1,

      isBrandModalShow: false,
      isTagModalShow: false,
      isConfirmModalShow: false,
    };
  },
  components: {
    ClothSizeSelectNewStyle,
    CustomModalFooter,
    CustomTagEditor,
    ColorSelect,
    FullScreenModal,
    WcCommonModal,
    WcSwitch,
    CropImageButton,
    UploadMask,
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  created() {
    this.cloth = Object.assign({}, this.cloth, clothDefault());
  },
  beforeDestroy() {
    this.clearComponentBusy(this.initBusyName);
    this.clearComponentBusy(this.uploadBusyName);
    this.clearComponentBusy(this.saveClothBusyName);
  },
  async mounted() {
    this.appendComponentBusy(this.initBusyName);
    try {
      if (this.id !== -1) {
        const cloth = await this.$store.dispatch('api/readClothInfoPromise', this.id);
        this.cloth = Object.assign({}, this.cloth, cloth);
        if (this.cloth.price > -1) {
          this.inputClothPrice = '' + this.cloth.price;
        }
      } else {
        // console.log(clothDefault);
        // console.log(clothDefault());
        // this.cloth = Object.assign({}, this.cloth, clothDefault());
        // console.log(this.cloth);
      }
      this.init = true;
    } catch(e) {
      this.emitError(e);
    } finally {
      this.clearComponentBusy(this.initBusyName);
    }

  },
  computed: {
    ...mapState(['clothColors', 'clothCategories']),
    activeImagesArray() {
      return Array.from({ length: 5 }, (_, i) => i).filter(index => this.cloth.imgs.length >= index);
    },
    saveBtnDisabled() {
      // if (this.clothImgUploading) {
      //   return true;
      // }
      // return false;
      return this.cropProgress !== -1;
    },
    hasImg() {
      return this.cloth.imgs.length > 0;
    },
    formattedTypesRules() {
      return this.cloth.category1 === -1 || this.cloth.category2 === -1 ? 'typesRequired' : '';
    },
    category1Options() {
      const opts = [{
        text: '分類',
        value: -1,
      }];
      this.clothCategories.map((item) => {
        opts.push({
          text: item.name,
          value: item.serial
        });
      });
      return opts;
    },
    category2Options() {
      const opts = [{
        text: '細項',
        value: -1,
      }];
      const selectedC1 = this.clothCategories.filter((item) => {
        return item.serial === this.cloth.category1;
      });
      if (selectedC1.length > 0) {
        selectedC1[0].sub.map((item) => {
          opts.push({
            text: item.name,
            value: item.serial
          });
        });
      }
      return opts;
    },
    getYearOptions() {
      const opts = [{
        text: '年份',
        value: -1
      }];
      const thisYear = new Date().getFullYear();
      for (let i=0;i<50;i++) {
        opts.push({
          text: `${thisYear - i}`,
          value: thisYear - i
        });
      }
      return opts;
    },
    getMonthOptions() {
      const opts = [{
        text: '月份',
        value: -1
      }];
      const monthAry = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'];
      for (let i=0;i<12;i++) {
        opts.push({
          text: monthAry[i],
          value: i + 1
        });
      }
      return opts;
    },
    getStatusOptions() {
      const opts = [{
        text: '空值',
        value: -1
      }];

      opts.push({
        text: '新品',
        value: 0
      });

      opts.push({
        text: '二手',
        value: 1
      });
      return opts;
    },
    materialOptions() {
      return ['請選擇', '棉Cotton', '麻Linen', '絲Silk', '羊毛 Wool', '聚酯纖維Polyester', '尼龍Nylon', '嫘縈(人造絲)Rayon/Viscose', '彈性纖維Spandex', '環境友善(再生纖維等)', '其他'].map((item, index) => {
        return {
          text: item,
          value: index - 1
        };
      });
    },
    washRuleOptions() {
      return ['請選擇', '手洗', '洗衣機-柔洗', '洗衣機-標準', '送洗', '送洗-乾洗'].map((item, index) => {
        return {
          text: item,
          value: index - 1
        };
      });
    },
    washAlertMonthOptions() {
      const opts = [{
        text: '時間',
        value: -1
      }];
      opts.push({
        text: '每個月',
        value: 1
      });
      opts.push({
        text: '每兩個月',
        value: 2
      });
      opts.push({
        text: '每一季',
        value: 3
      });
      opts.push({
        text: '每半年',
        value: 6
      });
      return opts;
    },
    washAlertDayOptions() {
      const opts = [{
        text: '日',
        value: -1
      }];
      opts.push({
        text: '1日',
        value: 1
      });
      opts.push({
        text: '15日',
        value: 15
      });
      return opts;
    },
    formattedOriginalPrice: {
      get() {
        return this.cloth.originalPrice === -1 ? '' : this.cloth.originalPrice;
      },
      set(value) {
        this.cloth.originalPrice = value === '' ? -1 : Number(value);
      }
    },
    formattedPrice: {
      get() {
        return this.cloth.price === -1 ? '' : this.cloth.price;
      },
      set(value) {
        this.cloth.price = value === '' ? -1 : Number(value);
      }
    },
    isCreate() {
      return this.id === -1;
    },
    isEdit() {
      return this.id !== -1;
    },
    modalTitle() {
      if (this.isCreate) {
        return '上傳單品';
      }
      if (this.isEdit) {
        return '編輯單品';
      }
      return '';
    },
    hasPhotoBack() {
      return this.cloth.photoBack.length > 0;
    },
    hasPhotoBrand() {
      return this.cloth.photoBrand.length > 0;
    },
    hasPhotoWash() {
      return this.cloth.photoWash.length > 0;
    },
    hasMaterialPhoto() {
      return this.cloth.materialPhoto.length > 0;
    },
  },
  watch: {
    inputClothPrice(val) {
      let i = parseInt(val);
      if (isNaN(i)) {
        this.cloth.price = -1;
      } else {
        this.cloth.price = i;
      }
    },
  },
  methods: {
    ...mapActions(['appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
    uploadFunction(file) {
      return this.$store.dispatch('api/postUploadImagePromise', file);
    },
    uploadSubImgFunction(file) {
      return this.$store.dispatch('api/postUploadSubImagePromise', file);
    },
    uploadingImageBusy(val) {
      if (val) {
        this.appendComponentBusy(this.uploadBusyName);
      } else {
        this.clearComponentBusy(this.uploadBusyName);
      }
    },
    category1Changed(val) {
      this.cloth.category2 = -1;
      this.cloth.category1 = val;
    },
    appendClothImg(data) {
      const {url} = data;
      this.cloth.imgs.push(url);
    },
    clothImgUploaded(index, data) {
      const { url } = data;
      console.log(url)
      if (this.cloth.imgs.length <= index) {
        // 長度不足時 push，確保 index 位置存在
        this.cloth.imgs.push(url);
        console.log(123)
      } else {
        // 直接替換指定 index 位置的 URL
        this.$set(this.cloth.imgs, index, url);
      }
    },
    photoBackUploaded(data) {
      const {url} = data;
      this.cloth.photoBack = url;
    },
    photoBrandUploaded(data) {
      const {url} = data;
      this.cloth.photoBrand = url;
    },
    photoWashUploaded(data) {
      const {url} = data;
      this.cloth.photoWash = url;
    },
    materialPhotoUploaded(data) {
      const {url} = data;
      this.cloth.materialPhoto = url;
    },
    removePhotoBack() {
      this.cloth.photoBack = '';
    },
    removePhotoBrand() {
      this.cloth.photoBrand = '';
    },
    removePhotoWash() {
      this.cloth.photoWash = '';
    },
    removeMaterialPhoto() {
      this.cloth.materialPhoto = '';
    },
    emitError(err) {
      this.appendErrorMsg(err);
    },
    checkClothValidate(cloth) {
      if (cloth.imgs.length === 0) {
        return '缺少衣物圖片';
      } else if (cloth.category1 === -1) {
        return '請選擇衣物大分類';
      } else if (cloth.category2 === -1) {
        return '請選擇衣物小分類';
      }
      return '';
    },
    scrollToTop() {
      const boxBody = document.querySelector('.full-screen-modal.create-cloth .box-body');
      if (boxBody) {
        boxBody.scrollTo({ top: 0, behavior: 'smooth' });
      }
    },
    async createBtnClicked() {
      const insertData = this.cloth;
      const errMsg = this.checkClothValidate(insertData);
      if (errMsg.length > 0) {
        this.emitError(errMsg);
        this.scrollToTop();
      } else {
        this.appendComponentBusy(this.saveClothBusyName);
        try {
          const res = await this.$store.dispatch('api/postCreateClothPromise', insertData);
          this.$emit('created', res);
        } catch(e) {
          this.emitError(e);
        } finally {
          this.clearComponentBusy(this.saveClothBusyName);
        }
      }
    },
    async editBtnClicked() {
      const insertData = this.cloth;
      const errMsg = this.checkClothValidate(insertData);
      if (errMsg.length > 0) {
        this.emitError(errMsg);
        this.scrollToTop();
      } else {
        this.appendComponentBusy(this.saveClothBusyName);
        try {
          const res = await this.$store.dispatch('api/updateClothInfoPromise', {
            id: this.id,
            cloth: insertData,
          });
          this.$emit('updated', res);
        } catch(e) {
          this.emitError(e);
        } finally {
          this.clearComponentBusy(this.saveClothBusyName);
        }
      }
    },
    showBrandModal() {
      this.isBrandModalShow = true;
    },
    showTagModal() {
      this.isTagModalShow = true;
    },
    brandModalOk(brandTags) {
      this.isBrandModalShow = false;
      this.cloth.brands.splice(0, this.cloth.brands.length);
      for (const brand of brandTags) {
        this.cloth.brands.push(brand);
      }
    },
    brandModalClose() {
      this.isBrandModalShow = false;
    },
    tagModalOk(tags) {
      this.isTagModalShow = false;
      this.cloth.tags.splice(0, this.cloth.tags.length);
      for (const tag of tags) {
        this.cloth.tags.push(tag);
      }
    },
    tagModalClose() {
      this.isTagModalShow = false;
    },
    removeBrand(index) {
      this.cloth.brands.splice(index, 1);
    },
    removeTag(index) {
      this.cloth.tags.splice(index, 1);
    },
    backClicked() {
      this.isConfirmModalShow = true;
    },
    backConfirm() {
      this.$emit('back');
    },
    uploadImgSetBusy(busy) {
      this.clothImgUploading = busy;
      console.log(this.clothImgUploading);
    },
    handleTagData(newTagData) {
      switch (newTagData.type) {
        case 'brand':
          this.cloth.brands = JSON.parse(JSON.stringify(newTagData.tagData));
          break;
        case 'tag':
        default:
          this.cloth.tags = JSON.parse(JSON.stringify(newTagData.tagData));
      }
    },
    openSelect(event) {
      const select = event.currentTarget.querySelector("select");
      if (select) {
        select.focus(); // 讓 select 取得焦點
        // 嘗試觸發原生的下拉選單開啟事件
        const event = new MouseEvent("mousedown", { bubbles: true, cancelable: true, view: window });
        select.dispatchEvent(event);
      }
    },
    updateCropProgress(val) {
      this.cropProgress = val;
    },
    removePhotoIndex(index) {
      if (index >= 0 && index < this.cloth.imgs.length) {
        this.cloth.imgs.splice(index, 1);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.create-cloth {
  .create-cloth-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 1rem 6.5rem;
    .images-group {
      display: flex;
      flex-wrap: wrap;
      gap: .5rem;
      .collection-image {
        position: relative;
        width: calc((100% - 1.5rem)/4);
        padding-top: calc((100% - 1.5rem)/4);
        &.main-image {
          &:first-child {
            width: 100%;
            padding-top: 100%;
          }
          .upload-btn .svg-icon {
            margin-bottom: .25rem;
          }
        }
        .image-delete-btn {
          position: absolute;
          top: 0;
          right: 0;
        }
      }
      .upload-btn {
        color: $typography-secondary-default;
        width: 100%;
        height: 100%;
        border: solid 1px $color-border;
        border-radius: .75rem;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        .svg-icon {
          margin-bottom: .125rem;
        }
      }
    }
    .content-block {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .header {
        margin-bottom: 0;
      }
      hr {
        border-color: $color-border;
        margin: 0;
      }
    }
    .color-select-group {
      display: flex;
      flex-direction: column;
      gap: .25rem;
      padding-left: .5rem;
      h4.fs-h4 {
        margin-bottom: 0;
      }
    }
    .optional-input-collapse {
      &.not-collapsed {
        .collapse-btn .btn-icon {
          transform: translateY(-50%) rotate(180deg);
        }
      }
      .collapse-btn {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: .25rem;
        .btn-icon {
          position: absolute;
          top: 50%;
          right: .5rem;
          color: $color-dark;
          transform: translateY(-50%);
        }
      }
    }
    .detail-image-block {
      .header {
        display: flex;
        flex-direction: column;
        gap: .25rem;
        padding: .125rem 0 .125rem .5rem;
        h4.fs-h4 {
          letter-spacing: .125rem;
          margin-bottom: 0;
        }
      }
    }
    .common-block {
      display: flex;
      flex-direction: column;
      gap: .5rem;
      &.share {
        position: relative;
        gap: .25rem;
        padding: .5rem 0;
        margin-top: 1rem;
        .wc-switch {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }
      }
      h4.fs-h4 {
        letter-spacing: .125rem;
        margin-bottom: 0;
      }
    }
    .desc {
      color: $typography-secondary-default
    }
    .ps {
      color: $color-input-grey;
    }
    .warning {
      color: $color-warning;
    }
  }
  .imgs-section{
    .append-cloth-img {
      position: relative;
      margin-bottom: 1rem;
      .btn-outer {
        padding-top: 100%;
        border-radius: .5rem;
        border: dashed 2px $color-border;
        .btn-inner {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          row-gap: 1rem;
          .btn-img {

          }

          .btn-text {
            font-size: .8rem;
          }
        }
      }

      &.first-btn {
        .btn-outer {
          background-color: $color-main-yellow-light;
        }
      }
    }
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.create-cloth {
  .create-cloth-body {
    .images-group {
      .collection-image {
        .crop-image-button {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
    .col-half-group {
      display: flex;
      gap: 1rem;
    }
    .color-select-group {
      .color-select {
        .ball {
          border: solid 1px $color-text-disabled;
          border-radius: .5rem;
          overflow: hidden;
          &.active {
            border: solid 1px transparent;
            box-shadow: 0 0 0 2px $color-primary;
          }
        }
        .color {
          border-radius: 0;
        }
      }
    }
    .optional-input-collapse {
      .collapse {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding-top: 1rem;
      }
    }
    .custom-radio-group .bv-no-focus-ring {
      display: flex;
      &.col-form-label {
        letter-spacing: .125rem;
      }
      .custom-radio:first-child {
        display: none;
      }
      .custom-radio {
        width: 100%;
      }
    }
  }
}
</style>
