<template>
  <svg class="svg-icon" :width="getSize(width)" :height="getSize(height)"  aria-hidden="true">
    <use :xlink:href="`#${name}`" />
  </svg>
</template>

<script>
export default {
  name: 'Icon',
  data() {
    return {
    };
  },
  props: {
    name: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: '30'
    },
    width: {
      type: String,
    },
    height: {
      type: String,
    },
  },
  components: {
  },
  mounted() {

  },
  methods: {
    getSize(item) {
      return this[item] || this.size;
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
