var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"image-adjust"},[_c('canvas',{ref:"background-canvas",staticClass:"invisible-canvas"}),_c('Cropper',{ref:"cropper",class:{ 'twitter-cropper': true, 'cropping': _vm.canMove },attrs:{"src":_vm.imgData,"background-class":"twitter-cropper__background","foreground-class":"twitter-cropper__foreground","image-restriction":_vm.imageRestrict,"stencil-size":_vm.stencilSize,"resizeImage":_vm.resizeImageSetting,"moveImage":_vm.moveImageSetting,"stencil-props":{
      lines: {},
      handlers: {},
      movable: false,
      scalable: false,
      aspectRatio: _vm.cropRatio,
      previewClass: (_vm.stencilPadding>0)?'twitter-cropper__stencil show-stencil':'twitter-cropper__stencil',
    },"canvas":true,"default-size":_vm.defaultSize,"min-width":150,"min-height":150},on:{"change":function($event){return _vm.onChange($event)},"zoom":function($event){return _vm.onZoom($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }