<template>
  <div class="color-select">
    <ul>
      <li v-for="(item) in clothColors" :key="item.id">
        <div class="" @click="colorClicked(item.id)">
          <ColorCircle :id="item.id" :active="item.id === selectColorId" v-if="selection || item.id === selectColorId" :size="size"></ColorCircle>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ColorCircle from '@/components/closet/ColorCircle.vue';
export default {
  name: 'ColorSelect',
  data() {
    return {
    };
  },
  components: {
    ColorCircle,
  },
  created() {
  },
  props: {
    value: {
      type: Number,
      required: false,
      default: 0,
    },
    selection: {
      type: Boolean,
      required: false,
      default: true,
    },
    size: {
      type: Number,
      required: false,
      default: 24,
    }
  },
  async mounted() {
  },
  computed: {
    ...mapState(['clothColors']),
    selectColorId: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  watch: {
    value(val) {
      this.selectColorId = val;
    }
  },
  methods: {
    colorClicked(val) {
      this.selectColorId = val;
    },
  }
}
</script>

<style scoped>
  .color-select {
    overflow-x: auto;
    padding: 2px;
  }

  ul, li {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  li {
    flex: 0 0 auto;
  }

  .ball {
    display: block;
    width: 24px;
    height: 24px;
    border: solid 1px #eeeeee;
    border-radius: 50%;
    margin-right: 7px;
    overflow: hidden;
    position: relative;
  }

  .ball.active {
    /* box-shadow: 0 0 2px 0 #f00; */
    border: solid 2px #444;
  }

  .color {
    width: 100%;
    height: 100%;
    /* position: absolute; */
    /* top: 0; */
    /* left: 0; */
    border-radius: 50%;
  }

  .color.ball-12 {
    background: conic-gradient(from 226.43deg at 85.42% 110.42%, #808080 0deg, #9F9F9A 46.01deg, #DEDECF 86.17deg, #808080 360deg);
  }

  .color.ball-13 {
    background: conic-gradient(from 199.62deg at 72.92% 100%, #CAA628 -178.13deg, #E9D58F 128.39deg, #CAA628 181.87deg, #E9D58F 488.39deg);
  }

  .color.ball-14 {
    background: url('~@/assets/icons/color-other.png');
    background-size: contain;
  }

</style>
