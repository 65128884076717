<template>
  <div class="outfit-viewer">
    <div class="images-group">
      <Flickity ref="outfitFlickity" v-if="init" :options="flickityOptions">
        <div class="square-outer" v-for="(photo, index) in outfitViewData.outfitPhotos" :key="index">
          <img :src="photo.imgUrl" style="background-color: #eeeeee; object-fit: cover;" v-if="photo.type === 'photo'" alt="cloth" />
          <img :src="photo.cloth.imgs[0]" style="background-color: #eeeeee;" v-if="photo.type === 'cloth'" alt="cloth" />
          <div class="cloth-info-btn" v-if="photo.type === 'cloth' && isTester" @click="clothInfoClicked(index)">
            <Icon name="clothing-border" size="16" />
          </div>
        </div>
      </Flickity>

      <div class="cloths" v-if="hasPhoto">
        <div class="cloth-div" :class="{'active': showingIndex === index}" v-for="(photo, index) in outfitViewData.outfitPhotos" :key="index">
          <div class="square-outer" @click="navImgClicked(index)">
            <img :src="photo.imgUrl" style="background-color: #eeeeee; object-fit: cover;" v-if="photo.type === 'photo'" alt="cloth" />
            <img :src="photo.cloth.imgs[0]" style="background-color: #eeeeee;" v-if="photo.type === 'cloth'" alt="cloth" />
          </div>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="date fs-14px" v-text="formatViewDate"></div>
      <div class="head d-flex justify-content-between align-items-center">
        <h2 class="situation fs-h2" v-text="outfitViewData.situation ? outfitViewData.situation : ''"></h2>
        <div class="privacy">
          <Icon :name="outfitViewData.isPublic ? 'unlock' : 'lock'" size="16" />
          <span v-text="outfitViewData.isPublic ? '公開' : '私人'"></span>
        </div>
      </div>
      <div class="feeling" v-if="!outfitViewData.feelingUnknown">
        <Icon :name="formatFeelingIcon" size="16" />
        <span v-text="formatFeelingString"></span>
      </div>
      <div class="weather">
        <span class="weather-icons">{{outfitViewData.weather}}</span>
        <span v-text="formatWeatherString"></span>
        <span v-if="this.outfitViewData.temperatureSection !== -1">・<span v-text="formatTemperatureString"></span>°C</span>
      </div>
      <div class="location" v-if="outfitViewData.place">
        <Icon name="location" size="16" />
        <span v-text="outfitViewData.place"></span>
      </div>
      <div class="ps" v-if="outfitViewData.desc">
        <div class="title fs-14px fw-bold">備註</div>
        <div class="fs-14px" v-text="outfitViewData.desc"></div>
      </div>
      <div class="tag-list" v-if="outfitViewData.tags.length > 0">
        <div class="tag-item" v-for="(item, index) in outfitViewData.tags" :key="index">
          <span class="fs-14px" v-text="'#' + item"></span>
        </div>
      </div>
      <hr>
      <div class="share">
        <div class="fs-14px fw-bold">分享穿搭</div>
        <b-button class="share-btn" variant="outline-primary" @click="shareCopyText">
          <Icon name="copy" size="16" />
          <span>複製衣物品牌資訊</span>
        </b-button>
      </div>
    </div>

    <SemiOverlayModal v-model="isClothInfoModalShow" title="單品資訊" @close="closeClothInfoModal">
      <ClothSimpleInfo v-if="isClothInfoModalShow" :cloth="infoCloth" :drawer-data="drawerData" @drawerDeleted="getDrawerDataBySerial()" @drawerUpdated="getDrawerDataBySerial()" />
    </SemiOverlayModal>
  </div>
</template>

<script>
import Flickity from 'vue-flickity';
import outfitDefault from "@/dataModel/outfit";
import moment from "moment";
import {mapActions, mapGetters, mapState} from "vuex";
import SemiOverlayModal from "@/components/SemiOverlayModal.vue";
import ClothSimpleInfo from "@/components/outfit/ClothSimpleInfo.vue";

export default {
  name: 'OutfitViewer',
  data() {
    return {
      initBusyName: 'OutfitViewerInitReading',
      deleteBusyName: 'OutfitViewerDeleting',
      init: false,

      outfitViewData: {},
      clothsData: [],
      drawerListData: [],
      drawerData: null,

      showingIndex: 0,
      flickityOptions: {
        // initialIndex: 3,
        prevNextButtons: false,
        pageDots: false,
        // wrapAround: true
        on: {
          ready: () => {
            // console.log('Flickity ready');
          },
          select: (index) => {
            this.showingIndex = index;
          }
        }
      },

      isClothInfoModalShow: false,
      showingClothIndex: -1,
    };
  },
  components: {
    Flickity, SemiOverlayModal, ClothSimpleInfo,
  },
  props: {
  },
  computed: {
    ...mapState(['cloths','clothCategories']),
    ...mapGetters(['isTester','outfitCreateDefaultDate']),
    ...mapGetters('outfit', ['outfitId', 'outfitType', 'outfitEditStep']),

    hasPhoto() {
      return this.outfitViewData.outfitPhotos.length > 0;
    },
    formatViewDate() {
      return moment(this.outfitViewData.date).format('YYYY/MM/DD');
    },
    formatWeatherString() {
      const weatherStrings = ['晴天', '明月', '多雲', '晴時多雲', '雨', '雷雨', '強風', '寒流', '雪', '濃霧'];
      return weatherStrings[this.outfitViewData.weather];
    },
    formatTemperatureString() {
      if (this.outfitViewData.temperatureSection === -1) {
        return '';
      }
      const secs = [
        '-10~0',
        '1~10',
        '11~15',
        '16~20',
        '21~25',
        '26~30',
        '31~35',
        '36~40',
      ];
      return secs[this.outfitViewData.temperatureSection];
    },
    formatFeelingIcon() {
      switch(this.outfitViewData.feeling) {
        case 0:
          return 'emoji-happy';
        case 1:
          return 'emoji-fine';
        case 2:
          return 'emoji-sad';
        default:
          return '';
      }
    },
    formatFeelingString() {
      switch(this.outfitViewData.feeling) {
        case 0:
          return '穿搭感受佳';
        case 1:
          return '穿搭感受普通';
        case 2:
          return '穿搭感受不佳';
        default:
          return '';
      }
    },
    infoCloth() {
      try {
        if (this.showingClothIndex === -1) {
          return null;
        }
        return this.outfitViewData.outfitPhotos[this.showingClothIndex].cloth;
      } catch(e) {
        console.error(e);
      }
      return null;
    },
  },
  watch: {
  },
  created() {
    this.outfitViewData = Object.assign({}, this.outfitViewData, outfitDefault());
  },
  async mounted() {
    await this.refresh();
  },
  beforeDestroy() {
    this.clearComponentBusy(this.initBusyName);
    this.clearComponentBusy(this.deleteBusyName);
  },
  methods: {
    ...mapActions(['appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'updateCloth', 'updateOutfit']),
    ...mapActions('outfit', ['setOutfitData', 'setOutfitId', 'setOutfitType', 'setOutfitEditStep', 'clearOutfitData']),

    async refresh() {
      this.appendComponentBusy(this.initBusyName);
      try {
        const outfit = await this.$store.dispatch('api/readOutfitInfoPromise', this.outfitId);
        this.outfitViewData = Object.assign({}, this.outfitViewData, outfit);
        this.drawerListData = await this.$store.dispatch('api/getDrawerListPromise');

        // 取得所有 clothSerial
        const clothSerials = this.outfitViewData.outfitPhotos
            .filter(photo => photo.clothSerial) // 篩選出有 clothSerial 的物件
            .map(photo => photo.clothSerial);   // 提取 clothSerial 值

        const clothDataArray = await Promise.all(
            clothSerials.map(serial => this.$store.dispatch('api/readClothInfoPromise', serial))
        );

        // 更新 clothsData 陣列
        this.clothsData = clothDataArray.map(cloth => {
          const matchedCategory1 = this.clothCategories.find(category => category.serial === cloth.category1);
          const matchedCategory2 = matchedCategory1.sub.find(category => category.serial === cloth.category2);
          return {
            ...cloth,
            category1: matchedCategory1 ? matchedCategory1.name : cloth.category1, // 若找到則替換，否則保留原值
            category2: matchedCategory2 ? matchedCategory2.name : cloth.category2, // 若找到則替換，否則保留原值
          };
        });

        this.init = true;
      } catch(e) {
        this.appendErrorMsg(e.toString());
      } finally {
        this.clearComponentBusy(this.initBusyName);
      }
    },
    clothInfoClicked(index) {
      this.showingClothIndex = index;
      this.getDrawerDataBySerial(this.infoCloth.serial);
      this.isClothInfoModalShow = true;
    },
    closeClothInfoModal() {
      this.isClothInfoModalShow = false;
      this.showingClothIndex = -1;
      this.drawerData = null;
    },
    navImgClicked(index) {
      this.$refs['outfitFlickity'].select( index );
    },
    shareCopyText() {
      if (!this.clothsData || this.clothsData.length === 0) {
        this.appendErrorMsg('複製失敗！找不到可以分享的穿搭資訊！');
        return;
      }

      // 格式化文字，每個物件的資料間隔一排空行
      const textToCopy = this.clothsData
          .map(item => `${item.category1}/${item.category2} ${item.brands?.[0] || ''}`)
          .join('\n\n'); // 兩行換行符號，確保 IG 貼文有間隔

      // 建立一個隱藏的 textarea 來執行複製
      const textarea = document.createElement('textarea');
      textarea.value = textToCopy;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);

      this.appendErrorMsg('已複製！現在可以貼到 Instagram 囉！');
    },
    getDrawerDataBySerial(serial) {
      const clothItem = this.cloths.find(item => item.serial === serial);
      this.drawerData = clothItem
          ? this.drawerListData.find(drawer => drawer.serial === clothItem.drawerSerial) || null
          : null;
      console.log(123)
      console.log(this.drawerData)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
@font-face {
  font-family: Wweather;
  src: url('~@/assets/font/Writes_weather.ttf');
}
.weather-icons {
  font-family: Wweather;
  margin-right: .5rem;
}
.outfit-viewer {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  padding: 1rem;
  .cloths {
    margin-top: 6px;
    width: 100%;
    overflow-x: auto;
    display: flex;
    flex-wrap: nowrap;
    margin-left: -3px;
    margin-right: -3px;
  }
  .cloth-div {
    position: relative;
    margin: 0 3px;
    flex: 0 0 23%;
    width: 23%;
    border-radius: .5rem;
    overflow: hidden;
    &.active {
      border: solid 2px var(--main-orange);
    }
  }
  .square-outer {
    position: relative;
    width: 100%;
    padding-top: 100%;
    &>img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    &>svg {
      position: absolute;
      width: 1rem;
      height: 1rem;
      top: 4px;
      right: 4px;
    }
  }
  //.float-div {
  //  position: absolute;
  //  right: 1rem;
  //  bottom: 1rem;
  //}
  .cloth-info-btn {
    cursor: pointer;
    position: absolute;
    left: 1.25rem;
    bottom: 1.25rem;
    display: flex;
    background: $color-white;
    border-radius: 100px;
    padding: .5rem;
    &:active {
      filter: brightness(.95);
    }
  }
  .content, .ps {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    color: $typography-primary-default;
    padding: .5rem 0;
  }
  .head {
    padding: .5rem 0;
  }
  .situation {
    margin: 0;
  }
  .privacy {
    display: flex;
    align-items: center;
    gap: .125rem;
    color: $typography-secondary-default;
  }
  .weather {
    display: flex;
    align-items: center;
  }
  .feeling, .location {
    display: flex;
    align-items: center;
    gap: .5rem;
  }
  .tag-list {
    display: flex;
    gap: .5rem;
    .tag-item {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      gap: .25rem;
      flex-shrink: 0;
      color: $typography-text-link;
      background: $color-white;
      border: solid .5px $typography-disabled;
      border-radius: 100px;
      padding: .25rem .5rem;
    }
  }
  hr {
    width: 100%;
    margin: .5rem 0;
  }
  .share {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .share-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: .5rem;
    }
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.outfit-viewer {
  .images-group {
    .flickity-viewport {
      border-radius: 1rem;
      overflow: hidden;
    }
  }
}
</style>
