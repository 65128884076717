import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment';

import ApiModule from './modules/api.js';
import UpdatePanelModule from './modules/updatePanel.js';
import EventModule from './modules/event.js';
import WalletModule from './modules/wallet.js';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    api: ApiModule,
    updatePanel: UpdatePanelModule,
    event: EventModule,
    wallet: WalletModule,
  },
  state: {
    debug: true,
    storeBusy: [],

    // token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwibmFtZSI6Ik5FTyBBdXN0aW4iLCJlbWFpbCI6Inp4YXVzdGluQGdtYWlsLmNvbSIsImlhdCI6MTY3MDQ2ODUwOH0.slTdVYWlns2Jpu9zPOXOQXLzCQlEiktClGSRKmmEHQo',
    token: '',
    TOKENCOOKIEKEY: 'wc_token',
    lineClientId: '1654960367',
    fbClientId: '335482275440127',

    user: {
      serial: -1,
    },
    cloths: [],
    outfits: [],
    clothColors: [],
    clothCategories: [],
    msgModalContent: '',
    errMsgs: [],

    outfitCalendarDate: '',

    configModals: {
      main: false,
      member: false,
      body: false,

      addFriend: false,
    },

    createModals: {
      outfit: false,
    },

    tempHideNav: false,
    tempHideBottomNav: false,

    policyModalShow: false,
    buyFlowModalShow: false,
    exchangeModalShow: false,
    resetPasswordModalShow: false,
  },
  getters: {
    hasToken(state) {
      return state.token.length > 0;
    },
    userToken(state) {
      return state.token;
    },
    isStoreBusy(state) {
      return state.storeBusy.length > 0;
    },
    isMsgModalShow(state) {
      return state.msgModalContent.length > 0;
    },
    recentBrands(state) {
      const recentTags = [];
      for (let i=state.cloths.length - 1; i >= 0 ; i--) {
        for (const item of state.cloths[i].brands) {
          if (recentTags.indexOf(item) === -1) {
            recentTags.push(item);
            if (recentTags.length >= 12) {
              return recentTags;
            }
          }
        }
      }
      return recentTags;
    },
    recentTags(state) {
      const recentTags = [];
      for (let i=state.cloths.length - 1; i >= 0 ; i--) {
        for (const item of state.cloths[i].tags) {
          if (recentTags.indexOf(item) === -1) {
            recentTags.push(item);
            if (recentTags.length >= 5) {
              return recentTags;
            }
          }
        }
      }
      return recentTags;
    },

    // policy
    isPolicyShow(state) {
      return state.policyModalShow;
    },

    isBuyFlowShow(state) {
      return state.buyFlowModalShow;
    },
    isExchangeShow(state) {
      return state.exchangeModalShow;
    },

    isResetPasswordShow(state) {
      return state.resetPasswordModalShow
    },

    // temp nav hide
    isNavHide(state) {
      return state.tempHideNav;
    }, 
    isBottomNavHide(state) {
      return state.tempHideBottomNav;
    }, 

    // user
    loginUserSerial(state) {
      return state.user.serial;
    },

    // config modal
    isMainConfigShow(state) {
      return state.configModals.main;
    },
    isMemberConfigShow(state) {
      return state.configModals.member;
    },
    isBodyConfigShow(state) {
      return state.configModals.body;
    },
    isAddFriendShow(state) {
      return state.configModals.addFriend;
    },

    // create modal
    isCreateOutfitShow(state) {
      return state.createModals.outfit;
    },

    outfitCreateDefaultDate(state) {
      return state.outfitCalendarDate.length > 0? state.outfitCalendarDate: moment().format('YYYY-MM-DD');
    },
    clothWithWearCount(state) {
      const res = [];
      for (const c of state.cloths) {
        const c_copy = { ... c};
        c_copy.wearCount = state.outfits.reduce((sum, outfit) => {
          if (!outfit.available) {
            return sum;
          }
          for (const outfitPhoto of outfit.outfitPhotos) {
            if (outfitPhoto.type === 'cloth' && outfitPhoto.clothSerial === c_copy.serial) {
              return sum + 1;
            }
          }
          return sum;
        }, 0);
        res.push(c_copy);
      }
      return res;
    },
  },
  mutations: {
    UPDATETOKEN(state, token) {
      state.token = token;
      Vue.$cookies.set(state.TOKENCOOKIEKEY, token, "30d");
    },
    CLEARTOKEN(state) {
      state.token = '';
      Vue.$cookies.remove(state.TOKENCOOKIEKEY);
    },
    APPENDBUSY(state, payload) {
      const index = state.storeBusy.indexOf(payload);
      if (index === -1) {
        state.storeBusy.push(payload);
      }
    },
    CLEARBUSY(state, payload) {
      const index = state.storeBusy.indexOf(payload);
      if (index !== -1) {
        state.storeBusy.splice(index, 1);
      }
    },
    SHOWMSGMODAL(state, payload) {
      state.msgModalContent = payload.toString();
    },
    CLEANMSGMODAL(state) {
      state.msgModalContent = '';
    },
    APPENDERRORMSG(state, errMsg) {
      state.errMsgs.push(errMsg);
    },
    CLEARERRORMSG(state) {
      state.errMsgs.splice(0, state.errMsgs.length);
    },

    SETCLOTHS(state, payload) {
      state.cloths.splice(0, state.cloths.length);
      for (const cloth of payload) {
        state.cloths.push(cloth);
      }
    },
    SETOUTFITS(state, payload) {
      state.outfits.splice(0, state.outfits.length);
      for (const outfit of payload) {
        state.outfits.push(outfit);
      }
    },
    SETCLOTHCATEGORIES(state, payload) {
      state.clothCategories.splice(0, state.clothCategories.length);
      for (const category of payload) {
        state.clothCategories.push(category);
      }
    },
    SETCLOTHCOLORS(state, colors) {
      state.clothColors.splice(0, state.clothColors.length);
      for (const color of colors) {
        state.clothColors.push(color);
      }
    },
    UPDATECLOTH(state, newCloth) {
      for (let i = 0; i < state.cloths.length ; i++) {
        const c = state.cloths[i];
        if (c.serial === newCloth.serial) {
          state.cloths.splice(i, 1, newCloth);
          return;
        }
      }
      state.cloths.push(newCloth);
    },
    UPDATEOUTFIT(state, newOutfit) {
      for (let i = 0; i < state.outfits.length ; i++) {
        const c = state.outfits[i];
        if (c.serial === newOutfit.serial) {
          state.outfits.splice(i, 1, newOutfit);
          return;
        }
      }
      state.outfits.push(newOutfit);
    },
    CHANGECONFIFMODAL(state, configSetting) {
      state.configModals[configSetting.name] = configSetting.value;
    },
    CHANGECREATEMODAL(state, createSetting) {
      state.createModals[createSetting.name] = createSetting.value;
    },
    CHANGEPOLICYMODAL(state, policyShow) {
      state.policyModalShow = policyShow;
    },
    CHANGEBUYFLOWMODAL(state, buyFlowShow) {
      state.buyFlowModalShow = buyFlowShow;
    },
    CHANGEEXCHANGEMODAL(state, exchangeShow) {
      state.exchangeModalShow = exchangeShow;
    },
    CHANGERESETPASSWORDMODAL(state, resetPasswordShow) {
      state.resetPasswordModalShow = resetPasswordShow;
    },
    SETCALENDARDATE(state, date) {
      state.outfitCalendarDate = date;
    },
    UPDATEUSER(state, user) {
      state.user = Object.assign({}, state.user, user);
    },
    SETNAVHIDE(state, hide) {
      state.tempHideNav = hide;
    },
    SETBOTTOMNAVHIDE(state, hide) {
      state.tempHideBottomNav = hide;
    },
  },
  actions: {
    showMsgModal(context, msg) {
      context.commit("SHOWMSGMODAL", msg);
    },
    clearMsg(context) {
      context.commit('CLEANMSGMODAL');
    },

    async initLoginToken(context) {
      const savedToken = Vue.$cookies.get(context.state.TOKENCOOKIEKEY);

      if (savedToken !== null) {
        try {
          const newToken = await context.dispatch('api/postRefreshToken', savedToken);
          await context.dispatch('login', newToken.token);
        } catch(e) {
          console.error(e);
          context.dispatch('logout');
        }
      }
    },
    async login(context, token) {
      const busyName = 'LOGIN';
      context.commit("APPENDBUSY", busyName);
      context.commit('UPDATETOKEN', token);
      try {
        await context.dispatch('refreshUser');
        await context.dispatch('refreshCloths');
        await context.dispatch('refreshOutfits');
        await context.dispatch('refreshClothCategories');
        await context.dispatch('refreshClothColors');
      } catch (e) {
        // 可能token有誤
        context.dispatch('showMsgModal', '登入可能有誤，請重新登入');
        context.dispatch('logout');
      } finally {
        context.commit("CLEARBUSY", busyName);
      }
    },
    logout(context) {
      context.commit('CLEARTOKEN');
    },
    async refreshUser(context) {
      try {
        const user = await context.dispatch('api/getUserDataPromise');
        if (user.needResetPassword) {
          context.commit('CHANGERESETPASSWORDMODAL', true);
        }
        context.commit("UPDATEUSER", user);
      } catch (e) {
        console.error(e);
      }
    },
    async refreshCloths(context) {
      const busyName = 'REFRESHCLOTHS';
      context.commit("APPENDBUSY", busyName);
      try {
        const cloths = await context.dispatch('api/readClothListPromise');
        context.commit("SETCLOTHS", cloths);
      } catch (e) {
        console.error(e);
      } finally {
        context.commit("CLEARBUSY", busyName);
      }
    },
    async refreshOutfits(context) {
      const busyName = 'REFRESHOUTFITS';
      context.commit("APPENDBUSY", busyName);
      try {
        const outfits = await context.dispatch('api/readOutfitListPromise');
        context.commit("SETOUTFITS", outfits);
      } catch (e) {
        console.error(e);
      } finally {
        context.commit("CLEARBUSY", busyName);
      }
    },
    async refreshClothCategories(context) {
      const busyName = 'REFRESHCLOTHCATEGORIES';
      context.commit("APPENDBUSY", busyName);
      try {
        const categories = await context.dispatch('api/readClothCategoryPromise');
        context.commit("SETCLOTHCATEGORIES", categories);
      } catch (e) {
        console.error(e);
      } finally {
        context.commit("CLEARBUSY", busyName);
      }
    },
    async refreshClothColors(context) {
      const busyName = 'REFRESHCLOTHCOLORS';
      context.commit("APPENDBUSY", busyName);
      try {
        const colors = await context.dispatch('api/readClothColorPromise');
        context.commit("SETCLOTHCOLORS", colors);
      } catch (e) {
        console.error(e);
      } finally {
        context.commit("CLEARBUSY", busyName);
      }
    },
    updateCloth(context, cloth) {
      context.commit("UPDATECLOTH", cloth);
    },

    appendComponentBusy(context, busyName) {
      context.commit("APPENDBUSY", busyName);
    },
    clearComponentBusy(context, busyName) {
      context.commit("CLEARBUSY", busyName);
    },
    appendErrorMsg(context, errMsg) {
      context.commit("APPENDERRORMSG", errMsg);
    },

    // policy
    setPolicyShow(context, policyShow) {
      context.commit("CHANGEPOLICYMODAL", policyShow);
    },
    setBuyFlowShow(context, buyFlowShow) {
      context.commit("CHANGEBUYFLOWMODAL", buyFlowShow);
    },
    setExchangeShow(context, exchangeShow) {
      context.commit("CHANGEEXCHANGEMODAL", exchangeShow);
    },
    setResetPasswordShow(context, flag) {
      context.commit("CHANGERESETPASSWORDMODAL", flag);
    },

    // config
    setMainConfigShow(context, show) {
      context.commit("CHANGECONFIFMODAL", {
        name: 'main',
        value: show,
      });
    },
    setMemberConfigShow(context, show) {
      context.commit("CHANGECONFIFMODAL", {
        name: 'member',
        value: show,
      });
    },
    setBodyConfigShow(context, show) {
      context.commit("CHANGECONFIFMODAL", {
        name: 'body',
        value: show,
      });
    },
    setAddFriendShow(context, show) {
      context.commit("CHANGECONFIFMODAL", {
        name: 'addFriend',
        value: show,
      });
    },

    // create modal
    setCreateOutfitShow(context, show) {
      context.commit("CHANGECREATEMODAL", {
        name: 'outfit',
        value: show,
      });
    },

    checkEmailValid(context, email) {
      const re = /\S+@\S+/;
      return re.test(email);
    },
    queryCategoryName(context, categoryId) {
      const cs = context.state.clothCategories;
      for (const c of cs) {
        if (c.serial === categoryId) {
          return c.name;
        }
        for (const s of c.sub) {
          if (s.serial === categoryId) {
            return s.name;
          }
        }
      }
      return '';
    },

    // temp nav hide
    hideNav(context) {
      context.commit('SETNAVHIDE', true);
    },
    showNav(context) {
      context.commit('SETNAVHIDE', false);
    },
    hideBottomNav(context) {
      context.commit('SETBOTTOMNAVHIDE', true);
    },
    showBottomNav(context) {
      context.commit('SETBOTTOMNAVHIDE', false);
    },
  },
})
