<template>
  <div class="upload-mask">
    <slot></slot>
    <div class="mask-body" v-if="value !== -1">
      <div class="msg-box">
        <div class="fs-16px">處理中，請等待...</div>
        <b-progress
            :value="value"
            :animated="value > -1"
            variant="warning"
            :max="100"
            show-value
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UploadMask',
  data() {
    return {

    };
  },
  props: {
    value: {
      type: Number,
      required: true,
      default: -1,
    },
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.upload-mask {
  position: relative;
  .mask-body {
    position: absolute;
    width: 100%;
    height: 100%;
    background: $color-bk-tp-60;
    border-radius: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .msg-box {
      display: flex;
      flex-direction: column;
      gap: .5rem;
      color: $color-notice;
      width: 80%;
    }
  }
}
</style>
<style lang="scss">
.upload-mask {
}
</style>
