<template>
    <div class="home-closet-data">

      <div class="panel-card">
        <p class="panel-title">本周<br/>新增單品</p>
        <div class="py-0">
          <p class="panel-value">{{clothThisWeekCount}}</p>
        </div>
        <p class="panel-amount">NTD</p>
        <p class="panel-amount">{{clothThisWeekValue}}</p>
      </div>


      <div class="panel-card">
        <p class="panel-title">衣物<br/>總數量</p>
        <div class="py-0">
          <p class="panel-value">{{totalCount}}</p>
        </div>
        <p class="panel-amount">NTD</p>
        <p class="panel-amount">{{totalValue}}</p>
      </div>
      
      <div class="panel-card">
        <p class="panel-title">穿搭紀錄<br/>總數量</p>
        <div class="py-0">
          <p class="panel-value">{{totalOutfit}}</p>
        </div>
        <!-- <p class="panel-amount">NTD</p>
        <p class="panel-amount">{{totalValue}}</p> -->
      </div>

    </div>
  </template>
  
  <script>
  import { mapState } from 'vuex';
  import moment from 'moment';
  
  export default {
    name: 'HomeClosetData',
    data() {
      return {
        outfits: [],
      }
    },
    components: {
    },
    created() {
    },
    computed: {
      ...mapState(['cloths']),
      inUseCloths() {
        const res = this.cloths.filter((cloth) => {
          return !cloth.isDeleted && cloth.recycleType === -1;
        });
        return res;
      },
      totalCount() {
        return this.inUseCloths.length;
      },
      totalValue() {
        const res = this.inUseCloths.reduce((sum, cloth) => {
          if (cloth.price > 0) {
            sum = sum + cloth.price;
          }
          return sum;
        }, 0);
        return res.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      clothThisWeek() {
        let weekStart = moment();
        weekStart = weekStart.subtract(weekStart.day(), 'days').set({'hour': 0, 'minute': 0, 'second': 0});
        const res = this.inUseCloths.filter((cloth) => {
          const m = moment(cloth.create_at);
          return m.isSameOrAfter(weekStart);
        });
        return res;
      },
      clothThisWeekCount() {
        return this.clothThisWeek.length;
      },
      clothThisWeekValue() {
        const res = this.clothThisWeek.reduce((sum, cloth) => {
          if (cloth.price > 0) {
            sum = sum + cloth.price;
          }
          return sum;
        }, 0);
        return res.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      totalOutfit() {
        return this.outfits.length;
      },
    },
    async mounted() {
      try {
        const list = await this.$store.dispatch('api/readOutfitListPromise');
        this.outfits.splice(0, this.outfits.length);
        for (const outfit of list) {
          this.outfits.push(outfit);
        }
      } catch(err) {
        this.$store.dispatch('appendErrorMsg', err.toString());
      }
    },
    props: {
    },
    watch: {
    },
    methods: {
  
    }
  
  }
  </script>
  
  <style scoped>
    p {
      margin: 0;
    }
  
    .home-closet-data {
      display: grid;
      column-gap: .5rem;
      row-gap: .5rem;
      grid-template-columns: repeat(3, 1fr);
      /* grid: auto / auto auto auto; */
    }
  
    .panel-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: .5rem;
      padding: 14px 0;
      background-color: #D8EEEA;
      color: #4D4D4D;
      box-shadow: 4px 4px 8px rgba(196, 196, 196, 0.51);
    }
  
    .panel-title {
      text-align: center;
      font-size: 13px;
      font-weight: 1000;
      color: #4D4D4D;
    }
  
    .panel-value {
      font-size: 20px;
      font-weight: 1000;
    }
  
    .panel-amount {
      color: #8D8D8D;
      font-size: 12px;
      line-height: 1;
      letter-spacing: .15em;
    }
  
    .color-panel {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: .5rem;
      padding: 8px;
      background-color: #fff;
      font-size: 12px;
    }
  
    .color-row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  </style>
  