<template>
  <div class="wc-custom-select">
    <select v-model="selectingValue" class="" ref="selector" :class="{'defaultStyle': isDefaultValue && !focusing}" @focus="inputFocus" @blur="inputBlur">
      <option v-for="(item) in optionObjects" :key="item.value" :value="item.value">{{item.name}}</option>
    </select>
  </div>
</template>

<script>

export default {
  name: 'custom-select',
  data() {
    return {
      init: false,
      // selectingValue: '',
      focusing: false,
    }
  },
  created() {
    // this.selectingValue = this.value;
  },
  computed: {
    selectingValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    isDefaultValue() {
      return this.selectingValue === -1;
    },
  },
  mounted() {
    this.init = true;
  },
  props: {
    optionObjects: {
      type: Array,
      required: true,
    },
    value: {
      required: false,
      default: '',
    }
  },
  watch: {
    // value(val) {
    //   if (this.selectingValue !== val) {
    //     this.selectingValue = val;
    //   }
    // },
  },
  methods: {
    inputFocus() {
      this.focusing = true;
    },
    inputBlur() {
      this.focusing = false;
    },
    // changed() {
    //   // console.log($evt);
    //   this.$emit('input', this.selectingValue);
    // }
  }

}
</script>

<style scoped>
  .wc-custom-select {
    /* background-color: #eeeeee; */
    border: none;
    /* border-radius: 0.25rem; */
  }

  .wc-custom-select>select {
    border-radius: 0.25rem;
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.1rem 2rem 0.1rem 0.75rem;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    color: #444;
    vertical-align: middle;
    background: #eeeeee url('~@/assets/icons/select-down.png') right .25rem center/24px 24px no-repeat;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .wc-custom-select>select.defaultStyle {
    color: #C4C4C4;
  }
</style>
