<template>
  <transition name="fade">
    <FullScreenModal v-if="isBodyConfigShow" title="我的身體數據" @back="configCancel">
      <div class="body-config">
        <ul class="config m-0">
          <li class="sect">
            填寫身體數據，即可在數據快覽中查閱
          </li>
        </ul>

        <div class="config-form">
          <div class="config-row">
            <div class="row align-items-center no-gutters">
              <div class="col-auto pl-2">
                <label class="mr-3">身高</label>
              </div>
              <div class="col pl-2">
                <div class="row no-gutters">
                  <div class="col-8 pr-2">
                    <custom-text-input type="number" v-model="body.height"></custom-text-input>
                  </div>

                  <div class="col">
                    cm
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="config-row">
            <div class="row align-items-center no-gutters">
              <div class="col-auto pl-2">
                <label class="mr-3">體重</label>
              </div>
              <div class="col pl-2">
                <div class="row no-gutters">
                  <div class="col-8 pr-2">
                    <custom-text-input type="number" v-model="body.weight"></custom-text-input>
                  </div>

                  <div class="col">
                    kg
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="config-row">
            <div class="row align-items-start no-gutters">
              <div class="col-auto pl-2">
                <label class="mr-3 pt-2">胸圍</label>
              </div>
              <div class="col pl-2">
                <div class="row no-gutters">
                  <div class="col-8 pr-2">
                    <custom-select :optionObjects="chestSizeOpt" v-model="body.chestSize">
                    </custom-select>
                    <a href="#" class="how-to" @click.prevent="howToClicked(2)">如何測量</a>
                  </div>

                  <div class="col">
                    <custom-select :optionObjects="cupOpt" v-model="body.opp">
                    </custom-select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="config-row">
            <div class="row align-items-start no-gutters">
              <div class="col-auto pl-2">
                <label class="mr-3 pt-2">肩寬</label>
              </div>
              <div class="col pl-2">
                <div class="row no-gutters">
                  <div class="col-8 pr-2">
                    <custom-select :optionObjects="shoulderSizeOpt" v-model="body.shoulderSize">
                    </custom-select>
                    <a href="#" class="how-to" @click.prevent="howToClicked(1)">如何測量</a>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div class="config-row">
            <div class="row align-items-start no-gutters">
              <div class="col-auto pl-2">
                <label class="mr-3 pt-2">腰圍</label>
              </div>
              <div class="col pl-2">
                <div class="row no-gutters">
                  <div class="col-8 pr-2">
                    <custom-select :optionObjects="waistSizeCmOpt" v-show="waistSizeType === 0" v-model="body.waistSize">
                    </custom-select>
                    <custom-select :optionObjects="waistSizeInchOpt" v-show="waistSizeType === 1" v-model="body.waistSize">
                    </custom-select>
                    <a href="#" class="how-to" @click.prevent="howToClicked(3)">如何測量</a>
                  </div>

                  <div class="col">
                    <custom-select :optionObjects="waistSizeTypeOpt" v-model="waistSizeType">
                    </custom-select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="config-row">
            <div class="row align-items-start no-gutters">
              <div class="col-auto pl-2">
                <label class="mr-3 pt-2">臀圍</label>
              </div>
              <div class="col pl-2">
                <div class="row no-gutters">
                  <div class="col-8 pr-2">
                    <custom-select :optionObjects="buttockSizeOpt" v-model="body.buttockSize">
                    </custom-select>
                    <a href="#" class="how-to" @click.prevent="howToClicked(4)">如何測量</a>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div class="config-row">
            <div class="row align-items-start no-gutters">
              <div class="col-auto pl-2">
                <label class="mr-3 pt-2">腳長</label>
              </div>
              <div class="col pl-2">
                <div class="row no-gutters">
                  <div class="col-6 pr-2">
                    <custom-select :optionObjects="feetSizeCmOpt" v-show="feetSizeType === 0" v-model="body.feetSize">
                    </custom-select>
                    <custom-select :optionObjects="feetSizeEuOpt" v-show="feetSizeType === 1" v-model="body.feetSize">
                    </custom-select>
                    <custom-select :optionObjects="feetSizeUsMaOpt" v-show="feetSizeType === 2" v-model="body.feetSize">
                    </custom-select>
                    <custom-select :optionObjects="feetSizeUsFeOpt" v-show="feetSizeType === 3" v-model="body.feetSize">
                    </custom-select>
                    <custom-select :optionObjects="feetSizeJaMaOpt" v-show="feetSizeType === 4" v-model="body.feetSize">
                    </custom-select>
                    <custom-select :optionObjects="feetSizeJaFeOpt" v-show="feetSizeType === 5" v-model="body.feetSize">
                    </custom-select>
                    <!-- <a href="#" class="how-to">如何測量</a> -->
                  </div>

                  <div class="col">
                    <custom-select :optionObjects="feetSizeTypeOpt" v-model="feetSizeType">
                    </custom-select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="config-row">
            <div class="row align-items-start no-gutters">
              <div class="col-auto pl-2">
                <label class="mr-3 pt-2">頭圍</label>
              </div>
              <div class="col pl-2">
                <div class="row no-gutters">
                  <div class="col-8 pr-2">
                    <custom-select :optionObjects="headSizeCmOpt" v-show="headSizeType === 0" v-model="body.headSize">
                    </custom-select>
                    <custom-select :optionObjects="headSizeUsOpt" v-show="headSizeType === 1" v-model="body.headSize">
                    </custom-select>
                    <a href="#" class="how-to" @click.prevent="howToClicked(0)">如何測量</a>
                  </div>

                  <div class="col">
                    <custom-select :optionObjects="headSizeTypeOpt" v-model="headSizeType">
                    </custom-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ul class="config m-0">
          <li class="sect">
            實穿品牌尺寸
          </li>
        </ul>

        <div class="config-form">

          <div class="row no-gutters mt-2">
            <div class="col-12 mb-2">
              <custom-text-input type="text" v-model="inputBrandSize.brandName" placeholder="品牌名稱，例: uniqlo"></custom-text-input>
            </div>

            <div class="col-5 pr-2 mb-2">
              <custom-text-input type="text" v-model="inputBrandSize.sizeType" placeholder="尺寸類別，例: 上身"></custom-text-input>
            </div>

            <div class="col-5 pr-2 mb-2">
              <custom-text-input type="text" placeholder="尺寸資料，例: M" v-model="inputBrandSize.size"></custom-text-input>
            </div>

            <div class="col-2 mb-2">
              <button class="btn btn-sm btn-outline-dark" @click="saveBrandSize">新增</button>
            </div>

          </div>

          <BrandSizeContainer v-model="brandSizeList"></BrandSizeContainer>
        </div>

        <button class="horizon-btn mt-3" @click="saveClicked">確定送出</button>

        <transition name="fade">
          <WcModal v-if="isHowToModalShow" @backClick="howToClose">
            <div class="how-to-container">
              <img :src="howToImg" />
            </div>
          </WcModal>
        </transition>

      </div>
    </FullScreenModal>
  </transition>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import '@/assets/css/config-ul.css';
import '@/assets/css/buttons.css';
import '@/assets/css/form-inputs.css';
import FullScreenModal from '@/components/FullScreenModal.vue';
import WcModal from '@/components/commons/WcModal.vue';
import BrandSizeContainer from '@/components/body/BrandSizeContainer.vue'

import HeadHowTo from '@/assets/howto/head.png';
import ChestHowTo from '@/assets/howto/chest.png';
import ShoulderHowTo from '@/assets/howto/shoulder.png';
import WaistHowTo from '@/assets/howto/waist.png';
import ButtockHowTo from '@/assets/howto/buttock.png';

export default {
  name: 'BodyConfig',
  data() {
    return {
      readingBusyName: 'READINGUSERBODYDATA',
      savingBusyName: 'UPDATINGUSERBODYDATA',
      isLogoutModalShow: false,
      isHowToModalShow: false,

      howToIndex: 0,

      waistSizeType: 0,
      feetSizeType: 5,
      headSizeType: 0,

      brandSizeList: [],
      deleteTargetIndex: -1,

      cupOpt: [
        {
          value: '',
          name: '未設定',
        },
        {
          value: 'A',
          name: 'A',
        },
        {
          value: 'B',
          name: 'B',
        },
        {
          value: 'C',
          name: 'C',
        },
        {
          value: 'D',
          name: 'D',
        },
        {
          value: 'E',
          name: 'E',
        },
        {
          value: 'F',
          name: 'F',
        },
        {
          value: 'G',
          name: 'G',
        },
        {
          value: 'G+',
          name: 'G以上',
        }
      ],
      waistSizeTypeOpt: [
        {
          value: 0,
          name: 'cm',
        },
        {
          value: 1,
          name: 'inch',
        },
      ],
      feetSizeTypeOpt: [
        {
          value: 0,
          name: 'cm',
        },
        {
          value: 1,
          name: '歐洲鞋',
        },
        {
          value: 2,
          name: '美國男鞋',
        },
        {
          value: 3,
          name: '美國女鞋',
        },
        {
          value: 4,
          name: '亞洲男鞋',
        },
        {
          value: 5,
          name: '亞洲女鞋',
        },
      ],
      feetTable: [
        [22.8,35,3.5,5,21.5,21],
        [23.1,35.5,4,5.5,22,21.5],
        [23.5,36,4.5,6,22.5,22],
        [23.8,37,5,6.5,23,22.5],
        [24.1,37.5,5.5,7,23.5,23],
        [24.5,38,6,7.5,24,23.5],
        [24.8,38.5,6.5,8,24.5,24],
        [25.1,39,7,8.5,25,24.5],
        [25.4,40,7.5,9,25.5,25],
        [25.7,41,8,9.5,26,25.5],
        [26,42,8.5,10,26.5,26],
        [26.7,43,9,10.5,27.5,27],
        [27.3,44,10.5,12,28.5,28],
        [27.9,45,11.5,13,29.5,29],
        [28.6,46.5,12.5,14,30.5,30],
        [29.2,48.5,14,15.5,31.5,31],
      ],
      headSizeTypeOpt: [
        {
          value: 0,
          name: 'cm',
        },
        {
          value: 1,
          name: 'US尺寸',
        },
      ],

      body: {
        buttockSize: 0,
        chestSize: 0,
        feetSize: 0,
        headSize: 0,
        height: 0,
        opp: "",
        shoulderSize: 0,
        waistSize: 0,
        weight: 0,
      },

      inputBrandSize: {
        brandName: '',
        sizeType: '',
        size: '',
      },
    };
  },
  components: {
    FullScreenModal,
    WcModal,
    BrandSizeContainer,
  },
  props: {
  },
  beforeDestroy() {
    this.clearComponentBusy(this.readingBusyName);
    this.clearComponentBusy(this.savingBusyName);
  },
  async mounted() {

  },
  computed: {
    ...mapGetters(['isBodyConfigShow']),
    chestSizeOpt() {
      const opt = [];
      for (let i = 71 ; i <= 136 ; i ++ ) {
        opt.push({
          value: i,
          name: `${i} cm`,
        });
      }
      return opt;
    },
    shoulderSizeOpt() {
      const opt = [];
      for (let i = 34 ; i <= 55 ; i ++ ) {
        opt.push({
          value: i,
          name: `${i} cm`,
        });
      }
      return opt;
    },
    waistSizeCmOpt() {
      const opt = [];
      for (let i = 18 ; i <= 48 ; i ++ ) {
        opt.push({
          value: i,
          name: `${(i * 2.54).toFixed(1)} cm`,
        });
      }
      return opt;
    },
    waistSizeInchOpt() {
      const opt = [];
      for (let i = 18 ; i <= 48 ; i ++ ) {
        opt.push({
          value: i,
          name: `${i} inch`,
        });
      }
      return opt;
    },
    buttockSizeOpt() {
      const opt = [];
      for (let i = 85 ; i <= 117 ; i ++ ) {
        opt.push({
          value: i,
          name: `${i} cm`,
        });
      }
      return opt;
    },
    feetSizeCmOpt() {
      const opt = [];
      for (let i = 0 ; i < this.feetTable.length ; i ++ ) {
        const row = this.feetTable[i];
        opt.push({
          value: row[0],
          name: `${row[0]} cm`,
        });
      }
      return opt;
    },
    feetSizeEuOpt() {
      const opt = [];
      for (let i = 0 ; i < this.feetTable.length ; i ++ ) {
        const row = this.feetTable[i]
        opt.push({
          value: row[0],
          name: `${row[1]}`,
        });
      }
      return opt;
    },
    feetSizeUsMaOpt() {
      const opt = [];
      for (let i = 0 ; i < this.feetTable.length ; i ++ ) {
        const row = this.feetTable[i]
        opt.push({
          value: row[0],
          name: `${row[2]}`,
        });
      }
      return opt;
    },
    feetSizeUsFeOpt() {
      const opt = [];
      for (let i = 0 ; i < this.feetTable.length ; i ++ ) {
        const row = this.feetTable[i]
        opt.push({
          value: row[0],
          name: `${row[3]}`,
        });
      }
      return opt;
    },
    feetSizeJaMaOpt() {
      const opt = [];
      for (let i = 0 ; i < this.feetTable.length ; i ++ ) {
        const row = this.feetTable[i]
        opt.push({
          value: row[0],
          name: `${row[4]}`,
        });
      }
      return opt;
    },
    feetSizeJaFeOpt() {
      const opt = [];
      for (let i = 0 ; i < this.feetTable.length ; i ++ ) {
        const row = this.feetTable[i]
        opt.push({
          value: row[0],
          name: `${row[5]}`,
        });
      }
      return opt;
    },
    headSizeCmOpt() {
      const opt = [];
      for (let i = 45 ; i <= 61 ; i ++ ) {
        opt.push({
          value: i,
          name: `${i} cm`,
        });
      }
      return opt;
    },
    headSizeUsOpt() {
      const opt = [];
      const sizes = ['6 5/8', '6 3/4', '6 7/8', '7', '7 1/8', '7 1/4', '7 3/8', '7 1/2', '7 5/8'];
      for (let i = 53 ; i <= 61 ; i ++ ) {
        opt.push({
          value: i,
          name: `${sizes[i-53]}`,
        });
      }
      return opt;
    },
    howToImg() {
      const ary = [
        HeadHowTo,
        ShoulderHowTo,
        ChestHowTo,
        WaistHowTo,
        ButtockHowTo,
      ];
      return ary[this.howToIndex];
    },
  },
  watch: {
    isBodyConfigShow(val) {
      if (val) {
        this.__refresh();
      }
    },
  },
  methods: {
    ...mapActions(['showMsgModal', 'setBodyConfigShow', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
    async __refresh() {
      this.appendComponentBusy(this.readingBusyName);
      try {
        const userBody = await this.$store.dispatch('api/getUserBodyDataPromise');
        this.body = Object.assign({}, this.body, userBody);
        const sizeList = await this.$store.dispatch('api/readBrandSizeListPromise');
        this.brandSizeList.splice(0, this.brandSizeList.length);
        for (const s of sizeList) {
          this.brandSizeList.push(s);
        }
      } catch(err) {
        this.appendErrorMsg(err.toString());
      } finally {
        this.clearComponentBusy(this.readingBusyName);
      }
    },
    configCancel() {
      this.setBodyConfigShow(false);
    },
    async saveClicked() {
      const body = {...this.body};
      body.height = parseFloat(body.height);
      body.weight = parseFloat(body.weight);
      this.appendComponentBusy(this.savingBusyName);
      try {
        await this.$store.dispatch('api/postUpdateUserBodyDataPromise', body);
        this.setBodyConfigShow(false);
      } catch(err) {
        this.appendErrorMsg(err.toString());
      } finally {
        this.clearComponentBusy(this.savingBusyName);
      }
    },
    howToClicked(index) {
      this.howToIndex = index;
      this.isHowToModalShow = true;
    },
    howToClose() {
      this.isHowToModalShow = false;
    },
    deleteClicked(index) {
      this.deleteTargetIndex = index;
      this.isConfirmModalShow = true;
    },
    async deleteBrandSizeConfirmed() {
      this.appendComponentBusy(this.savingBusyName);
      try {
        await this.$store.dispatch('api/deleteBrandSizePromise', this.brandSizeList[this.deleteTargetIndex].serial);
        this.brandSizeList.splice(this.deleteTargetIndex, 1);
        this.showMsgModal('已刪除!');
        this.isConfirmModalShow = false;
      } catch(err) {
        this.appendErrorMsg(err.toString());
      } finally {
        this.clearComponentBusy(this.savingBusyName);
      }
    },
    clearInputBrandSize() {
      this.inputBrandSize.brandName = '';
      this.inputBrandSize.sizeType = '';
      this.inputBrandSize.size = '';
    },
    async saveBrandSize() {
      if (this.inputBrandSize.brandName.trim().length === 0 || this.inputBrandSize.sizeType.trim().length === 0 || this.inputBrandSize.size.trim().length === 0) {
        this.showMsgModal('資料有缺無法新增!');
        return;
      }
      this.appendComponentBusy(this.savingBusyName);
      try {
        const newData = await this.$store.dispatch('api/recordBrandSizePromise', {
          brandName: this.inputBrandSize.brandName.trim(),
          sizeType: this.inputBrandSize.sizeType.trim(),
          size: this.inputBrandSize.size.trim(),
        });
        if (newData !== null) {
          this.brandSizeList.push(newData);
          this.clearInputBrandSize();
          this.showMsgModal('已新增!');
        } else {
          this.showMsgModal('此資料已存在!');
        }
      } catch(err) {
        this.appendErrorMsg(err.toString());
      } finally {
        this.clearComponentBusy(this.savingBusyName);
      }
    }
  }
}
</script>

<style scoped>
  .body-config {

  }

  .config-form {
    padding: 0 30px;
    font-size: 14px;
  }

  .config-row {
    padding: 1rem 0;
  }

  .config-row:not(:last-child) {
    border-bottom: solid 1px #EEEEEE;
  }

  .config-form label {
    margin: 0;
  }

  .how-to-container {
    border-radius: .5rem;
    background-color: #fff;
    width: 75%;
    position: relative;
    margin: 0 auto;
    padding: 1rem;
  }

  .how-to-container>img {
    width: 100%;
  }

  .how-to {
    display: inline-block;
    margin-top: .25rem;
    color: #C4C4C4;
  }


</style>
