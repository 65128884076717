<template>
  <span class="color-circle">
    <div class="ball" :style="{'width': `${size}px`, 'height': `${size}px`}" :class="{'active': active}">
      <div v-if="color.id < 12" class="color" :style="{'backgroundColor': color.color}" ></div>
      <div v-if="color.id >= 12" class="color" :class="'ball-' + color.id"></div>
    </div>
  </span>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'ColorCircle',
  data() {
    return {
    };
  },
  created() {
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: 0,
    },
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: Number,
      required: false,
      default: 24,
    }
  },
  mounted() {
  },
  computed: {
    ...mapState(['clothColors']),
    color() {
      try {
        for (const c of this.clothColors) {
          if (c.id === this.id) {
            return c;
          }
        }
      } catch(e) {
        console.error(e);
      }
      return null;
    }
  },
  watch: {
  },
  methods: {
  }
}
</script>

<style scoped>

  .ball {
    display: block;
    width: 24px;
    height: 24px;
    border: solid 1px #eeeeee;
    border-radius: 50%;
    margin-right: 7px;
    overflow: hidden;
    position: relative;
    box-shadow: 0px 0px 4px #aaa;
  }

  .ball.active {
    /* box-shadow: 0 0 2px 0 #f00; */
    border: solid 2px #444;
  }

  .color {
    width: 100%;
    height: 100%;
    /* position: absolute; */
    /* top: 0; */
    /* left: 0; */
    border-radius: 50%;
  }

  .color.ball-12 {
    background: conic-gradient(from 226.43deg at 85.42% 110.42%, #808080 0deg, #9F9F9A 46.01deg, #DEDECF 86.17deg, #808080 360deg);
  }

  .color.ball-13 {
    background: conic-gradient(from 199.62deg at 72.92% 100%, #CAA628 -178.13deg, #E9D58F 128.39deg, #CAA628 181.87deg, #E9D58F 488.39deg);
  }

  .color.ball-14 {
    //background: url('~@/assets/icons/color-other.png');
    //background-size: contain;
    background: linear-gradient(
        135deg,
        #E3861A 25%,
        #7DC4B7 25% 50%,
        #F2C31C 50% 75%,
        #EEAB95 75%
    );
  }

</style>
