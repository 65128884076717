<template>
  <div class="addFriend">
    <section class="invite-sec d-none">
      <div class="container">
        <button class="invite-btn">以訊息或行動條碼邀請好友</button>
      </div>
    </section>

    <section class="id-sec">
      <div class="container">
        <div class="id-row">
          <img :src="myPicUrl" />
          <p>{{myName}}</p>
          <span>@{{myPublicId}}</span>
        </div>
      </div>
    </section>

    <section class="search-sec">
      <div class="container">
        <div class="search-div">
          <img :src="require('@/assets/icons/search.png')" />
          <span style="color: #8D8D8D">@</span>
          <input type="search" v-model="searchId" class="search-input" placeholder="搜尋好友ID" />
          <!-- <button class="search-btn" @click="searchIdBtnClicked" :disabled="searchBtnDisabled">
            <img :src="require('@/assets/icons/search.png')" />
          </button> -->
        </div>

        <div v-if="searching">
          <!-- <p class="mt-3 text-center">正在搜尋</p> -->
        </div>

        <div class="search-result-list" v-if="firstSearched && !searching">
          <p class="text-center pt-3" v-if="searchResList.length === 0">找不到符合的使用者</p>
          <ul>
            <li v-for="(item, index) in searchResList" :key="item.serial">
              <div class="list-img">
                <img :src="item.picUrl" />
              </div>

              <div class="list-desc">
                <p>{{item.displayName}}</p>
                <span>@{{item.publicId}}</span>
              </div>

              <div class="list-action">
                <button class="follow-btn following" v-if="item.followed" @click="setFollowing(false, index)">追蹤中</button>
                <button class="follow-btn" v-if="!item.followed" @click="setFollowing(true, index)">追蹤</button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <transition name="fade">
      <SetPublicIdModal v-if="isSetPublicIdModalShow" @close="closeSetPublicIdModal" @updated="publicIdUpdated">
      </SetPublicIdModal>
    </transition>
  </div>
</template>

<script>
import SetPublicIdModal from '@/views/share/SetPublicIdModal.vue';
import { mapActions } from 'vuex';
import _ from 'lodash';

import '@/assets/css/form-inputs.css';

export default {
  name: 'AddFriend',
  data() {
    return {
      readingBusyName: 'ADDFRIENDREADING',
      searchingBusyName: 'ADDFRIENDSEARCHING',
      setFollowingBusyName: 'SETFOLLOWING',
      myName: '',
      myPicUrl: '',
      myPublicId: '',
      firstSearched: false,

      searchId: '',
      searching: false,
      searchResList: [],
      isSetPublicIdModalShow: false,
    };
  },
  components: {
    SetPublicIdModal,
  },
  props: {
  },
  beforeDestroy() {
    this.clearComponentBusy(this.readingBusyName);
    this.clearComponentBusy(this.searchingBusyName);
    this.clearComponentBusy(this.setFollowingBusyName);
  },
  async mounted() {
    this.appendComponentBusy(this.readingBusyName);
    try {
      const userData = await this.$store.dispatch('api/getUserDataPromise');
      const {displayName, picUrl, publicId} = userData;
      this.myName = displayName;
      this.myPicUrl = picUrl;
      this.myPublicId = publicId;
      if (this.myPublicId.length === 0) {
        this.isSetPublicIdModalShow = true;
      }
    } catch(e) {
      console.error(e);
    } finally {
      this.clearComponentBusy(this.readingBusyName);
    }
  },
  computed: {
    searchBtnDisabled() {
      return this.searchId.trim().length === 0;
    },
  },
  watch: {
    searchId() {
      this.triggerSearchId();
    },
  },
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
    async searchIdBtnClicked() {
      if (this.searchId.trim().length === 0) {
        this.showMsgModal('請輸入至少一個字');
        return;
      }
      this.appendComponentBusy(this.searchingBusyName);
      try {
        const res = await this.$store.dispatch('api/postSearchUserPublicIdPromise', {
          publicId: this.searchId,
          precise: false,
        });
        this.searchResList.splice(0, this.searchResList.length);
        for (const user of res.list) {
          this.searchResList.push(user);
        }
        this.firstSearched = true;
      } catch(e) {
        this.showMsgModal(e);
      } finally {
        this.clearComponentBusy(this.searchingBusyName);
      }
    },
    triggerSearchId: _.debounce(async function () {
      this.searching = true;
      const searchingId = this.searchId;
      if (searchingId.trim().length === 0) {
        this.searchResList.splice(0, this.searchResList.length);
        this.searching = false;
        return;
      }
      const res = await this.$store.dispatch('api/postSearchUserPublicIdPromise', {
        publicId: searchingId,
        precise: false,
      });
      if (searchingId === this.searchId) {
        this.searchResList.splice(0, this.searchResList.length);
        for (const user of res.list) {
          this.searchResList.push(user);
        }
        this.searching = false;
        this.firstSearched = true;
      }
    }, 10),
    async setFollowing(follow, index) {
      const followObject = this.searchResList[index];
      const followedSerial = followObject.serial;

      this.appendComponentBusy(this.setFollowingBusyName);
      try {
        const res = await this.$store.dispatch('api/postSetFollowPromise', {
          follow,
          followedSerial,
        });
        this.$set(this.searchResList[index], 'followed', res.follow);
      } catch(e) {
        this.showMsgModal(e);
      } finally {
        this.clearComponentBusy(this.setFollowingBusyName);
      }
    },
    closeSetPublicIdModal() {
      this.isSetPublicIdModalShow = false;
      this.$emit('close');
    },
    publicIdUpdated(publicId) {
      this.myPublicId = publicId;
      this.isSetPublicIdModalShow = false;
    },
  }
}
</script>

<style scoped>
  .addFriend {

  }

  .invite-sec {
    padding: 1rem 0;
    background-color: var(--main-green);
  }

  .invite-btn {
    border: none;
    border-radius: .25rem;
    background-color: #fff;
    padding: .3rem;
    /* text-align: center; */
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-appearance: none;
    width: 100%;
  }

  .id-sec {
    background-color: #EEEEEE;
    padding: 14px 0;
  }

  .id-row {
    border-radius: .25rem;
    background-color: #fff;
    display: flex;
    padding: 6px 24px;
    align-items: center;
  }

  .id-row>img {
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 1rem;
  }

  .id-row>p {
    margin-bottom: 0;
    margin-right: 1rem;
    font-size: 12px;
    font-weight: 600;
    color: var(--text-dark);
  }

  .id-row>span {
    font-size: 12px;
    color: #C4C4C4;
  }

  .search-sec {
    padding: .5rem 0;
  }

  .search-div {
    display: flex;
    padding: 6px 12px;
    background-color: #EEEEEE;
    border-radius: .25rem;
    margin-bottom: .5rem;
    align-items: center;
  }

  .search-div>img {
    flex: 0 0 auto;
    display: block;
    width: 1rem;
    margin-right: .3rem;
  }

  .search-input {
    -webkit-appearance: none;
    border: none;
    flex: 1 1;
    background-color: transparent;
  }

  .search-input::placeholder {
    color: #c4c4c4;
  }

  .search-input:focus {
    outline: none;
  }

  .search-btn {
    -webkit-appearance: none;
    border: none;
    flex: 0 0 auto;
    background-color: transparent;
  }

  .search-btn>img {
    display: block;
    width: 1rem;
  }

  .search-result-list {

  }

  .search-result-list ul {
    margin: 0;
    padding: 0;
  }

  .search-result-list ul li {
    margin: 0;
    padding: .5rem 0;
    list-style: none;
    display: flex;
    align-items: center;
    border-bottom: solid 1px #C4C4C4;
  }

  .list-img {
    flex: 0 0 auto;
  }

  .list-img>img {
    display: block;
    width: 36px;
    height: 36px;
    object-fit: cover;
    border-radius: 50%;
  }

  .list-desc {
    flex: 1 1;
    padding: 0 .5rem;
  }

  .list-desc>p {
    font-size: 12px;
    color: var(--text-dark);
    margin-bottom: 0;
    font-weight: 600;
  }

  .list-desc>span {
    font-size: 12px;
    color: #C4C4C4;
  }

  .list-action {
    flex: 0 0 auto;
  }

  .follow-btn {
    border: none;
    border-radius: 999px;
    padding: 4px 14px;
    background-color: var(--main-green);
    color: #fff;
  }

  .follow-btn.following {
    background-color: rgb(238, 238, 238);
    color: #000;
  }
</style>
