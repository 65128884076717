<template>
  <span class="crop-image-button">
    <span class="img-upload" :class="btnClass" @click="uploadBtnClicked">
      <slot>
        上傳圖片
      </slot>
      <input type="file" ref="file" @change="fileChanged($event)" accept="image/jpeg,application/pdf" class="d-none">
    </span>
    <canvas ref="invisible-canvas" class="invisible-canvas"></canvas>
    <FullScreenModal v-if="isCropShow" title="編輯照片" @back="isCloseModalShow = true">
      <CropImage :defaultImage="image" :mask="mask" @cropped="cropped"></CropImage>
    </FullScreenModal>

    <Transition name="fade">
      <WcCommonModal v-if="isRemoveBgConfirmShow" @ok="uploadWithoutBg" @cancel="uploadWithBg" @close="isRemoveBgConfirmShow = false">
        <h5 class="text-center font-weight-bold">要使用智慧去背工具嗎？</h5>
        <p class="m-0 text-center">使用智慧去背移除圖片背景需較長的處理時間，敬請耐心等待。</p>
        <span slot="cancel">保留背景</span>
        <span slot="ok" class="font-weight-bold">智慧去背</span>
      </WcCommonModal>
    </Transition>

    <Transition name="fade">
      <WcCommonModal v-if="isCloseModalShow" @ok="cancelModalOk" @cancel="cancelModalCancel" hide-header>
        <p class="m-0 text-center">確定放棄編輯照片嗎?</p>
        <span slot="cancel">返回繼續編輯</span>
        <span slot="ok">確定並離開</span>
      </WcCommonModal>
    </Transition>
  </span>
</template>

<script>
function getMimeType(file, fallback = null) {
  const byteArray = (new Uint8Array(file)).subarray(0, 4);
  let header = '';
  for (let i = 0; i < byteArray.length; i++) {
    header += byteArray[i].toString(16);
  }
  switch (header) {
    case "89504e47":
      return "image/png";
    case "47494638":
      return "image/gif";
    case "ffd8ffe0":
    case "ffd8ffe1":
    case "ffd8ffe2":
    case "ffd8ffe3":
    case "ffd8ffe8":
      return "image/jpeg";
    default:
      return fallback;
  }
}

// function dataURItoBlob(dataURI) {
//   // convert base64 to raw binary data held in a string
//   // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
//   var byteString = atob(dataURI.split(',')[1]);

//   // separate out the mime component
//   var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

//   // write the bytes of the string to an ArrayBuffer
//   var ab = new ArrayBuffer(byteString.length);

//   // create a view into the buffer
//   var ia = new Uint8Array(ab);

//   // set the bytes of the buffer to the correct values
//   for (var i = 0; i < byteString.length; i++) {
//       ia[i] = byteString.charCodeAt(i);
//   }

//   // write the ArrayBuffer to a blob, and you're done
//   var blob = new Blob([ab], {type: mimeString});
//   return blob;
// }


// @ is an alias to /src
import {mapActions, mapGetters} from 'vuex';
import FullScreenModal from '@/components/FullScreenModal.vue';
import CropImage from './CropImage.vue';
import WcCommonModal from '../commons/WcCommonModal.vue';
import moment from "moment";

export default {
  name: 'CropImageButton',
  data() {
    return {
      tempData: '',
      image: {
        src: null,
        type: null
      },
      isCropShow: false,
      isRemoveBgConfirmShow: false,
      isCloseModalShow: false,

      lastPostTimestamp: 0,
    };
  },
  props: {
    btnClass: {
      type: String,
      required: false,
      default() {
        return '';
      },
    },
    canRemoveBg: {
      type: Boolean,
      default: false,
      required: false,
    },
    mask: {
      type: String,
      default: '',
      required: false,
    },
    syncUpload: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  components: {
    FullScreenModal,
    CropImage,
    WcCommonModal,
  },
  computed: {
    ...mapGetters(['isTester']),
  },
  watch: {

  },
  async mounted() {

  },
  methods: {
    ...mapActions(['appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
    uploadBtnClicked() {
      this.$refs.file.click();
    },
    fileChanged(event) {
      const { files } = event.target;
      if (files && files[0]) {
        this.resizeImagePromise(files[0]).then((blob) => {
          this.image = {
            // Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
            src: blob,
            // Determine the image type to preserve it during the extracting the image from canvas:
            type: 'image/png',
          };
          this.isCropShow = true;
        }).catch((e) => {
          this.appendErrorMsg(e.toString());
        });
      }
    },
    resizeImagePromise(imgFile) {
      return new Promise((resolve, reject) => {
        const encode = 'png';
        const maxSize = 2048;
        
        const fileLoader = new FileReader();
        const imageObj = new Image();
        const c = this.$refs['invisible-canvas'];

        if (imgFile.type.match('image.*')) {
          fileLoader.readAsDataURL(imgFile);
        } else {
          reject('請選擇符合格式的圖片檔案!');
          return;
        }
        
        fileLoader.onload = function () {
          const data = this.result;
          imageObj.src = data;
        };

        fileLoader.onabort = function () {
          reject("無法讀取檔案");
        };

        fileLoader.onerror = function () {
          reject("圖片載入時發生錯誤");
        };

        const ctx = c.getContext('2d');
        imageObj.onload = function () {

          // Check for empty images
          if (this.width == 0 || this.height == 0) {
            reject('無法使用空白圖片');
          } else {
            const wRatio = maxSize / this.width;
            const hRatio = maxSize / this.height;
            const ratio = Math.min(wRatio, hRatio);
            const cw = this.width * ratio;
            const ch = this.height * ratio;
            c.width = cw;
            c.height = ch;
            
            ctx.clearRect(0, 0, cw, ch);
            ctx.drawImage(imageObj, 0, 0, cw, ch);

            //dataURItoBlob function available here:
            // http://stackoverflow.com/questions/12168909/blob-from-dataurl
            // add ')' at the end of this function SO dont allow to update it without a 6 character edit
            resolve(c.toDataURL(encode));
            
            // const blob = dataURItoBlob(c.toDataURL(encode));
            // resolve(blob);
            // console.log(blob);
            //pass this blob to your upload function
            // upload(blob);
          }
        };

        imageObj.onabort = function () {
          reject("無法繪製圖片");
        };

        imageObj.onerror = function () {
          reject("繪製圖片時發生錯誤");
        };
      });
      
    },
    loadImage(event) {
      // Reference to the DOM input element
      const { files } = event.target;
      // Ensure that you have a file before attempting to read it
      if (files && files[0]) {
        // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src)
        }
        // 2. Create the blob link to the file to optimize performance:
        const blob = URL.createObjectURL(files[0]);

        // 3. The steps below are designated to determine a file mime type to use it during the 
        // getting of a cropped image from the canvas. You can replace it them by the following string, 
        // but the type will be derived from the extension and it can lead to an incorrect result:
        //
        // this.image = {
        //    src: blob;
        //    type: files[0].type
        // }

        // Create a new FileReader to read this image binary data
        const reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.image" refers to the image of Vue component
          this.image = {
            // Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
            src: blob,
            // Determine the image type to preserve it during the extracting the image from canvas:
            type: getMimeType(e.target.result, files[0].type),
          };
          this.isCropShow = true;
        };
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsArrayBuffer(files[0]);
      }
    },
    cropped(data) {
      this.tempData = data;
      if (this.canRemoveBg) {
        this.isRemoveBgConfirmShow = true;
      } else {
        this.uploadWithBg();
      }
    },
    uploadWithoutBg() {
      this.upload(true);
    },
    uploadWithBg() {
      this.upload(false);
    },
    async upload(removeBg) {
      this.isRemoveBgConfirmShow = false;
      const BUSYNAME = "UPLOADIMG";
      this.$emit('busy', true);
      if(!this.syncUpload) {
        if(this.isTester) {
          await this.postUploadWithProgressTest(removeBg);
        } else {
          await this.postUploadWithProgress(removeBg);
        }
      } else {
        this.appendComponentBusy(BUSYNAME);
        try {
          const url = await this.$store.dispatch('api/postUploadBase64ImagePromise', {
            img: this.tempData,
            removeBg: removeBg,
          });
          this.isCropShow = false;
          // console.log(url);
          this.$emit('uploaded', url);
        } catch (error) {
          console.error(error);
          this.appendErrorMsg(error);
        } finally {
          this.$emit('busy', false);
          this.clearComponentBusy(BUSYNAME);
        }
      }
    },
    async postUploadWithProgress(removeBg) {
      try {
        this.isCropShow = false; // 上傳開始就關閉裁剪畫面
        this.$emit('progress', 0); // 進度條設為 0% 開始

        const url = await this.$store.dispatch('api/postUploadBase64ImagePromise', {
          img: this.tempData,
          removeBg: removeBg,
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            this.$emit('progress', percentCompleted);
          },
        });

        this.$emit('progress', 100); // 上傳完成，進度條設為 100%
        this.$emit('uploaded', url); // 上傳成功後 emit
      } catch (error) {
        console.error('上傳失敗:', error);
      } finally {
        setTimeout(() => this.$emit('progress', -1), 500); // 短暫延遲後 emit -1 關閉進度條
      }
    },
    async postUploadWithProgressTest(removeBg) {
      const timestamp = moment().valueOf();
      this.lastPostTimestamp = timestamp;

      try {
        this.isCropShow = false; // 上傳開始就關閉裁剪畫面
        this.$emit('progress', 0); // 進度條設為 0% 開始

        const url = await this.$store.dispatch('api/postUploadBase64ImagePromise', {
          img: this.tempData,
          removeBg: removeBg,
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            if(timestamp === this.lastPostTimestamp) {
              this.$emit('progress', percentCompleted);
            }
          },
        });

        if (timestamp === this.lastPostTimestamp) {
          this.$emit('progress', 100); // 上傳完成，進度條設為 100%
          this.$emit('uploaded', url); // 上傳成功後 emit
        }
      } catch (error) {
        if (timestamp === this.lastPostTimestamp) {
          console.error('上傳失敗:', error);
        }
      } finally {
        if (timestamp === this.lastPostTimestamp) {
          setTimeout(() => this.$emit('progress', -1), 500); // 短暫延遲後 emit -1 關閉進度條
        }
      }
    },
    cancelModalOk() {
      this.isCloseModalShow = false;
      this.isCropShow = false;
    },
    cancelModalCancel() {
      this.isCloseModalShow = false;
    },
  }
}
</script>

<style lang="scss" scoped>
  .invisible-canvas {
    visibility: hidden;
    width: 0px;
    height: 0px;
    position: absolute;
    z-index: -1;
  }
</style>
