<template>
<span class="img-upload" @click="clicked">
  <input type="file" :accept="acceptType" ref="imageUploadInput" @change="uploadImage" style="display:none;">
  <slot></slot>
</span>
</template>

<script>
export default {
  name: 'ImageUploadButton',
  data() {
    return {
      uploading: false,
    }
  },
  props: {
    acceptType: {
      type: String,
      required: false,
      default: 'image/jpg',
    },
    sizelimit: {
      type: Number,
      required: false,
      default: 5,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    uploadFunction: {
      required: true,
    }
  },
  created() {},
  computed: {},
  watch: {
    uploading(newVal) {
      this.$emit('busy', newVal);
    }
  },
  methods: {
    clicked() {
      if (!this.disabled) {
        this.$refs['imageUploadInput'].click();
      }
    },
    mbToByte(mb) {
      return mb * 1024 * 1024;
    },
    uploadImage(event) {
      let input = event.target;
      if (input.files && input.files[0]) {
        if (input.files[0].size > this.mbToByte(this.sizelimit)) {
          this.$emit('upload_alert', 'file size over limit.');
          this.$emit('error', 'file size over limit.');
          this.$refs['imageUploadInput'].value = '';
          return;
        }
        const _this = this;
        _this.uploading = true;
        _this.uploadFunction(input.files[0]).then((data) => {
          _this.$emit('uploaded', data);
        }).catch(err => {
          console.error(err.toString());
          this.$emit('error', err.toString());
        }).then(() => {
          _this.uploading = false;
          _this.$refs['imageUploadInput'].value = '';
        });
      }
    },
    __emitLoadedPercentage(percentage) {
      this.$emit('progress', percentage);
    },
  }

}
</script>

<style scoped>
.img-upload {
  cursor: pointer;
  position: relative;
  width: 100%;
}
</style>
