<template>
  <div class="walking-closet-loading">
    <div class="modal-bg"></div>

    <div class="modal-ft">
      <div class="ani-center">
        <span class="ball ball-green"></span>
        <span class="ball ball-yellow"></span>
      </div>
      <p>載入中</p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'WalkingClosetLoading',
  data() {
    return {
    }
  },
  props: {
  },
  methods: {

  }

}
</script>

<style scoped>
  .walking-closet-loading {
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    height: 100dvh;
  }

  .modal-bg {
    width: 100%;
    height: 100%;
    background-color: #000a;
  }

  .modal-ft {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ani-center {
    position: relative;
    width: 20px;
    height: 20px;
    margin-bottom: .5rem;
  }

  span.ball {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #fff;
    z-index: 2;
  }

  @keyframes ball-green {
    0% {
      left: -15px;
      z-index: 2;
    }

    100% {
      left: 15px;
      z-index: 3;
    }
  }

  @keyframes ball-yellow {
    0% {
      left: 15px;
      /* z-index: 3; */
    }

    100% {
      left: -15px;
      /* z-index: 2; */
    }
  }

  span.ball.ball-green {
    background-color: var(--main-green);
    animation-name: ball-green;
    animation-direction: alternate;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

  span.ball.ball-yellow {
    background-color: var(--main-yellow);
    animation-name: ball-yellow;
    animation-direction: alternate;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

  .modal-ft>p {
    margin: 0;
    text-align: center;
    color: #fff;
    font-weight: 600;
    letter-spacing: .16em;
  }
</style>
